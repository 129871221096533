import React, { useContext } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useLocation } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';
import { UIElement } from '../../api/moduleElement';
import { hasViewOnlyPermission } from '../../utils/permissions';
import { CONTAINER_CARD, DraggableContext } from './ModuleBoard';
import { APIBoxCard } from './cards/APIBoxCard';
import { AmbientListeningCard } from './cards/AmbientListeningCard';
import { ChoiceCard } from './cards/ChoiceCard';
import { DataExtractorCard } from './cards/DataExtractorCard';
import { GptBoxCard } from './cards/GPTBoxCard';
import { MessageCard } from './cards/MessageCard';
import { NumbersCard } from './cards/NumbersCard';
import { SectionDividerCard } from './cards/SectionDividerCard';
import { TextInputCard } from './cards/TextInputCard';
import { GroupCard, GroupCardContext } from './cards/GroupCard';

interface CardBoardProps {
  droppableId: string;
  moduleElements: UIElement[];
}

export const CardBoard = ({ droppableId, moduleElements }: CardBoardProps) => {
  const { isDragDisabled } = useContext(DraggableContext);
  const { isInsideGroupCard } = useContext(GroupCardContext);
  const { pathname } = useLocation();
  const moduleType = pathname.split('/')[1];
  const hasPermission = !hasViewOnlyPermission(moduleType);

  return (
    <>
      {moduleElements.map(
        (element, key) =>
          element.is_introduction && (
            <div className='mb-[20px]' key={element.id}>
              <MessageCard key={element.id} element={element} />
            </div>
          )
      )}
      <Droppable
        droppableId={droppableId}
        type={droppableId.includes(CONTAINER_CARD) ? droppableId : undefined}
      >
        {(provided, snapshot) => (
          <div
            data-testid={droppableId}
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={twJoin('select-none', !isInsideGroupCard && 'min-h-[400px] space-y-4')}
          >
            {moduleElements.map((element, index) => {
              if (!!element.is_introduction) {
                return;
              }
              let component: React.JSX.Element;
              switch (element.resourcetype) {
                case 'Container':
                  component = <GroupCard key={element.id} element={element} />;
                  break;
                case 'ValuePanel':
                  component = <NumbersCard key={element.id} element={element} />;
                  break;
                case 'ChoicePanel':
                  component = <ChoiceCard key={element.id} element={element} />;
                  break;
                case 'MultiChoicePanel':
                  component = <ChoiceCard key={element.id} element={element} multi />;
                  break;
                case 'DescriptionPanel':
                  component = <MessageCard key={element.id} element={element} />;
                  break;
                case 'SectionDivider':
                  component = <SectionDividerCard key={element.id} element={element} />;
                  break;
                case 'TextInput':
                  component = <TextInputCard key={element.id} element={element} />;
                  break;
                case 'GPTBox':
                  component = <GptBoxCard key={element.id} element={element} />;
                  break;
                case 'AmbientListening':
                  component = <AmbientListeningCard key={element.id} element={element} />;
                  break;
                case 'DataExtractor':
                  component = <DataExtractorCard key={element.id} element={element} />;
                  break;
                case 'APIBox':
                  component = <APIBoxCard key={element.id} element={element} />;
                  break;
              }
              return (
                <Draggable
                  draggableId={`${element.id}`}
                  key={element.id}
                  index={index}
                  isDragDisabled={!hasPermission || isDragDisabled}
                >
                  {(provided) => (
                    <div
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      {component}
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </>
  );
};
