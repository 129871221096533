import { Menu } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Trigger } from 'api/resources';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import 'css/custom.css';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { hasViewOnlyPermission } from 'utils/permissions';
import { UIElement } from '../../../api/moduleElement';
import { MODULE_TYPES } from '../../../constants';
import { Trigger as TriggerComponent } from '../../app-previews/Trigger';
import SectionDividerForm from '../../panels/SectionDividerForm';
import { ModuleContext } from '../../utils/module/ModuleContext';
import { SlidePanel } from '../../utils/panels/SlidePanel';
import { ModuleElementContext } from '../ModuleBoard';
import { useTriggers } from '../../../hooks/module/resources/useTriggers';
import { GroupCardContext } from './GroupCard';

interface FormValues {
  id?: string;
  title: string;
  subtitle: string;
  trigger?: Trigger;
  module?: number;
  position?: number;
}

interface SectionDividerCardProps {
  element: UIElement;
}

export const SectionDividerCard = ({ element }: SectionDividerCardProps) => {
  const { module } = useContext(ModuleContext);
  const { isInsideGroupCard } = useContext(GroupCardContext);
  const { refetchModuleElements, duplicateUIElement, deleteUIElement, clearModuleElements } =
    useContext(ModuleElementContext);
  const [panelOpen, setPanelOpen] = useState(false);
  const [panelModalOpen, setPanelModalOpen] = useState(false);

  useEffect(() => {
    setPanelOpen(!element.id);
  }, [element]);

  const [deleteModal, setDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const {
    data: { triggers, candidate_triggers },
  } = useTriggers();
  const triggerList = [...triggers, ...candidate_triggers];

  const defaultValues = useMemo<FormValues>(
    () => ({
      title: element.title ?? '',
      subtitle: element.subtitle ?? '',
      trigger: element.trigger ?? undefined,
      module: module?.type === MODULE_TYPES.ALGO ? module.id : undefined,
      position: element.position,
    }),
    [element, module]
  );

  const form = useForm<FormValues>({
    // TODO: need to set default trigger id when create mode
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  const handlePanelClose = () => {
    clearModuleElements();
    form.reset(defaultValues);
    setPanelOpen(false);
  };

  const [triggerId, setTriggerId] = useState<string | undefined>();
  const trigger = form.watch('trigger');
  useEffect(() => {
    if (!!triggerId) {
      form.setValue(
        'trigger',
        triggerList.find((t) => t.id === triggerId)
      );
    }
  }, [triggerId]);

  const cardStyle = useMemo(
    () => ({
      marginTop: isInsideGroupCard ? '20px' : '5px',
      marginBottom: isInsideGroupCard ? '0px' : '20px',
      width: isInsideGroupCard ? '100%' : '576px',
      // borderRadius: '10px',
      border: 'none',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingTop: 0,
      paddingBottom: '10px',
      backgroundColor: 'transparent',
      // boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.3)',
    }),
    [isInsideGroupCard]
  );

  const questionStyle = {
    fontStyle: 'normal',
    marginTop: 4,
    fontWeight: 600,
    fontSize: '22px',
    lineHeight: '30px',
    textTransform: 'uppercase',
    color: '#000000',
  };

  return (
    <>
      <div
        style={
          {
            position: panelOpen ? 'relative' : '',
            zIndex: panelOpen ? '30' : '',
            maxWidth: isInsideGroupCard ? '100%' : '580px',
            // marginLeft: !this.props.container && '25px',
          } as React.CSSProperties
        }
      >
        <TriggerComponent trigger={trigger} containerCard={isInsideGroupCard} />
        <Card
          className='item-to-hover'
          onDoubleClick={() => !hasViewOnlyPermission(module?.type) && setPanelOpen(true)}
          variant='outlined'
          style={cardStyle}
        >
          <CardContent
            style={{
              paddingLeft: 16,
              paddingRight: 16,
              paddingBottom: 16,
              paddingTop: 5,
            }}
          >
            <Typography
              component={'div'}
              color='textPrimary'
              style={questionStyle as React.CSSProperties}
            >
              {!hasViewOnlyPermission(module?.type) && (
                <div
                  onClick={(e) => setAnchorEl(e.target as Element)}
                  style={{
                    background: '#08A88E',
                    width: '33px',
                    height: '33px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    color: 'white',
                    marginLeft: 'auto',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.11)',
                  }}
                  className='right item-to-show'
                >
                  {' '}
                  <MoreHorizIcon style={{ fontSize: 20 }} />
                </div>
              )}
              <Menu
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem
                  onClick={() => {
                    setPanelOpen(true);
                    setAnchorEl(null);
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setDeleteModal(true);
                    setAnchorEl(null);
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>
              {form.watch('title')}
            </Typography>
            <Typography
              className='pb-2'
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '16px',
                lineHeight: '21px',
                color: '#5B5B5B',
              }}
            >
              {form.watch('subtitle')}
            </Typography>
          </CardContent>
        </Card>
        <ConfirmModal
          preset='delete'
          open={deleteModal}
          content='Are you sure you want to delete this card?'
          toggleModal={() => setDeleteModal(false)}
          performAction={() => {
            deleteUIElement(element.id);
            setDeleteModal(false);
          }}
        />
      </div>
      <SlidePanel open={panelOpen} onClose={() => setPanelModalOpen(true)}>
        <div className='w-[620px] overflow-x-visible bg-white'>
          <SectionDividerForm
            handleSelectedItem={() => {}}
            startPreview={() => {}}
            handleAddTitle={(title: string) => form.setValue('title', title)}
            handleAddTrigger={(triggerId: string) => setTriggerId(triggerId)}
            handleAddSubtitle={(subtitle: string) => form.setValue('subtitle', subtitle)}
            moduleId={module?.id}
            position={element.position}
            toggleModal={handlePanelClose}
            sectionDividerId={element.id}
            resetState={refetchModuleElements}
            modal={panelModalOpen}
            closeUiElementPane={() => setPanelModalOpen(false)}
          />
        </div>
      </SlidePanel>
    </>
  );
};
