import { useQuery } from '@tanstack/react-query';
import { moduleAPI } from 'api/module';
import LoadingSpinner from 'components/loader/LoadingSpinner';
import Checkbox from 'components/utils/Checkbox';
import Label from 'components/utils/Label';
import { ModuleContext } from 'components/utils/module/ModuleContext';
import { Skeleton } from 'components/utils/Skeleton';
import { Body1, Body2, Caption2, H3 } from 'components/utils/typo';
import { useTeam } from 'hooks/useTeam';
import { useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { PublishFormVariables } from './PublishModal';

export const SelectChannels = () => {
  const { module } = useContext(ModuleContext);
  const { team } = useTeam();
  const { data: channels, isFetching } = useQuery({
    queryKey: ['publish', 'channels', team.id],
    queryFn: moduleAPI.getPublishChannels,
    enabled: !!module,
    refetchOnWindowFocus: false,
  });

  const { watch, setValue, register } = useFormContext<PublishFormVariables>();

  const getModules = (channel, moduleType) => {
    switch (moduleType) {
      case 'algo':
        return channel.modules || [];
      case 'calculator':
        return channel.calculators || [];
      case 'knowledge_base':
        return channel.knowledge_bases || [];
      default:
        return [];
    }
  };

  useEffect(() => {
    if (!module || !channels || isFetching) return;
    setValue(
      'channels',
      channels
        .filter((channel) => {
          const modules = getModules(channel, module.type);
          return modules.some((item) => item.id === module.id) && channel.last_synced;
        })
        .map((channel) => channel.id)
    );
  }, [module, channels, isFetching]);

  return (
    <>
      <div className='space-y-6 py-4'>
        <div className='space-y-2'>
          <H3 className='text-gray-900'>Choose your distribution channels</H3>
          {isFetching || !channels ? (
            <Skeleton />
          ) : (
            <Body2 className='text-gray-700'>
              {watch('channels').length} of {channels.length} channels are selected.
            </Body2>
          )}
        </div>
        <div className='space-y-4'>
          {isFetching || !channels ? (
            <div className='flex h-full items-center justify-center'>
              <LoadingSpinner size='large' />
            </div>
          ) : (
            channels.map((channel, idx) => <ChannelItem key={idx} channel={channel} />)
          )}
        </div>
      </div>
      {module?.type === 'calculator' && (
        <div className='space-y-2 border-t border-gray-200 py-4'>
          <H3 className='text-gray-900'>Advanced Publishing Options</H3>
          <Label className='flex gap-1'>
            <Checkbox checked={watch('is_universal')} {...register('is_universal')} />
            <Body1 className='text-gray-900'>Publish as Universal Calculator</Body1>
          </Label>
        </div>
      )}
    </>
  );
};

interface ChannelItemProps {
  channel: {
    id: number;
    name: string;
    last_synced: string;
    published: boolean;
  };
}

const ChannelItem = ({ channel }: ChannelItemProps) => {
  const { setValue, watch } = useFormContext<PublishFormVariables>();
  const onChange = (checked: boolean) => {
    if (checked) {
      setValue('channels', [...watch('channels'), channel.id]);
    } else {
      setValue(
        'channels',
        watch('channels').filter((id) => id !== channel.id)
      );
    }
  };
  return (
    <Label className='flex gap-1'>
      <Checkbox
        checked={watch('channels').includes(channel.id)}
        onChange={(e) => onChange(e.target.checked)}
      />
      <div className='space-y-0.5'>
        <Body1 className='text-gray-900'>{channel.name}</Body1>
        {channel.last_synced && (
          <Caption2 className='text-gray-700'>
            Synced on: {new Date(channel.last_synced).toLocaleString('en-US')}
          </Caption2>
        )}
      </div>
    </Label>
  );
};
