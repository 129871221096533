import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import LoadingSpinnerGreen from 'assets/dotlotties/circle-loading-green.lottie';
import LoadingSpinnerWhite from 'assets/dotlotties/circle-loading-white.lottie';
import { ReactComponent as InfoBox } from 'assets/icons/InfoBox.svg';
import { ReactComponent as AlgoGreen } from 'assets/icons/algo-green.svg';
import { ReactComponent as AmbientListening } from 'assets/icons/ambient_listening.svg';
import { ReactComponent as ArrowDown } from '../../assets/icons/v2/item/arrow_down.svg';
import { ReactComponent as CalculatorGreen } from 'assets/icons/calculator-green.svg';
import { ReactComponent as Chat } from 'assets/icons/chat.svg';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevronLeft.svg';
import { ReactComponent as Checkbox } from 'assets/icons/draggable-chips/checkbox.svg';
import { ReactComponent as DragIcon } from 'assets/icons/draggable-chips/dragIcon.svg';
import { ReactComponent as GPTBox } from 'assets/icons/draggable-chips/gptbox.svg';
import { ReactComponent as Group } from 'assets/icons/draggable-chips/group.svg';
import { ReactComponent as Message } from 'assets/icons/draggable-chips/message.svg';
import { ReactComponent as MultipleChoice } from 'assets/icons/draggable-chips/multipleChoice.svg';
import { ReactComponent as Number } from 'assets/icons/draggable-chips/number.svg';
import { ReactComponent as TextInput } from 'assets/icons/draggable-chips/text-input.svg';
import { ReactComponent as EHROrder } from 'assets/icons/ehr_order.svg';
import { ReactComponent as Error } from 'assets/icons/error.svg';
import { ReactComponent as EyeSlashIcon } from 'assets/icons/eye.svg';
import { ReactComponent as Google } from 'assets/icons/google.svg';
import { ReactComponent as Grid } from 'assets/icons/grid.svg';
import { ReactComponent as Home } from 'assets/icons/home.svg';
import { ReactComponent as Info } from 'assets/icons/info.svg';
import { ReactComponent as KnowledgeBaseGreen } from 'assets/icons/knowledgebase-green.svg';
import { ReactComponent as LinkOpen } from 'assets/icons/link_open.svg';
import { ReactComponent as ListNumbers } from 'assets/icons/listNumbers.svg';
import { ReactComponent as Member } from 'assets/icons/member.svg';
import { ReactComponent as Refresh } from 'assets/icons/refresh.svg';
import { ReactComponent as Search } from 'assets/icons/search.svg';
import { ReactComponent as SectionDivider } from 'assets/icons/section-divider.svg';
import { ReactComponent as Setting } from 'assets/icons/setting.svg';
import { ReactComponent as Share } from 'assets/icons/share.svg';
import { ReactComponent as Tag } from 'assets/icons/tag.svg';
import { ReactComponent as TaskSetting } from 'assets/icons/taskSetting.svg';
import { ReactComponent as ThreeDot } from 'assets/icons/threedot.svg';
import { ReactComponent as EHRData } from 'assets/icons/tiptap/EHRData.svg';
import { ReactComponent as EHRNode } from 'assets/icons/tiptap/EHRNode.svg';
import { ReactComponent as TiptapCenterAlign } from 'assets/icons/tiptap/centerAlign.svg';
import { ReactComponent as TiptapIfIcon } from 'assets/icons/tiptap/ifIcon.svg';
import { ReactComponent as TiptapInfoboxNode } from 'assets/icons/tiptap/infoboxNode.svg';
import { ReactComponent as TiptapKnowledegeBaseNode } from 'assets/icons/tiptap/knowledgeBaseNode.svg';
import { ReactComponent as TiptapLeftAlign } from 'assets/icons/tiptap/leftAlign.svg';
import { ReactComponent as TiptapLinkReadOnly } from 'assets/icons/tiptap/linkReadOnly.svg';
import { ReactComponent as TiptapMediaNode } from 'assets/icons/tiptap/mediaNode.svg';
import { ReactComponent as MenuUp } from 'assets/icons/tiptap/menuUp.svg';
import { ReactComponent as TiptapPhoneNode } from 'assets/icons/tiptap/phoneNode.svg';
import { ReactComponent as TiptapPhoneReadOnly } from 'assets/icons/tiptap/phoneReadOnly.svg';
import { ReactComponent as TiptapReference } from 'assets/icons/tiptap/reference.svg';
import { ReactComponent as TiptapRefNode } from 'assets/icons/tiptap/referenceNode.svg';
import { ReactComponent as TiptapRightAlign } from 'assets/icons/tiptap/rightAlign.svg';
import { ReactComponent as TiptapVariableNode } from 'assets/icons/tiptap/variableNode.svg';
import { ReactComponent as ToolCalc } from 'assets/icons/toolCalc.svg';
import { ReactComponent as ToolInfobox } from 'assets/icons/toolInfobox.svg';
import { ReactComponent as TrashBin } from 'assets/icons/trash.svg';
import { ReactComponent as ChevronDown } from 'assets/icons/v2/general/ic_arrow_down.svg';
import { ReactComponent as Plus } from 'assets/icons/v2/general/plus_m.svg';
import { ReactComponent as Edit } from 'assets/icons/v2/ic_edit.svg';
import { ReactComponent as FloatingButton } from 'assets/icons/v2/ic_more.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/v2/item/arrow_right.svg';
import { ReactComponent as BannerWarningGreen } from 'assets/icons/v2/item/banner_warning_green.svg';
import { ReactComponent as CaretDown } from 'assets/icons/v2/item/caret_down.svg';
import { ReactComponent as Check } from 'assets/icons/v2/item/check.svg';
import { ReactComponent as CheckEmpty } from 'assets/icons/v2/item/check_empty.svg';
import { ReactComponent as Close } from 'assets/icons/v2/item/close.svg';
import { ReactComponent as Email } from 'assets/icons/v2/item/email.svg';
import { ReactComponent as Profile } from 'assets/icons/v2/item/profile.svg';
import { ReactComponent as StepBar } from 'assets/icons/v2/item/step_bar.svg';
import { FC, ImgHTMLAttributes, SVGProps } from 'react';
import { twJoin } from 'tailwind-merge';
import { ReactComponent as CheckCircle } from '../../assets/icons/v2/item/check_circle.svg';

// mention icons
import { ReactComponent as AiGeneration } from '../../assets/icons/mentions/aiGeneration.svg';
import { ReactComponent as ConditionalText } from '../../assets/icons/mentions/conditionalText.svg';
import { ReactComponent as ContactNumber } from '../../assets/icons/mentions/contactNumber.svg';
import { ReactComponent as EhrData } from '../../assets/icons/mentions/ehrData.svg';
import { ReactComponent as EhrOrder } from '../../assets/icons/mentions/ehrOrder.svg';
import { ReactComponent as Formula } from '../../assets/icons/mentions/formula.svg';
import { ReactComponent as MentionInfoBox } from '../../assets/icons/mentions/infoBox.svg';
import { ReactComponent as Link } from '../../assets/icons/mentions/link.svg';
import { ReactComponent as Media } from '../../assets/icons/mentions/media.svg';
import { ReactComponent as Reference } from '../../assets/icons/mentions/reference.svg';
import { ReactComponent as Variable } from '../../assets/icons/mentions/variable.svg';

function withDataTestid(Component: FC<SVGProps<SVGSVGElement>>, dataTestid: string) {
  return (props: SVGProps<SVGSVGElement>) => (
    <Component
      data-testid={dataTestid}
      className={twJoin('fill-current', props.className)}
      {...props}
    />
  );
}

function imgWithDataTestid(src: string, dataTestid: string) {
  return (props: ImgHTMLAttributes<HTMLImageElement>) => (
    <img data-testid={dataTestid} src={src} {...props} />
  );
}

function lottieJsonWithDataTestid(dotlottiePath: any, dataTestid: string) {
  return (props) => (
    <DotLottieReact data-testid={dataTestid} src={dotlottiePath} loop autoplay {...props} />
  );
}
/**
 * Please add new icons sorted by alphabetical !!
 */
export const Icons = {
  AlgoGreen: withDataTestid(AlgoGreen, 'icon-algo-green'),
  AmbientListening: withDataTestid(AmbientListening, 'icon-ambient-listening'),
  ArrowDown: withDataTestid(ArrowDown, 'icon-arrow-down'),
  ArrowRight: withDataTestid(ArrowRight, 'icon-arrow-right'),
  BannerWarningGreen: withDataTestid(BannerWarningGreen, 'icon-banner-warning-green'),
  CalculatorGreen: withDataTestid(CalculatorGreen, 'icon-calculator-green'),
  CaretDown: withDataTestid(CaretDown, 'icon-caret-down'),
  Chat: withDataTestid(Chat, 'icon-chat'),
  Check: withDataTestid(Check, 'icon-check'),
  Checkbox: withDataTestid(Checkbox, 'icon-checkbox'),
  CheckCircle: withDataTestid(CheckCircle, 'icon-check-circle'),
  CheckEmpty: withDataTestid(CheckEmpty, 'icon-check-empty'),
  ChevronDown: withDataTestid(ChevronDown, 'icon-chevron-down'),
  ChevronLeft: withDataTestid(ChevronLeft, 'icon-chevron-left'),
  Close: withDataTestid(Close, 'icon-close'),
  DragIcon: withDataTestid(DragIcon, 'icon-drag-icon'),
  EyeSlashIcon: withDataTestid(EyeSlashIcon, 'icon-eye-slash'),
  Edit: withDataTestid(Edit, 'icon-edit'),
  EHRData: withDataTestid(EHRData, 'icon-ehr-data'),
  EHRNode: withDataTestid(EHRNode, 'icon-ehr-node'),
  EHROrder: withDataTestid(EHROrder, 'icon-ehr-order'),
  Email: withDataTestid(Email, 'icon-email'),
  Error: withDataTestid(Error, 'icon-error'),
  FloatingButton: withDataTestid(FloatingButton, 'icon-floating-button'),
  Google: withDataTestid(Google, 'icon-google'),
  GPTBox: withDataTestid(GPTBox, 'icon-gpt-box'),
  Grid: withDataTestid(Grid, 'icon-grid'),
  Group: withDataTestid(Group, 'icon-group'),
  Home: withDataTestid(Home, 'icon-home'),
  Info: withDataTestid(Info, 'icon-info'),
  InfoBoxIcon: withDataTestid(InfoBox, 'icon-info-box'),
  KnowledgeBaseGreen: withDataTestid(KnowledgeBaseGreen, 'icon-knowledge-base-green'),
  LinkOpen: withDataTestid(LinkOpen, 'icon-link-open'),
  ListNumbers: withDataTestid(ListNumbers, 'icon-list-numbers'),
  LoadingSpinnerGreen: lottieJsonWithDataTestid(
    LoadingSpinnerGreen,
    'lottie-loading-spinner-green'
  ),
  LoadingSpinnerWhite: lottieJsonWithDataTestid(
    LoadingSpinnerWhite,
    'lottie-loading-spinner-white'
  ),
  Member: withDataTestid(Member, 'icon-member'),
  MenuUp: withDataTestid(MenuUp, 'icon-menu-up'),
  Message: withDataTestid(Message, 'icon-message'),
  MultipleChoice: withDataTestid(MultipleChoice, 'icon-multiple-choice'),
  Number: withDataTestid(Number, 'icon-number'),
  Search: withDataTestid(Search, 'icon-search'),
  TextInput: withDataTestid(TextInput, 'icon-text-input'),
  Plus: withDataTestid(Plus, 'icon-plus'),
  Profile: withDataTestid(Profile, 'icon-profile'),
  Refresh: withDataTestid(Refresh, 'icon-refresh'),
  SectionDivider: withDataTestid(SectionDivider, 'icon-section-divider'),
  Setting: withDataTestid(Setting, 'icon-setting'),
  Share: withDataTestid(Share, 'icon-share'),
  StepBar: withDataTestid(StepBar, 'icon-step-bar'),
  Tag: withDataTestid(Tag, 'icon-tag'),
  TaskSetting: withDataTestid(TaskSetting, 'icon-task-setting'),
  ThreeDot: withDataTestid(ThreeDot, 'icon-three-dot'),
  TiptapCenterAlign: withDataTestid(TiptapCenterAlign, 'icon-tiptap-center-align'),
  TiptapIfIcon: withDataTestid(TiptapIfIcon, 'icon-tiptap-if-icon'),
  TiptapInfoboxNode: withDataTestid(TiptapInfoboxNode, 'icon-tiptap-infobox-node'),
  TiptapKnowledegeBaseNode: withDataTestid(
    TiptapKnowledegeBaseNode,
    'icon-tiptap-knowledge-base-node'
  ),
  TiptapLeftAlign: withDataTestid(TiptapLeftAlign, 'icon-tiptap-left-align'),
  TiptapLinkReadOnly: withDataTestid(TiptapLinkReadOnly, 'icon-tiptap-link-read-only'),
  TiptapMediaNode: withDataTestid(TiptapMediaNode, 'icon-tiptap-media-node'),
  TiptapPhoneNode: withDataTestid(TiptapPhoneNode, 'icon-tiptap-phone-node'),
  TiptapPhoneReadOnly: withDataTestid(TiptapPhoneReadOnly, 'icon-tiptap-phone-read-only'),
  TiptapRightAlign: withDataTestid(TiptapRightAlign, 'icon-tiptap-right-align'),
  TiptapReference: withDataTestid(TiptapReference, 'icon-tiptap-reference'),
  TiptapRefNode: withDataTestid(TiptapRefNode, 'icon-tiptap-ref-node'),
  TiptapVariableNode: withDataTestid(TiptapVariableNode, 'icon-tiptap-variable-node'),
  ToolInfobox: withDataTestid(ToolInfobox, 'icon-tool-infobox'),
  ToolCalc: withDataTestid(ToolCalc, 'icon-tool-calc'),
  TrashBin: withDataTestid(TrashBin, 'icon-trash-bin'),

  // mention icons
  AiGeneration: withDataTestid(AiGeneration, 'icon-mention-ai-generation'),
  ConditionalText: withDataTestid(ConditionalText, 'icon-mention-conditional-text'),
  ContactNumber: withDataTestid(ContactNumber, 'icon-mention-contact-number'),
  EhrData: withDataTestid(EhrData, 'icon-mention-ehr-data'),
  Formula: withDataTestid(Formula, 'icon-mention-formula'),
  EhrOrder: withDataTestid(EhrOrder, 'icon-mention-individual-order'),
  InfoBox: withDataTestid(MentionInfoBox, 'icon-mention-info-box'),
  Link: withDataTestid(Link, 'icon-mention-link'),
  Media: withDataTestid(Media, 'icon-mention-media'),
  Reference: withDataTestid(Reference, 'icon-mention-reference'),
  Variable: withDataTestid(Variable, 'icon-mention-variable'),
};
