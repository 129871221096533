import { useMutation } from '@tanstack/react-query';
import { msgCardAPI } from 'api/panels';
import clsx from 'clsx';
import LoadingSpinner from 'components/loader/LoadingSpinner';
import { MessageFormVariable } from 'components/module/cards/MessageCard';
import SituationBuilder from 'components/resources/triggers/SituationBuilder';
import { AvoSwitch } from 'components/utils/AvoSwitch';
import Button from 'components/utils/Button';
import { ComboBox } from 'components/utils/ComboBox';
import Input from 'components/utils/Input';
import { AvoEditor } from 'components/utils/avoeditor/AvoEditor';
import { ToolbarButton } from 'components/utils/draftJS/utils';
import { ExampleNTools } from 'components/utils/example-n-tools/ExampleNTools';
import { ExampleNToolButtons } from 'components/utils/example-n-tools/ToolButtons';
import { ModuleContext } from 'components/utils/module/ModuleContext';
import { AvoSlideCloseModal } from 'components/utils/panels/AvoSlideCloseModal';
import { SlidePanel } from 'components/utils/panels/AvoSlidePanel';
import RequiredMark from 'components/utils/requiredMark';
import { RichTextMenuButtonTypes } from 'components/utils/tiptap/tiptapInterfaces';
import { Body2, Caption2, H4 } from 'components/utils/typo';
import VariableAutoGenerator from 'components/utils/variable-generator';
import { useVariables } from 'hooks/module/useVariables';
import { X } from 'lucide-react';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { getVariableName } from 'utils/gptUtils';
import { getNumerics, getSuggestions } from 'utils/suggestions';
import { useTriggers } from '../../hooks/module/resources/useTriggers';
import { DEMOGRAPHICS_TYPE, PROBLEM_LIST_TYPE } from '../../hooks/useEHRVariables';

interface MessageFormProps {
  messageId: number;
  is_introduction: boolean;
  onSave: () => void;
  onCancel: () => void;
}

export const MessageForm = ({ messageId, is_introduction, onSave, onCancel }: MessageFormProps) => {
  const { module } = useContext(ModuleContext);
  const { data: triggerState } = useTriggers();
  const triggers = [...triggerState.triggers, ...triggerState.candidate_triggers];
  const triggerOptions = triggers.map((trigger) => ({
    value: trigger.id,
    label: trigger.title,
  }));
  const {
    watch,
    register,
    setValue,
    handleSubmit,
    getValues,
    formState: { isDirty, errors },
    setError,
    clearErrors,
    reset,
  } = useFormContext<MessageFormVariable>();

  const [triggerFormOpen, setTriggerFormOpen] = useState(false);
  const [closeModalOpen, setCloseModalOpen] = useState(false);
  const [triggerId, setTriggerId] = useState<number | undefined>();

  const triggerComponentProps = {
    toggleModal: () => setTriggerFormOpen(false),
    isFullHeight: true,
    selectTrigger: (value) => setValue('trigger', value, { shouldDirty: true }),
    createModal: true,
    triggerId,
    savePosition: () => {},
  };
  const { data: variables } = useVariables();
  const [suggestions, setSuggestions] = useState<{ name: string; code: string }[]>([]);
  const [numerics, setNumerics] = useState([]);

  useEffect(() => {
    if (!module || module.use_new_editor) return;
    getSuggestions(module.id).then(setSuggestions);
    getNumerics(module.id).then(setNumerics);
  }, [module?.id]);

  const initialDescription = useRef(
    module?.use_new_editor ? getValues('description_json_tiptap') : getValues('description_json')
  );

  const initialDetail = useRef(
    module?.use_new_editor ? getValues('detail_json_tiptap') : getValues('detail_json')
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isDescEmpty, setIsDescEmpty] = useState(!messageId);
  const [descCharLength, setDescCharLength] = useState(0);
  const [detailCharLength, setDetailCharLength] = useState(0);
  const [tiptapDescCurrentCount, setTiptapDescCurrentCount] = useState(0);
  const [tiptapDetailCurrentCount, setTiptapDetailCurrentCount] = useState(0);

  const { mutateAsync } = useMutation({
    mutationFn: !messageId
      ? msgCardAPI.postMsgCard
      : (data: MessageFormVariable) => msgCardAPI.putMsgCard(data, messageId),
  });

  const onSubmit = async (data: MessageFormVariable) => {
    if (isLoading) return;
    if (isDescEmpty) {
      setError(module?.use_new_editor ? 'description_json_tiptap' : 'description_json', {
        type: 'required',
        message: 'Description is required',
      });
      return;
    }
    setIsLoading(true);
    if (!is_introduction && !data.variable) {
      data.variable = await getVariableName(data.title);
    }

    data['question_items'] = [...data['examples'], ...data['tools']];
    await mutateAsync(data);
    reset(data);
    onSave();
    setIsLoading(false);
  };

  const onDescDraftJsChange = (value: any) => {
    const text = value.blocks.map((block) => block.text).join('\n');
    setIsDescEmpty(!text);
    setValue('description_json', value, { shouldDirty: true });
    clearErrors('description_json');
  };

  const onDescTiptapChange = (editor) => {
    setValue('description_json_tiptap', editor.getJSON(), { shouldDirty: true });
    setIsDescEmpty(editor.isEmpty);
    setTiptapDescCurrentCount(editor.getText().length);
    clearErrors('description_json_tiptap');
  };

  const onDetailDraftJsChange = (value: any) => {
    const text = value.blocks.map((block) => block.text).join('\n');
    setIsDescEmpty(!text);
    setValue('detail_json', value, { shouldDirty: true });
  };

  const onDetailTiptapChange = (editor) => {
    setValue('detail_json_tiptap', editor.getJSON(), { shouldDirty: true });
    setIsDescEmpty(editor.isEmpty);
    setTiptapDetailCurrentCount(editor.getText().length);
  };

  // TODO: need to add entire ehr type (currently not included for product team QA/test)
  const suggestionsToExclude = useMemo(
    () =>
      is_introduction
        ? [
            'knowledge_base',
            'variable',
            'conditional_text',
            'link',
            'contact_number',
            'reference',
            'ehr_order',
            PROBLEM_LIST_TYPE,
            DEMOGRAPHICS_TYPE,
          ]
        : ['knowledge_base', PROBLEM_LIST_TYPE, DEMOGRAPHICS_TYPE],
    [is_introduction]
  );
  const richTextButtonsShowListTiptap: RichTextMenuButtonTypes[] = useMemo(
    () =>
      is_introduction
        ? ['textStyles', 'italic', 'highlight', 'bulletList', 'orderedList', 'more']
        : ['textStyles', 'bold', 'italic', 'highlight', 'bulletList', 'orderedList', 'more'],
    [is_introduction]
  );
  const richTextButtonShowList = useMemo(
    () =>
      is_introduction
        ? [
            ToolbarButton.TEXT_STYLE,
            ToolbarButton.ITALIC,
            ToolbarButton.HIGHLIGHT,
            ToolbarButton.BULLETED_LIST,
            ToolbarButton.NUMBERED_LIST,
            ToolbarButton.INFOBOX,
            ToolbarButton.MEDIA,
          ]
        : [
            ToolbarButton.TEXT_STYLE,
            ToolbarButton.BOLD,
            ToolbarButton.ITALIC,
            ToolbarButton.HIGHLIGHT,
            ToolbarButton.BULLETED_LIST,
            ToolbarButton.NUMBERED_LIST,
            ToolbarButton.INFOBOX,
            ToolbarButton.INSERT_LINK,
            ToolbarButton.MEDIA,
            ToolbarButton.PHONE_NUMBER,
            ToolbarButton.VARIABLES,
            ToolbarButton.CONDITIONAL_TEXT,
          ],
    [is_introduction]
  );

  return (
    <>
      <form className='flex h-full flex-col' onSubmit={handleSubmit(onSubmit)}>
        <div className='grow overflow-y-auto'>
          <div className='sticky top-0 z-10 flex items-center justify-between bg-white pl-4 pr-3 pt-[20px]'>
            <H4>{is_introduction ? 'Introduction' : 'Message'}</H4>
            <div
              className='cursor-pointer'
              onClick={() => (isDirty ? setCloseModalOpen(true) : onCancel())}
            >
              <X size={20} />
            </div>
          </div>
          <div className='mt-4 px-4'>
            {!is_introduction && (
              <>
                <div className='space-y-1'>
                  <div className='flex gap-1'>
                    <Body2 className='text-gray-900'>Trigger</Body2>
                    <RequiredMark />
                  </div>
                  <ComboBox
                    onChange={(value: any) => setValue('trigger', value, { shouldDirty: true })}
                    options={triggerOptions}
                    selectedValue={watch('trigger')}
                    onCreate={() => {
                      setTriggerId(undefined);
                      setTriggerFormOpen(true);
                    }}
                    onEdit={(id) => {
                      setTriggerId(+id);
                      setTriggerFormOpen(true);
                    }}
                    createButtonLabel='Create New Trigger'
                  />
                </div>
                <div className='mt-6 space-y-1'>
                  <div className='flex gap-1'>
                    <Body2 className='text-gray-900'>Title</Body2>
                    <RequiredMark />
                  </div>
                  <Input
                    {...register('title', {
                      required: { value: !is_introduction, message: 'Title field is required' },
                      maxLength: 200,
                      shouldUnregister: is_introduction,
                    })}
                    maxLength={200}
                    className={clsx(
                      errors.title &&
                        'border-red-500 ring-1 !ring-red-500' /* TODO: Need to remove ring after remove global style*/
                    )}
                  />
                  {errors.title && <span className='text-error'>{errors.title.message}</span>}
                  <Caption2 className='flex justify-end text-gray-text-weak'>
                    {watch('title').length}/200
                  </Caption2>
                </div>
              </>
            )}
            <div className='mt-1 space-y-2'>
              <div className='flex gap-1'>
                <Body2 className='text-gray-900'>{is_introduction ? 'Text' : 'Description'}</Body2>
                <RequiredMark />
              </div>
              <div
                className={clsx(
                  (errors.description_json || errors.description_json_tiptap) &&
                    'rounded border border-red-500 ring-1 !ring-red-500' /* TODO: Need to remove ring after remove global style*/
                )}
              >
                <AvoEditor
                  // common props
                  placeholder='This is card description'
                  // draft js props
                  fullWidth
                  moduleId={module?.id}
                  prevValue={initialDescription.current}
                  MAX_TEXT_LIMIT={3000}
                  MAX_NO_OF_LINES={99999}
                  numerics={numerics}
                  variables={variables}
                  setValue={onDescDraftJsChange}
                  suggestions={suggestions}
                  getCharactersLength={setDescCharLength}
                  richTextButtonShowList={richTextButtonShowList}
                  // tiptap props
                  maxLines={99999}
                  maxCharacters={3000}
                  initialContent={initialDescription.current}
                  onUpdate={onDescTiptapChange}
                  suggestionsToExclude={suggestionsToExclude}
                  characterLimitTotalCount={descCharLength}
                  characterLimitCurrentCount={tiptapDescCurrentCount}
                  wrapperClassNames='flex-grow max-h-[600px] min-h-[280px] !h-auto'
                  richTextButtonsShowListTiptap={richTextButtonsShowListTiptap}
                />
              </div>
              {(errors.description_json || errors.description_json_tiptap) && (
                <span className='text-red-500'>
                  {errors.description_json?.message || errors.description_json_tiptap?.message}
                </span>
              )}
            </div>
            {!is_introduction && (
              <div className='mt-6 flex w-full justify-between'>
                <Body2 className='text-gray-900'>Add More Details</Body2>
                <AvoSwitch
                  checked={watch('detail_enabled')}
                  onChange={(val) => setValue('detail_enabled', val, { shouldDirty: true })}
                />
              </div>
            )}
            {watch('detail_enabled') && (
              <div className='mt-6 space-y-[5px]'>
                <div className='flex gap-1'>
                  <Body2 className='text-gray-900'>Details</Body2>
                </div>
                <div className='space-y-1'>
                  <AvoEditor
                    // common props
                    placeholder='These are the card details. This section is optional and only needed if card contents requires more details than the description section allows'
                    // draft js props
                    fullWidth
                    moduleId={module?.id}
                    prevValue={initialDetail.current}
                    MAX_TEXT_LIMIT={3000}
                    MAX_NO_OF_LINES={99999}
                    numerics={numerics}
                    variables={variables}
                    setValue={onDetailDraftJsChange}
                    suggestions={suggestions}
                    getCharactersLength={setDetailCharLength}
                    richTextButtonShowList={richTextButtonShowList}
                    // tiptap props
                    maxLines={99999}
                    maxCharacters={3000}
                    initialContent={initialDetail.current}
                    onUpdate={onDetailTiptapChange}
                    suggestionsToExclude={suggestionsToExclude}
                    characterLimitTotalCount={detailCharLength}
                    characterLimitCurrentCount={tiptapDetailCurrentCount}
                    wrapperClassNames='flex-grow max-h-[600px] min-h-[280px] !h-auto'
                    richTextButtonsShowListTiptap={richTextButtonsShowListTiptap}
                  />
                </div>
              </div>
            )}
            {!!messageId && watch('examples').length > 0 && (
              <div>
                <div className='space-y-[5px]'>
                  <div className='flex gap-1'>
                    <Body2 className='text-gray-900'>Examples</Body2>
                  </div>
                </div>
                <div className='mt-2'>
                  <ExampleNToolButtons
                    section='E'
                    list={watch('examples')}
                    buttonsToShow={['text', 'infobox']}
                    onChange={(value) => setValue('examples', value, { shouldDirty: true })}
                  />
                </div>
                {watch('examples').length > 0 && (
                  <div className='mt-2'>
                    <ExampleNTools
                      list={watch('examples')}
                      onChange={(value) => setValue('examples', value, { shouldDirty: true })}
                    />
                  </div>
                )}
              </div>
            )}
            <div className='mt-6'>
              <div className='space-y-[5px]'>
                <div className='flex gap-1'>
                  <Body2 className='text-gray-900'>Tools</Body2>
                </div>
              </div>
              <div className='mt-2'>
                <ExampleNToolButtons
                  section='T'
                  list={watch('tools')}
                  buttonsToShow={['infobox', 'calculator']}
                  onChange={(value) => setValue('tools', value, { shouldDirty: true })}
                />
              </div>
              {watch('tools').length > 0 && (
                <div className='mt-2'>
                  <ExampleNTools
                    list={watch('tools')}
                    onChange={(value) => setValue('tools', value, { shouldDirty: true })}
                  />
                </div>
              )}
            </div>
            {!is_introduction && (
              <div className='my-6'>
                <VariableAutoGenerator
                  question={watch('title')}
                  tagTitle='Message'
                  variable={watch('variable')}
                  setVariableName={(variable) =>
                    setValue('variable', variable, { shouldDirty: true })
                  }
                />
              </div>
            )}
          </div>
        </div>
        <div className='shrink-0'>
          <div className='flex justify-end gap-2 px-[12px] pb-[22px] pt-[12px]'>
            <div className='flex'>
              <Button.Reverse
                type='button'
                onClick={() => (isDirty ? setCloseModalOpen(true) : onCancel())}
                disabled={isLoading}
              >
                Cancel
              </Button.Reverse>
            </div>
            <div className='flex w-[80px]'>
              <Button disabled={isLoading || !isDirty}>
                {isLoading ? <LoadingSpinner /> : 'Save'}
              </Button>
            </div>
          </div>
        </div>
      </form>
      <AvoSlideCloseModal
        open={closeModalOpen}
        onContinue={() => setCloseModalOpen(false)}
        onDiscard={onCancel}
      />
      {!is_introduction && (
        <SlidePanel open={triggerFormOpen} onClose={() => setTriggerFormOpen(false)}>
          <div className='mx-4 mt-3'>
            <SituationBuilder {...triggerComponentProps} />
          </div>
        </SlidePanel>
      )}
    </>
  );
};
