import { useQuery } from '@tanstack/react-query';
import React, { createContext, ReactNode, useContext } from 'react';
import { mentionAPI } from '../../../api/mention';
import {
  DEMOGRAPHICS_SUGGESTION,
  LAB_DATA_SUGGESTION,
  MEDICATION_SUGGESTION,
  PROBLEM_LIST_SUGGESTION,
  PROVIDER_INFO_SUGGESTION,
  RADIOLOGY_REPORT_SUGGESTION,
  SUGGESTION,
  VITAL_SIGN_SUGGESTION,
} from '../../../hooks/useEHRVariables';
import { Suggestion, useSuggestions } from '../../../hooks/useSuggestions';
import { SuggestionTypes } from '../tiptap/tiptapInterfaces';
import { ModuleContext } from './ModuleContext';

export interface MentionContext {
  headings: Readonly<Suggestion[]>;
  suggestions: Suggestion[];
  excludedSuggestionTypes: SuggestionTypes[];
}

export const MentionContext = createContext<MentionContext>({
  headings: [],
  suggestions: [],
  excludedSuggestionTypes: [],
});

export const MentionProvider: React.FC<{
  children: ReactNode;
  excludedSuggestionTypes: SuggestionTypes[];
  /**
   * A parameter that allows the transformation of the choice variable, which was implemented in the backend,
   * to be handled on the frontend within certain components (such as data extractors and API boxes).
   * e.g. { code: 'variable_code_code/choice_code_code' ... } -> { code: 'variable_code_code', choice: { code: 'choice_code_code' ... }
   */
  performChoiceCodeSubstitution?: boolean;
}> = ({ children, excludedSuggestionTypes, performChoiceCodeSubstitution = false }) => {
  const { module } = useContext(ModuleContext);
  const { data } = useQuery({
    queryKey: [module?.type, module?.id, 'unique_code'],
    queryFn: mentionAPI.getUniqueCodes,
    enabled: !!module?.id,
  });

  const suggestions = useSuggestions();
  const filteredSuggestions = suggestions
    .filter((suggestion) => !excludedSuggestionTypes.includes(suggestion.type))
    .map((suggestion) => {
      if (!performChoiceCodeSubstitution || !suggestion.code) return suggestion;
      const [code, choiceCode] = suggestion.code.split('/');
      return {
        ...suggestion,
        code,
        choice:
          choiceCode && data?.choice[choiceCode]
            ? {
                code: choiceCode,
                type: 'choice',
                name: `__${data?.choice[choiceCode]}`,
              }
            : undefined,
      };
    });
  const filteredHeadings = Headings.filter(
    (heading) => !excludedSuggestionTypes.includes(heading.type)
  );
  return (
    <MentionContext.Provider
      value={{
        headings: filteredHeadings,
        suggestions: filteredSuggestions,
        excludedSuggestionTypes,
      }}
    >
      {children}
    </MentionContext.Provider>
  );
};

export const Headings: Suggestion[] = [
  { name: 'Variable', code: 'heading', type: 'variable' },
  { name: 'Formula', code: 'heading', type: 'formula' },
  { name: 'Conditional Text', code: 'heading', type: 'conditional_text' },
  { name: 'Infobox', code: 'heading', type: 'infobox' },
  { name: 'Reference', code: 'heading', type: 'reference' },
  { name: 'Knowledge Base', code: 'heading', type: 'knowledge_base' },
  ...SUGGESTION,
  ...LAB_DATA_SUGGESTION,
  ...VITAL_SIGN_SUGGESTION,
  ...MEDICATION_SUGGESTION,
  ...PROBLEM_LIST_SUGGESTION,
  ...DEMOGRAPHICS_SUGGESTION,
  ...RADIOLOGY_REPORT_SUGGESTION,
  ...PROVIDER_INFO_SUGGESTION,
  { name: 'Individual Order', code: 'heading', type: 'ehr_order' },
];
