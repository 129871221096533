import { Edit, ExpandMore, PlayArrow } from '@material-ui/icons';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import Select, { components } from 'react-select';

import { UseMutateFunction } from '@tanstack/react-query';
import AVOMDIcon from 'assets/icons/avomdIcon';
import SearchIcon from 'assets/icons/searchIcon';
import profileAvatar from 'assets/images/profileAvatar.png';
import { ProfileAvatar, UserButton, UserName } from 'components/bars/styles';
import history from 'components/utils/History';
import { StaffOnlyBadgeType, StaffOnlyWrapper } from 'components/utils/StaffOnlyWrapper';
import { StyledMenuItem, TearDropMenu } from 'components/utils/styled-components/FormStyle';
import { AutoHidingTooltip } from 'components/utils/tooltip/AutoHidingTooltip';
import 'css/custom.css';
import { hasViewOnlyPermission } from 'utils/permissions';
import { ROLES, TeamListItem, TeamWithMates } from '../../api/team';
import {
  HAS_PUBLISH_PERMISSIONS,
  HAS_VIEW_PERMISSIONS,
  HOME_ROUTE,
  NUMBER_ROUTE,
  PROFILE_ROUTE,
} from '../../constants';
import { Authentication, withAuthentication } from '../../hooks/useAuthentication';
import { withTeam } from '../../hooks/useTeam';
import ShareLink from './partials/share-link';
import TeamManagement from './partials/team-management';
import {
  ActionButton,
  Logo,
  ModuleEditorButtonBox,
  ModuleEditorTitle,
  ModuleEditorWrapper,
  ModuleEditorWrapperAvatar,
  ModuleTitleBoxEditor,
  NavButton,
  NavLink,
  NumericsNavLink,
  StyledAppBar,
  StyledBar,
  StyledBox,
} from './styles';

interface NavBarProps {
  activeState?: any;
  isCalculatorBoard?: any;
  isKnowledgeBase?: any;
  object?: any;
  isCoversationBoard?: any;
  moduleEditor?: any;
  editLink?: any;
  simulate?: any;
  calculatorId?: any;
  toggleReleaseModal?: Function;
  verify?: any;
  publishButtonTooltipOpen?: boolean;
  publishButtonTooltipMessage?: string | React.ReactNode;
  logout?: () => void;
  isLoggedIn?: boolean;
  authentication?: Authentication;
  team?: TeamWithMates;
  teamList?: TeamListItem[];
  setTeam?: UseMutateFunction<any, unknown, number, unknown>;
}
interface NavBarState {
  userButton: any;
  open: boolean;
  isRedirect: boolean;
  activeState: any;
  warningModal: boolean;
  selectedTeam: any;
  toggleIcon: any;
  redirectToAccessDenied: any;
  team: any;
}
class NavBar extends Component<NavBarProps, NavBarState> {
  constructor(props: NavBarProps) {
    super(props);
    this.state = {
      userButton: null,
      open: false,
      isRedirect: false,
      activeState: 'MainPage',
      warningModal: false,
      selectedTeam: {},
      toggleIcon: true,
      redirectToAccessDenied: false,
      team: {},
    };
  }

  componentDidMount() {
    this.setState({
      activeState: this.props.activeState,
    });
  }

  toggleIcon = (open) => {
    this.setState({
      toggleIcon: open === 0 ? true : false,
    });
  };

  handleChange = async (team) => {
    const user = this.props.authentication?.user;
    const isStaff = user?.is_staff || false;
    const teamData = this.props.teamList?.find((userTeam) => userTeam?.id === team?.id);
    const isTeamMember = !!teamData?.is_team_member;

    if (isStaff && !isTeamMember) {
      this.setState({ redirectToAccessDenied: true, team });
      return;
    }

    this.setState({ selectedTeam: team, open: false });
    this.props.setTeam?.(team?.id);
    this.setState({ isRedirect: true });
  };

  handleMenu = (event) => this.setState({ userButton: event.currentTarget });

  handleMenuClose = () => this.setState({ userButton: null });

  handleToggle = (modalName) => {
    this.setState(
      (previous) =>
        ({
          [modalName]: !previous[modalName],
        }) as Pick<NavBarState, any>
    );
  };

  render() {
    const user = this.props.authentication?.user;
    const teams = this.props.teamList;
    const isLoggedIn = this.props.isLoggedIn;
    const isTrialUser = user?.user_type === 'trial';
    const type = this.props.isCalculatorBoard
      ? 'calculator'
      : this.props.isKnowledgeBase
        ? 'knowledge_base'
        : 'algo';

    const object = this.props.object || {};
    const isMirror = object?.isMirror;
    const permissionType = object?.current_user_role;

    const teamRole = this.props.team?.current_teammate?.role;
    const teamAdmin = teamRole === ROLES.Admin;
    const hasPublishPermission =
      teamAdmin ||
      HAS_PUBLISH_PERMISSIONS.includes(permissionType) ||
      (!permissionType && !!teamRole && HAS_PUBLISH_PERMISSIONS.includes(object?.permission_type));

    const hasAllPermissions =
      teamAdmin ||
      HAS_VIEW_PERMISSIONS.includes(permissionType) ||
      (!permissionType && !!teamRole && HAS_VIEW_PERMISSIONS.includes(object?.permission_type));

    // team selection dropdown styling & components
    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        background: 'white',
        height: '48px',
        fontSize: '16px !important',
        paddingLeft: '2vh !important',
        color: '#767676 !important',
        boxShadow: 0,
        width: '450px !important',
        minHeight: '55px !important',
        border: '1px solid #E9E9E9 !important',
        borderRadius: '44px !important',
        borderBottomLeftRadius:
          state.isFocused && state.menuIsOpen ? '0 !important' : '40px !important',
        borderBottomRightRadius:
          state.isFocused && state.menuIsOpen ? '0 !important' : '40px !important',
        borderTopLeftRadius:
          state.isFocused && state.menuIsOpen ? '20px !important' : '40px !important',
        borderTopRightRadius:
          state.isFocused && state.menuIsOpen ? '20px !important' : '40px !important',
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: '0px !important',
      }),
      menu: (provided) => ({
        ...provided,
        border: '1px solid #E9E9E9',
        borderTop: 'none !important',
        borderBottomLeftRadius: '20px',
        borderBottomRightRadius: '20px',
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        maxWidth: '450px !important',
      }),
      option: (provided, states) => ({
        ...provided,
        color: states.isFocused || states.isSelected ? 'white' : '#767676',
      }),
      valueContainer: (provided) => ({
        ...provided,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      }),
      singleValue: (provided) => ({
        ...provided,
        marginLeft: this.state.toggleIcon ? '7px' : '22px',
        //marginBottom: '10px',
        color: this.state.toggleIcon ? '#616262' : '#DEDEDE !important',
      }),
      placeholder: (provided) => ({
        ...provided,
        marginLeft: this.state.toggleIcon ? '-12px' : '5px',
        marginRight: this.state.toggleIcon ? '2px' : '0px',
        color: this.state.toggleIcon ? '#616262' : '#DEDEDE !important',
        marginTop: '1px',
      }),
      indicatorsContainer: (provided) => ({
        ...provided,
        marginRight: '20px',
      }),
    };

    const ValueContainer = (props) => {
      const { children } = props;
      return (
        components.ValueContainer && (
          <components.ValueContainer {...props}>
            {!this.state.toggleIcon ? (
              <SearchIcon style={{ position: 'absolute', left: '1px' }} />
            ) : null}
            {children}
          </components.ValueContainer>
        )
      );
    };

    const DropdownIndicator = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <ExpandMore />
        </components.DropdownIndicator>
      );
    };

    const SingleValue = (props) => {
      return (
        components.SingleValue && (
          <components.SingleValue {...props}>
            {!this.state.toggleIcon ? 'Type here to search' : this.props.team?.name}
          </components.SingleValue>
        )
      );
    };

    if (this.state.isRedirect && window.location.pathname !== '/') {
      return <Navigate to={HOME_ROUTE} />;
    }

    const isBoard = this.props.isCoversationBoard || this.props.isCalculatorBoard;

    return (
      <StyledBox className={isBoard && 'main-app-bar'}>
        <StyledAppBar position='fixed' className={isBoard && 'main-app-bar'}>
          <StyledBar className={isBoard && 'inner-app-bar'}>
            <NavLink to={HOME_ROUTE}>
              <Logo className={isBoard && 'main-logo'}>
                <AVOMDIcon />
              </Logo>
            </NavLink>

            {isLoggedIn && !this.props.moduleEditor && (
              <StaffOnlyWrapper type={StaffOnlyBadgeType.STAFF}>
                <NavLink to={NUMBER_ROUTE}>
                  <NavButton
                    style={
                      // eslint-disable-next-line
                      this.state.activeState == 'Numerics'
                        ? {
                            color: '#08A88E',
                            borderBottom: '3px solid #08A88E',
                          }
                        : {}
                    }
                  >
                    {' '}
                    Numerics
                  </NavButton>
                </NavLink>
              </StaffOnlyWrapper>
            )}
            {this.props.moduleEditor && (
              <ModuleEditorWrapper className='module-navigation'>
                <ModuleTitleBoxEditor
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                  className={
                    this.props.isCoversationBoard || this.props.isCalculatorBoard
                      ? 'module-title mx-3'
                      : 'mx-3'
                  }
                >
                  <ModuleEditorTitle
                    className={
                      this.props.isCoversationBoard || this.props.isCalculatorBoard
                        ? 'module-title'
                        : ''
                    }
                  >
                    {isMirror ? object?.mirrorName : object?.name}
                  </ModuleEditorTitle>
                  {!hasViewOnlyPermission(type) && (
                    <NavLink to={this.props.editLink}>
                      <Edit
                        style={{
                          marginRight: 20,
                          marginLeft: 20,
                          fill: '#08A88E',
                          width: '20px',
                          color: '#08A88E',
                          height: '20px',
                        }}
                      />
                    </NavLink>
                  )}
                </ModuleTitleBoxEditor>

                <ModuleEditorButtonBox>
                  {(hasAllPermissions || isMirror) && (
                    <ActionButton
                      style={{ marginRight: '9px' }}
                      variant='contained'
                      className='navbar-button'
                      onClick={this.props.simulate}
                      disabled={this.props.isKnowledgeBase}
                      startIcon={<PlayArrow className='nav-icon' />}
                    >
                      Simulate
                    </ActionButton>
                  )}

                  {(hasAllPermissions || isMirror || !this.props.isKnowledgeBase) && (
                    <ShareLink
                      calculatorId={this.props.calculatorId}
                      openSyncModal={() =>
                        this.props.toggleReleaseModal &&
                        this.props.toggleReleaseModal('warningModal')
                      }
                    />
                  )}

                  {!hasViewOnlyPermission(type) && hasPublishPermission && (
                    <AutoHidingTooltip
                      message={this.props.publishButtonTooltipMessage}
                      seconds={10}
                      open={this.props.publishButtonTooltipOpen}
                    >
                      <ActionButton
                        variant='contained'
                        disabled={isTrialUser}
                        className='navbar-button navbar-responsive responsive-width'
                        style={{ marginRight: '9px' } as React.CSSProperties}
                        onClick={() =>
                          this.props.toggleReleaseModal &&
                          this.props.toggleReleaseModal('warningModal')
                        }
                      >
                        Publish
                      </ActionButton>
                    </AutoHidingTooltip>
                  )}

                  {hasAllPermissions && !isMirror && (
                    <ActionButton
                      variant='contained'
                      className='navbar-button navbar-responsive'
                      style={{ marginRight: '9px' }}
                      onClick={this.props.verify}
                    >
                      Verify
                    </ActionButton>
                  )}
                </ModuleEditorButtonBox>
              </ModuleEditorWrapper>
            )}

            {isLoggedIn && !this.props.moduleEditor && !!this.props.team?.id && (
              <TeamManagement
                activeState={this.props.activeState}
                moduleEditor={this.props.moduleEditor}
              />
            )}

            {/* split button for team selection */}
            <ModuleEditorWrapperAvatar>
              {isLoggedIn && !this.props.moduleEditor && Array.isArray(teams) && teams.length && (
                <div style={{ marginLeft: 'auto' }}>
                  <Select
                    onMenuOpen={() => this.toggleIcon(1)}
                    onMenuClose={() => this.toggleIcon(0)}
                    blurInputOnSelect={true}
                    classNamePrefix='react-select'
                    styles={{
                      ...customStyles,
                      input: (provided) => ({
                        ...provided,
                        color: '#737373',
                        position: teams.length > 0 ? 'absolute' : 'relative',
                        // marginTop: '16px',
                        marginLeft: '22px',
                      }),
                    }}
                    components={{
                      IndicatorSeparator: () => null,
                      ValueContainer,
                      SingleValue,
                      DropdownIndicator,
                    }}
                    options={teams}
                    getOptionLabel={(team) => team?.name}
                    getOptionValue={(team) => (!!team ? String(team.id) : '')}
                    onChange={this.handleChange}
                    placeholder={
                      this.state.toggleIcon
                        ? this.props.team?.name || 'Select Team'
                        : 'Type here to search'
                    }
                    theme={(theme) => ({
                      ...theme,
                      borderBottom: '1px solid lightgrey',
                      colors: {
                        ...theme.colors,
                        primary25: '#08A88E',
                        primary: '#08A88E',
                        primary50: '#08A88E',
                      },
                    })}
                  />
                </div>
              )}

              {isLoggedIn && this.props.moduleEditor && (
                <StaffOnlyWrapper type={StaffOnlyBadgeType.STAFF}>
                  <NavLink to={NUMBER_ROUTE}>
                    <NumericsNavLink variant='text'> Numerics </NumericsNavLink>=
                  </NavLink>
                </StaffOnlyWrapper>
              )}

              {isLoggedIn && this.props.moduleEditor && (
                <TeamManagement
                  activeState={this.props.activeState}
                  moduleEditor={this.props.moduleEditor}
                />
              )}
            </ModuleEditorWrapperAvatar>

            {/* profile menu */}
            {isLoggedIn && user && (
              <>
                <UserButton onClick={this.handleMenu}>
                  <ProfileAvatar alt='Admin' src={user.profile_picture || profileAvatar} />
                  <UserName variant='body2'>{user.name}</UserName>
                  <ExpandMore style={{ color: '#A6A6A6' }} />
                </UserButton>

                <TearDropMenu
                  id='menu-appbar'
                  anchorEl={this.state.userButton}
                  open={Boolean(this.state.userButton)}
                  onClose={this.handleMenuClose}
                  style={{ marginTop: '18px' }}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <StyledMenuItem onClick={() => history.replace(PROFILE_ROUTE)}>
                    Profile
                  </StyledMenuItem>
                  <StyledMenuItem onClick={this.props.logout}> Sign Out </StyledMenuItem>
                </TearDropMenu>
              </>
            )}
          </StyledBar>
        </StyledAppBar>

        <StyledBar />
      </StyledBox>
    );
  }
}

const mapStateToProps = (state) => ({ ...state });

export default withTeam(withAuthentication(connect(mapStateToProps, {})(NavBar)));
