import { UIElement } from 'api/moduleElement';
import clsx from 'clsx';
import { MessageForm } from 'components/panels/MessageForm';
import { AvoEditorPreview } from 'components/utils/avoeditor/AvoEditorPreview';
import { Icons } from 'components/utils/Icons';
import { ModuleContext } from 'components/utils/module/ModuleContext';
import { SlidePanel } from 'components/utils/panels/AvoSlidePanel';
import { H4 } from 'components/utils/typo';
import { useTriggers } from 'hooks/module/resources/useTriggers';
import { ChevronDown } from 'lucide-react';
import { useContext, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MODULE_TYPES } from '../../../constants';
import { DraggableContext, ModuleElementContext } from '../ModuleBoard';
import { CardContainer } from './utils/CardContainer';
import { GroupCardContext } from './GroupCard';

interface MessageCardProps {
  element: UIElement;
}

export interface MessageFormVariable {
  module?: number;
  calculator?: number;
  container: number;
  trigger: number;
  title: string;
  variable: string;
  description_json?: any;
  description_json_tiptap: any;
  detail_enabled: boolean;
  detail_json?: any;
  detail_json_tiptap: any;
  question_items: any[];
  examples: any[];
  tools: any[];
  position?: number;
}

export const MessageCard = ({ element }: MessageCardProps) => {
  const [formOpen, setFormOpen] = useState(!element.id);
  const { setIsDragDisabled } = useContext(DraggableContext);
  const { groupCardId } = useContext(GroupCardContext);
  useEffect(() => setIsDragDisabled(formOpen), [formOpen]);

  const [showMore, setShowMore] = useState(false);
  const { module } = useContext(ModuleContext);
  const detailContentRef = useRef<HTMLDivElement>(null);
  const {
    data: { triggers, candidate_triggers },
  } = useTriggers();
  const triggerList = [...triggers, ...candidate_triggers];
  const alwayOnTrigger = triggerList.find((data) => data.title === 'Always On');
  const defaultValues = {
    module: module && module.type === MODULE_TYPES.ALGO ? module.id : undefined,
    calculator: module && module.type === MODULE_TYPES.CALCULATOR ? module.id : undefined,
    question_items: element.question_items ?? [],
    examples: element.examples ?? [],
    tools: element.tools ?? [],
    title: element.title ?? (element.is_introduction ? 'Introduction' : ''),
    trigger: element.trigger ? element.trigger.id : alwayOnTrigger?.id,
    container: groupCardId,
    variable: element.variable_item?.name ?? '',
    detail_enabled: element.detail_enabled ?? false,
    description_json: element.description_json,
    description_json_tiptap: element.description_json_tiptap,
    detail_json: element.detail_json,
    detail_json_tiptap: element.detail_json_tiptap,
    position: element.position,
  };

  const form = useForm<MessageFormVariable>({ defaultValues });
  const [noteGenerators, setNoteGenerators] = useState<any[]>([]);

  useEffect(() => {
    if (!formOpen) {
      const noteGenerators = form
        .watch('tools')
        ?.filter((item) => item?.section === 'T' && item?.infobox?.infobox_type === 'NG');

      setNoteGenerators(noteGenerators);
    }
  }, [formOpen, form.watch('question_items')]);

  const { refetchModuleElements, clearModuleElements } = useContext(ModuleElementContext);
  const onSave = () => {
    try {
      refetchModuleElements();
      setFormOpen(false);
    } catch (e) {
      console.log(e);
    }
  };
  const onCancel = () => {
    clearModuleElements();
    form.reset(defaultValues);
    setFormOpen(false);
  };

  return (
    <FormProvider {...form}>
      <CardContainer
        elementId={element.id}
        onOpenPanel={() => setFormOpen(true)}
        isPreviewing={formOpen}
        variables={[{ type: 'message', name: form.watch('variable') }]}
        hideTrigger={element.is_introduction}
      >
        {!!form.watch('tools').length && (
          <div className='flex flex-wrap gap-1'>
            {form.watch('tools').map((tool) => (
              <div className='flex items-center gap-1'>
                {!!tool.infobox ? <Icons.ToolInfobox /> : <Icons.ToolCalc />}
                <span className='line-clamp-1 font-bold text-primary-600'>
                  {tool.label ||
                    tool.infobox?.shortened_title ||
                    tool.image?.representation_phrase ||
                    tool.legacy_calculator?.title ||
                    tool.calculator?.name}
                </span>
              </div>
            ))}
          </div>
        )}
        {!!form.watch('examples').length && (
          <>
            <div className='mb-1 mt-2 min-h-14 rounded border border-gray-200 bg-gray-100 px-3 py-2'>
              <div className='text-button-1'>Example</div>
              <div className='line-clamp-2 flex flex-wrap items-center'>
                {form
                  .watch('examples')
                  .map(
                    (example, idx) =>
                      example.label ||
                      example.text?.statement ||
                      example.infobox?.shortened_title ||
                      example.image?.representation_phrase
                  )
                  .join(', ')}
              </div>
            </div>
          </>
        )}
        <H4 className='text-gray-900'>
          {element.is_introduction ? 'Introduction' : form.watch('title')}
        </H4>
        <AvoEditorPreview
          contentTiptap={form.watch('description_json_tiptap')}
          contentDraftJS={form.watch('description_json')}
          // isLineClampTwo={!element.is_introduction && !showMore}
        />
        <div
          ref={detailContentRef}
          style={{
            height:
              showMore && detailContentRef.current
                ? `${detailContentRef.current.scrollHeight}px`
                : '0px',
            transition: `height ${detailContentRef.current ? Math.min(1000, Math.max(300, detailContentRef.current.scrollHeight * 0.5)) : 300}ms ease-in-out`,
          }}
          className='overflow-hidden transition-all'
        >
          <AvoEditorPreview
            contentTiptap={form.watch('detail_json_tiptap')}
            contentDraftJS={form.watch('detail_json')}
          />
        </div>

        {!!noteGenerators.length && (
          <div>
            <div className='text-button-1'>Smart Links</div>
            {noteGenerators.map((item, idx) => (
              <div className='flex items-center gap-1'>
                <Icons.ToolInfobox />
                <span className='line-clamp-1 font-bold text-primary-600'>
                  {item?.label || item?.infobox?.shortened_title}
                </span>
              </div>
            ))}
          </div>
        )}

        {form.watch('detail_enabled') && (
          <div
            className='flex cursor-pointer items-center gap-1 py-2'
            onClick={() => form.watch('detail_enabled') && setShowMore((prev) => !prev)}
          >
            <ChevronDown
              size={20}
              className={clsx('text-primary-600 transition-all', showMore && 'rotate-180')}
            />
            <H4 className='text-primary-600'>{showMore ? 'Hide' : 'Details'}</H4>
          </div>
        )}
      </CardContainer>
      <SlidePanel open={formOpen}>
        <MessageForm
          messageId={element.id}
          onSave={onSave}
          onCancel={onCancel}
          is_introduction={element.is_introduction}
        />
      </SlidePanel>
    </FormProvider>
  );
};
