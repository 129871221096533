import { Box, Button, Grid } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import 'components/utils/modals/modal.css';
import { Input, Label } from 'reactstrap';
import styled from 'styled-components';

const AddLabel = styled(Label)`
  font-family: Roboto !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;
  text-transform: uppercase;

  color: #bdbdbd !important;
`;

const AddButton = withStyles(() => ({
  root: {
    background: '#08A88E !important',
    borderRadius: '6px 6px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#ffffff !important',
    minWidth: '95px',
    height: '40px',
    '&:hover': {
      background: '#08A88E !important',
      boxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
      WebkitBoxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
      MozBoxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
    },
    '&:focus': {
      background: '#08A88E !important',
      boxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
      WebkitBoxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
      MozBoxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
    },
  },
}))(Button);

export const CreateButton = withStyles(() => ({
  root: {
    width: '148px',
    height: '60px',
    color: '#ffffff',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '25px',
    textAlign: 'center',
    textTransform: 'none',
    background: '#08A88E',
    borderRadius: '100px',
    boxShadow: '0px 20px 30px rgba(0, 0, 0, 0.15)',
    WebkitBoxShadow: '0px 20px 30px rgba(0, 0, 0, 0.15)',
    MozBoxShadow: '0px 20px 30px rgba(0, 0, 0, 0.15)',
    '&:hover': {
      background: '#08A88E',
      boxShadow: '0px 20px 30px rgba(0, 0, 0, 0.15)',
      WebkitBoxShadow: '0px 20px 30px rgba(0, 0, 0, 0.15)',
      MozBoxShadow: '0px 20px 30px rgba(0, 0, 0, 0.15)',
    },
    '&:focus': {
      background: '#08A88E',
      boxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
      WebkitBoxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
      MozBoxShadow: '2px 2px 15px rgba(0, 0, 0, 0.1)',
    },
  },
  disabled: {
    backgroundColor: '#E5EDF0 !important',
    color: '#ffffff !important',
  },
}))(Button);

export const CancelButton = withStyles(() => ({
  root: {
    lineHeight: '25px',
    textAlign: 'center',
    textTransform: 'none',
    background: 'transparent',
    color: '#08A88E',
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
  },
}))(Button);

const Examples = styled(Grid)`
  width: 99%;
  background: #fbfbfb;
  border: 1px dashed #e4e3e3;
  box-sizing: border-box;
  border-radius: 10px;
`;
export const FixedRow = styled(Box)`
  position: sticky;
  bottom: 0;
  right: 0;
  display: flex;
  background-color: white;
  align-items: center;
  width: 100%;
  height: 100px;
  z-index: 9;
`;
export const UnderlyingElement = styled(Box)`
  width: 100%;
  height: 100px;
  background-color: white;
`;

export const MemberInput = styled(Input)`
  background: #ffffff !important ;
  border: 1px solid #cccccc !important;
  max-width: 850px;
  height: 45px;
  //box-sizing: border-box !important ;
  border-radius: 5px !important ;
  text-indent: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  :focus {
    border: 1px solid #08a88e !important;
  }

  ::-webkit-input-placeholder {
    font-weight: normal;
  }
  ::-moz-placeholder {
    font-weight: normal;
  }
  ::placeholder {
    font-weight: normal;
  }
  font-weight: bold;
`;

const StyledFormControlLabel = withStyles((theme) => ({
  label: {
    marginTop: 5,
  },
}))(FormControlLabel);
