import { ROLES, TeamMate } from '../../api/team';
import { Listbox } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { DeleteModal } from '../utils/modals/DeleteModal';
import { useMemo, useState } from 'react';
import { useTeam } from '../../hooks/useTeam';
import { useAuthentication } from '../../hooks/useAuthentication';
import { useNavigate } from 'react-router-dom';
import { HOME_ROUTE } from '../../constants';
import { Icons } from '../utils/Icons';

export function MemberActionColumn({ member }: { member: TeamMate }) {
  const { team, patchRole, deleteMember } = useTeam();
  const { authentication } = useAuthentication();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isAdmin = useMemo(
    () =>
      team.teammates.some(
        (member) => member.role === ROLES.Admin && member.user.id === authentication.user?.id
      ),
    [team, authentication]
  );
  const uniqueAdminNo = useMemo(() => {
    const adminMembers = team.teammates.filter((member) => member.role === ROLES.Admin);
    return adminMembers.length === 1 ? adminMembers.pop()?.id : undefined;
  }, [team]);

  const handleChange = (item) => {
    patchRole({
      id: member.id,
      role: item,
    });
  };

  const navigate = useNavigate();
  const handleDelete = () => {
    deleteMember(member.id);
    if (authentication.user?.id && authentication.user.id === member.user.id) {
      navigate(HOME_ROUTE, { replace: true });
    }
  };

  return (
    <div className='relative flex w-5/6 justify-between'>
      {isAdmin ? (
        <Listbox onChange={handleChange}>
          {({ open }) => (
            <>
              <Listbox.Button>
                <div className='flex w-28 items-center justify-between gap-[4px]'>
                  <p className='text-body-1'>{member.role_display_value}</p>
                  <Icons.ChevronDown className={twMerge(open && 'rotate-180', 'transition')} />
                </div>
              </Listbox.Button>
              <Listbox.Options className='absolute top-10 z-10 max-h-[310px] w-[160px] overflow-y-auto rounded-sm bg-white py-[4px] shadow-04'>
                {Object.values(ROLES)
                  .filter((role) => member.role !== role)
                  .map((role) => (
                    <Listbox.Option
                      key={role}
                      value={role}
                      className='cursor-pointer px-[12px] py-[10px]'
                    >
                      <div className='flex justify-between'>
                        <p>{Object.keys(ROLES).find((key) => ROLES[key] === role)}</p>
                        <Icons.Check />
                      </div>
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </>
          )}
        </Listbox>
      ) : (
        <p className='text-body-1'>{member.role_display_value}</p>
      )}
      {isAdmin && uniqueAdminNo !== member.id && (
        <Icons.TrashBin
          className='cursor-pointer fill-gray-600 hover:fill-error'
          onClick={() => setIsOpen(true)}
        />
      )}
      <DeleteModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onDelete={handleDelete}
        title='Delete Member'
        content='Are you sure you want to delete this member?'
      />
    </div>
  );
}
