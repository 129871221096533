import Mention from '@tiptap/extension-mention';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { MentionNode } from './MentionNode';

export const CustomMention = Mention.extend({
  selectable: true,
  addNodeView() {
    return ReactNodeViewRenderer(MentionNode);
  },
  addAttributes() {
    return {
      ...this.parent?.(),
      name: getAttributeObj('name', 'data-mention-name'),
      code: getAttributeObj('code', 'data-mention-code'),
      type: getAttributeObj('type', 'data-mention-type'),
      decimalPlaces: getAttributeObj('decimalPlaces', 'data-mention-decimal-places'),
      decimalRounding: getAttributeObj('decimalRounding', 'data-mention-decimal-rounding'),
      hasUnsignedNote: getAttributeObj('hasUnsignedNote', 'data-mention-has-unsigned-note'),
      days: getAttributeObj('days', 'data-mention-days'),
      past24HoursExcluded: getAttributeObj(
        'past24HoursExcluded',
        'data-mention-past-24hours-excluded'
      ),
      unique_code: getAttributeObj('unique_code', 'data-unique-code'),
      indication: getAttributeObj('indication', 'data-indication'),
      description: getAttributeObj('description', 'data-description'),
      amount: getAttributeObj('amount', 'data-mention-amount'),
      choice: getAttributeObj('choice', 'data-mention-choice'),
    };
  },
  renderText({ node }) {
    return node.attrs.code || node.attrs.unique_code;
  },
});

const getAttributeObj = (name, attribute) => {
  return {
    default: undefined,
    parseHTML: (element) => {
      const attributeValue = element.getAttribute(attribute);
      if (attributeValue === null) return null;
      if (attributeValue === undefined) return undefined;
      switch (name) {
        case 'amount':
        case 'id':
        case 'days':
          return Number(attributeValue);
        case 'hasUnsignedNote':
        case 'past24HoursExcluded':
          return Boolean(attributeValue);
        case 'indication':
        case 'choice':
          return JSON.parse(attributeValue);
        // case 'name':
        // case 'code':
        // case 'type':
        // case 'decimalRounding':
        // case 'decimalPlaces':
        // case 'unique_code':
        // case 'description':
        default:
          return attributeValue;
      }
    },
    renderHTML: (attributes) => {
      if (!attributes?.[name]) return {};
      switch (name) {
        case 'choice':
        case 'indication':
          return { [attribute]: JSON.stringify(attributes?.[name]) };
        default:
          return { [attribute]: attributes?.[name] };
      }
    },
  };
};
