import { moduleElementAPI, Positions, ResourceTypes, UIElement } from 'api/moduleElement';
import { ModuleContext } from 'components/utils/module/ModuleContext';
import { SlidePanel } from 'components/utils/panels/AvoSlidePanel';
import { H3 } from 'components/utils/typo';
import { useTriggers } from 'hooks/module/resources/useTriggers';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MODULE_TYPES } from '../../../constants';
import { CONTAINER_CARD, DraggableContext, ModuleElementContext } from '../ModuleBoard';
import { CardContainer } from './utils/CardContainer';
import { getGroupVariables } from '../../module-detail/container/GroupCardUtil';
import Button from '../../utils/Button';
import { PlusIcon } from 'lucide-react';
import { CardBoard } from '../CardBoard';
import { GroupForm } from '../../panels/GroupForm';
import { Menu } from '@headlessui/react';
import { twJoin } from 'tailwind-merge';
import { useMutation, useQuery } from '@tanstack/react-query';

export const GroupCardContext = createContext<{
  // Indicates if the UI and behavior differ inside a group card
  isInsideGroupCard: boolean;
  // Only used when creating child cards via the group card button without opening the panel.
  groupCardId?: number;
}>({
  isInsideGroupCard: false,
});

interface GroupCardProps {
  element: UIElement;
}

export interface GroupFormVariable {
  id?: number;
  name: string;
  variable: string;
  trigger: number;
  module?: number;
  position?: number;
  ui_elements: UIElement[];
}

export const GroupCard = ({ element }: GroupCardProps) => {
  const {
    refetchModuleElements,
    clearModuleElements,
    addModuleElement,
    deleteUIElement,
    duplicateUIElement,
  } = useContext(ModuleElementContext);

  const { module } = useContext(ModuleContext);
  const {
    data: { triggers, candidate_triggers },
  } = useTriggers();
  const triggerList = [...triggers, ...candidate_triggers];
  const alwayOnTrigger = triggerList.find((data) => data.title === 'Always On');

  const { refetchGroupUiElements } = useGroupUiElements();

  // List of child card IDs created in the group card panel
  const [tempChildElementIds, setTempChildElementIds] = useState<number[]>([]);

  // Whether the group card panel is open
  const [formOpen, setFormOpen] = useState(!element.id);
  // Whether the child card panel is open
  const [childCardFormOpen, setChildCardFormOpen] = useState(false);
  const { isDragDisabled, setIsDragDisabled } = useContext(DraggableContext);
  useEffect(() => {
    setIsDragDisabled(formOpen || childCardFormOpen);
  }, [formOpen, childCardFormOpen]);

  const defaultValues: GroupFormVariable = {
    id: element.id || undefined,
    name: element.name || '',
    variable: element.variable,
    trigger: element.trigger ? element.trigger.id : alwayOnTrigger?.id,
    module: module && module.type === MODULE_TYPES.ALGO ? module.id : undefined,
    ui_elements: element.ui_elements,
    position: element.position,
  };

  const form = useForm<GroupFormVariable>({ defaultValues });
  const uiElements = form.watch('ui_elements');

  // Toggle panels when starting or finishing/canceling child card creation.
  // Close group panel and open child panel during creation.
  // Reopen group panel and close child panel when done or canceled.
  useEffect(() => {
    if (uiElements.some((element) => !element.id) && formOpen) {
      setFormOpen(false);
      setChildCardFormOpen(true);
    } else if (childCardFormOpen) {
      setFormOpen(true);
      setChildCardFormOpen(false);
    }
  }, [uiElements]);

  // addModuleElement in GroupPanel adds an empty child card to element.ui_elements to sync with the form for rendering.
  // If canceled, the empty child card is removed and ui_elements are resynced.
  useEffect(() => {
    const newEmptyCard = (element.ui_elements as UIElement[]).find((element) => !element.id);
    if (!!newEmptyCard) {
      form.setValue('ui_elements', [...uiElements, newEmptyCard]);
    } else if (uiElements.length !== element.ui_elements.length) {
      // When canceling child card creation (with unequal list lengths), remove empty child card.
      form.setValue(
        'ui_elements',
        element.ui_elements.filter((element) => !!element.id)
      );
    } else {
      // When a child card is repositioned via drag-and-drop (with equal list lengths), update the form's ui_elements positions.
      const positionMap = new Map<number, number>();
      element.ui_elements.forEach((item: UIElement) => {
        positionMap.set(item.id, item.position);
      });
      form.setValue(
        'ui_elements',
        [...element.ui_elements].sort((a, b) => {
          const posA = positionMap.get(a.id) ?? a.position;
          const posB = positionMap.get(b.id) ?? b.position;
          return posA - posB;
        })
      );
    }
  }, [element.ui_elements]);

  const saveChildCardsPosition = useGroupOrderingMutation();
  // 1. Creating child cards within the group panel:
  //    - ModuleBoard calls refetchModuleElements after a card is created, refreshing all cards with API data.
  //    - This deletes the new group card and prevents adding child cards.
  //    - To prevent this, group card children use an alternative function below.
  // 2. Creating child cards via the card's bottom button:
  //    - After creation, the card's position isn't set correctly and needs to be specified.
  //    - Since position can't be set externally, it's handled within an internal function.
  const onRefetch = (id?: number) => {
    // In case 1, Created cards are generated outside the group card.
    // Refetch cards, insert the new card into the form's ui_elements, and remove the empty child card from element.ui_elements.
    refetchGroupUiElements().then(async (data) => {
      const newSavedCard = data?.find((element) => element.id === id)!;
      setTempChildElementIds([...tempChildElementIds, id!]);
      const childElements = uiElements.filter((element) => !!element.id).concat(newSavedCard);
      form.setValue('ui_elements', childElements, { shouldDirty: true });

      if (formOpen || childCardFormOpen) {
        // Makes the panel close properly
        clearModuleElements();
      } else {
        // In case 2, Reset the child card's position after creation.
        await saveChildCardsPosition(childElements);
        onSave();
      }
    });
  };

  // Child cards created in the group panel are removed if the group card is canceled or deleted from the list within the panel.
  const { mutateAsync: deleteChildElement } = useMutation({
    mutationFn: moduleElementAPI.deleteUIElement,
    onSuccess: refetchGroupUiElements,
  });

  const onCancel = async () => {
    // If a child card is created in the group panel and the group card edit is canceled, remove the created child card
    await Promise.all(tempChildElementIds.map(async (id) => await deleteChildElement(id)));
    setTempChildElementIds([]);
    clearModuleElements();
    form.reset(defaultValues);
    setFormOpen(false);
  };

  // Excluding the created card from the list deletes it.
  const handleDelete = async (id: number) => {
    if (tempChildElementIds.includes(id)) {
      await deleteChildElement(id);
      setTempChildElementIds(tempChildElementIds.filter((elementId) => elementId !== id));
    }
  };

  const onSave = async () => {
    try {
      // After creating a child card in the group panel, deleting it from the list allows re-adding via the combo box.
      // When saving the group panel, remove any created cards that are excluded from the list.
      await Promise.all(
        tempChildElementIds
          .filter((id) => !uiElements.some((e) => e.id === id))
          .map(async (id) => await deleteChildElement(id))
      );
      refetchModuleElements();
      setTempChildElementIds([]);
      setFormOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  const variables = useMemo(() => getGroupVariables(element.ui_elements), [element]);

  return (
    <FormProvider {...form}>
      <CardContainer
        elementId={element.id}
        // Prevent double-click events from propagating to the entire child component when the child card panel is open
        onOpenPanel={() => !isDragDisabled && setFormOpen(true)}
        isPreviewing={formOpen}
        variables={variables}
        className={twJoin(formOpen && 'pointer-events-none')}
      >
        {form.watch('name') && (
          <>
            <H3 className='mx-4 border-b border-gray-200 pb-3 text-xl text-gray-900'>
              {form.watch('name')}
            </H3>
          </>
        )}
        {uiElements.length > 0 && (
          <DraggableContext.Provider
            value={{
              isDragDisabled: isDragDisabled || formOpen || childCardFormOpen,
              setIsDragDisabled,
            }}
          >
            {/* Disabled due to UX issues */}
            {/*
            <ModuleElementContext.Provider
              value={{
                refetchModuleElements: onRefetch,
                clearModuleElements,
                addModuleElement,
                deleteUIElement,
                duplicateUIElement,
              }}
            >
            */}
            <GroupCardContext.Provider
              value={{
                isInsideGroupCard: true,
                groupCardId: !formOpen && !childCardFormOpen ? element.id : undefined,
              }}
            >
              <CardBoard
                droppableId={`${CONTAINER_CARD}-${element.id}`}
                moduleElements={uiElements}
              />
            </GroupCardContext.Provider>
            {/*</ModuleElementContext.Provider>*/}
          </DraggableContext.Provider>
        )}
        <div className='h-18 mt-5 flex justify-center bg-gray-100 pb-4 pt-3'>
          <Menu as='div' className='relative'>
            <Menu.Button
              as={Button}
              className={twJoin(
                'flex h-10 !w-40 shrink items-center gap-1 whitespace-nowrap px-2 py-2.5',
                (formOpen || childCardFormOpen) && 'pointer-events-none'
              )}
            >
              <PlusIcon size={20} />
              Add Group Item
            </Menu.Button>
            <Menu.Items className='absolute top-10 z-10 mt-1 w-52 rounded bg-white py-1 shadow-04'>
              {Object.keys(labelToCardType).map((label) => (
                <Menu.Item
                  key={label}
                  as='div'
                  className='px-3 py-2.5 hover:bg-primary-200'
                  onClick={() =>
                    addModuleElement({
                      parentId: element.id,
                      position: uiElements.length,
                      resourcetype: labelToCardType[label],
                    })
                  }
                >
                  {label}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Menu>
        </div>
      </CardContainer>

      <SlidePanel open={formOpen}>
        <GroupForm
          onSave={onSave}
          onCancel={onCancel}
          originalChildElements={element.ui_elements}
          childElements={uiElements}
          setChildElements={(elements) =>
            form.setValue('ui_elements', elements, { shouldDirty: true })
          }
        />
      </SlidePanel>
    </FormProvider>
  );
};

export const labelToCardType = {
  'Multiple Choice': ResourceTypes.multipleChoice,
  CheckBoxes: ResourceTypes.checkboxes,
  Numbers: ResourceTypes.numbers,
  'Text Input': ResourceTypes.textInput,
  'GPT Box': ResourceTypes.gptBox,
  Message: ResourceTypes.message,
  'AI Scribe': ResourceTypes.ambientListening,
  'Data Extractor': ResourceTypes.dataExtractor,
};

// Query to check the list of ui elements in the api separately from the ModuleBoard component when creating a child card
export function useGroupUiElements() {
  const { module } = useContext(ModuleContext);
  const moduleType = module?.type === MODULE_TYPES.ALGO ? 'modules' : 'calculators';
  const { data: uiElements, refetch: refetchUiElements } = useQuery({
    queryKey: [moduleType, module?.id, 'uiElements', 'group'],
    queryFn: moduleElementAPI.getUIElements,
    enabled: !!module?.id,
    initialData: [],
    staleTime: Infinity,
  });

  const { data: containers, refetch: refetchContainers } = useQuery({
    queryKey: [moduleType, module?.id, 'containers'],
    queryFn: moduleElementAPI.getContainers,
    enabled: !!module?.id,
    initialData: [],
    staleTime: Infinity,
    select: (containers): UIElement[] =>
      containers.map((container) => ({
        ...container,
        resourcetype: ResourceTypes.group,
      })),
  });

  const groupUiElements = [...uiElements, ...containers].flatMap((element) => [
    element,
    ...(element.ui_elements || []),
  ]);

  const refetchGroupUiElements = async () => {
    const promises = await Promise.allSettled([refetchUiElements(), refetchContainers()]);

    return promises.flatMap((result) =>
      result.status === 'fulfilled'
        ? result.value.data!.flatMap((element) => [element, ...(element.ui_elements || [])])
        : []
    );
  };

  return { groupUiElements, refetchGroupUiElements };
}

export function useGroupOrderingMutation() {
  const { module } = useContext(ModuleContext);
  const moduleType = module?.type === MODULE_TYPES.ALGO ? 'modules' : 'calculators';
  const { mutateAsync: saveChildCardsPosition } = useMutation({
    mutationFn: (uiElements: UIElement[]) => {
      const positions: Positions = uiElements.reduce((acc, obj, index) => {
        acc[obj.id] = index;
        return acc;
      }, {});

      return moduleElementAPI.saveUIElementPosition({
        moduleType: moduleType,
        moduleId: module!.id + '',
        positions,
      });
    },
  });

  return saveChildCardsPosition;
}
