import { ModuleWithMirror } from '../../hooks/useModules';
import { Icons } from '../utils/Icons';
import { ModulePopover } from './ModulePopover';
import { format } from 'date-fns';
import { H4 } from '../utils/typo';
import { ReactNode, useState } from 'react';

export function ModuleName({ module }: { module: ModuleWithMirror }) {
  const { last_verified, reviewed_by, review_expire_at } = module;
  const [showVerifiedTooltip, setShowVerifiedTooltip] = useState(false);

  return (
    <div className='flex-no-wrap flex flex-row items-center'>
      {last_verified && (
        <div>
          <Icons.CheckCircle
            className='h-8 w-8 pr-2'
            onMouseOver={() => {
              setShowVerifiedTooltip(true);
            }}
            onMouseLeave={() => setShowVerifiedTooltip(false)}
          />
          <ModulePopover
            open={showVerifiedTooltip}
            onClose={() => setShowVerifiedTooltip(false)}
            sectionInfoList={[
              {
                head: 'Verified',
                description: format(new Date(last_verified), 'MMM d, y, HH:mm a'),
                owner: reviewed_by?.name ? `by ${reviewed_by.name}` : '',
              },
              ...(review_expire_at
                ? [
                    {
                      head: 'Expired date',
                      //TODO: add expired date
                      description: format(new Date(review_expire_at), 'MMM d, y'),
                    },
                  ]
                : []),
            ]}
          />
        </div>
      )}
      <H4 className='line-clamp-2 !text-black'>{module.title}</H4>
    </div>
  );
}
