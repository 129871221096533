import clsx from 'clsx';
import { InputHTMLAttributes, forwardRef } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {}

const Input = forwardRef<HTMLInputElement, InputProps>(({ className, ...rest }, ref) => {
  return (
    <input
      {...rest}
      ref={ref}
      className={clsx(
        className,
        'mb-0 h-auto', // because of material UI. need to remove later
        '!box-border w-full rounded border !border-gray-300 bg-white !px-[12px] !py-[10px] text-body-2 !outline-none focus:border-primary-500'
      )}
      onWheel={(e) => rest.type === 'number' && e.currentTarget.blur()}
    />
  );
});

export default Input;
