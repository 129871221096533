import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteIcon from '@material-ui/icons/Delete';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { onSortEnd } from 'components/utils/ListItemUtil';
import { AvoEditor } from 'components/utils/avoeditor/AvoEditor';
import { AvoEditorTextInput } from 'components/utils/avoeditor/AvoEditorTextInput';
import { ToolbarButton } from 'components/utils/draftJS/utils';
import { StyledInputWithButton2 } from 'components/utils/styled-components/FormStyle';
import { RichTextMenuButtonTypes, SuggestionTypes } from 'components/utils/tiptap/tiptapInterfaces';
import SlidingPane from 'react-sliding-pane';
import { Input } from 'reactstrap';
import styled from 'styled-components';
import { hasViewOnlyPermission } from 'utils/permissions';
import { MODULE_TYPES } from '../../../constants';
import {
  DEMOGRAPHICS_TYPE,
  LAB_DATA_TYPE,
  MEDICATION_TYPE,
  NOTE_TYPE,
  PROBLEM_LIST_TYPE,
  PROVIDER_INFO_TYPE,
  RADIOLOGY_REPORT_TYPE,
  VITAL_SIGN_TYPE,
} from '../../../hooks/useEHRVariables';
import { StyledButton } from '../board/outputStyles';
import ConditionBuilder from './ConditionBuilder';

const richTextButtonsShowList: RichTextMenuButtonTypes[] = [
  'textStyles',
  'bold',
  'italic',
  'highlight',
  'bulletList',
  'orderedList',
  'more',
];

const suggestionsToExcludeForCondition: SuggestionTypes[] = [
  'link',
  'reference',
  'knowledge_base',
  'infobox',
  'media',
  'ehr_order',
  'ehr_data',
  NOTE_TYPE,
  LAB_DATA_TYPE,
  VITAL_SIGN_TYPE,
  MEDICATION_TYPE,
  PROBLEM_LIST_TYPE,
  DEMOGRAPHICS_TYPE,
  RADIOLOGY_REPORT_TYPE,
  PROVIDER_INFO_TYPE,
];

const suggestionsToExclude: SuggestionTypes[] = [
  ...suggestionsToExcludeForCondition,
  // 'variable',
];

const Accordion = withStyles({
  root: {
    background: '#F4F6F9',
    boxSizing: 'border-box',
    borderRadius: '10px',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: '2px solid #DADADA',
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'white',
    marginBottom: -1,
    borderTopRightRadius: '12px',
    borderTopLeftRadius: '12px',
    borderBottomRightRadius: '12px',
    borderBottomLeftRadius: '12px',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
      borderBottom: '1px solid #D3D3D3',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const StyledInput = styled(Input)`
  background: #ffffff !important;
  border: 1px solid #cccccc !important;
  max-width: 462px;
  height: 45px !important;
  //box-sizing: border-box !important ;
  border-radius: 5px !important;
  text-indent: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  :focus {
    border: 1px solid #08a88e !important;
  }

  ::-webkit-input-placeholder {
    font-weight: normal;
  }

  ::-moz-placeholder {
    font-weight: normal;
  }

  ::placeholder {
    font-weight: normal;
  }

  &:disabled {
    background: #f4f4f4 !important;
    color: #161616;
    opacity: 0.4;
  }

  font-weight: bold;
`;

interface SortableItemProps {
  item: any;
  indexNo: number; // Specify the type explicitly
  handleDeleteClick: () => void;
  handleListChange: () => void;
  calcId: string;
  setCategoryCondition: () => void;
  clearCategoryCondition: () => void;
}

const SortableItem = SortableElement<SortableItemProps>(
  ({ item, indexNo, handleDeleteClick, handleListChange, calcId, setCategoryCondition }) => {
    const [expanded, setExpanded] = React.useState('panel1');
    const [conditionModal, setConditionModal] = React.useState(false);

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

    return (
      <React.Fragment>
        <Accordion
          square
          expanded={expanded === `panel${indexNo + 1}`}
          onChange={handleChange(`panel${indexNo + 1}`)}
          key={item.sortable_id}
        >
          <AccordionSummary
            aria-controls={`panel${indexNo + 1}d-content`}
            id={`panel${indexNo + 1}d-header`}
          >
            {expanded === `panel${indexNo + 1}` ? (
              <KeyboardArrowDownIcon style={{ color: '#A6A6A6' }} />
            ) : (
              <ChevronRightIcon style={{ color: '#A6A6A6' }} />
            )}
            <Typography id={`label${indexNo}`} style={{ marginLeft: '5px', color: '#191919' }}>
              {item.label}
            </Typography>

            {item.is_default === false && !hasViewOnlyPermission('calculator') && (
              <span style={{ position: 'absolute', right: '1em' }}>
                <DeleteIcon
                  style={{ color: 'red' }}
                  onClick={(e) => handleDeleteClick(item.sortable_id)}
                />
              </span>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={{ width: '549px' }}>
              <label
                style={{
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: '22px',
                  color: '#969696',
                }}
              >
                Label
              </label>
              <br />
              <StyledInput
                type='text'
                name={`answerInput${indexNo}`}
                key={item.sortable_id}
                value={item.label}
                disabled={hasViewOnlyPermission('calculator')}
                onChange={(e) => handleListChange(item.sortable_id, e.target.value, 'label')}
              />

              {item.is_default === false && (
                <React.Fragment>
                  <label
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 600,
                      fontSize: '16px',
                      lineHeight: '22px',
                      color: '#969696',
                    }}
                  >
                    Category Condition
                  </label>
                  <br />

                  {item.builder_condition_json ? (
                    <React.Fragment>
                      <StyledInputWithButton2
                        type='text'
                        name='readable_condition'
                        value={item.readableCondition}
                        disabled={true}
                      />
                      <StyledButton
                        disabled={hasViewOnlyPermission('calculator')}
                        onClick={() => setConditionModal((prevState) => !prevState)}
                      >
                        Edit
                      </StyledButton>
                    </React.Fragment>
                  ) : (
                    <>
                      <div style={{ display: 'flex' }}>
                        <AvoEditorTextInput
                          moduleId={calcId}
                          moduleType={MODULE_TYPES.CALCULATOR}
                          width={331}
                          conditionContent={item.prev_cond_content}
                          setCondition={(e) => setCategoryCondition(item.sortable_id, 'draftjs', e)}
                          isReadOnly={hasViewOnlyPermission('calculator')}
                          wrapperClassNames='flex-grow !max-h-[45px]'
                          placeholder='Type #'
                          onUpdate={(editor) =>
                            setCategoryCondition(item.sortable_id, 'tiptap', editor)
                          }
                          initialContent={item.condition_json_tiptap}
                          suggestionsToExclude={suggestionsToExcludeForCondition}
                        />
                        <StyledButton
                          disabled={hasViewOnlyPermission('calculator')}
                          onClick={() => setConditionModal((prevState) => !prevState)}
                        >
                          Build
                        </StyledButton>
                      </div>
                    </>
                  )}
                  <br />
                </React.Fragment>
              )}

              <label
                style={{
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: '22px',
                  color: '#969696',
                }}
              >
                Description (Optional)
              </label>
              <br />
              <AvoEditor
                moduleId={calcId}
                suggestions={[]}
                isNested={true}
                isReadOnly={hasViewOnlyPermission('calculator')}
                disableConditionalText={true}
                disableMentionPlugin={true}
                prevValue={item.prev_desc_content}
                setValue={(e) => handleListChange(item.sortable_id, e, 'draftjs')}
                richTextButtonShowList={[
                  ToolbarButton.TEXT_STYLE,
                  ToolbarButton.BOLD,
                  ToolbarButton.ITALIC,
                  ToolbarButton.HIGHLIGHT,
                  ToolbarButton.BULLETED_LIST,
                  ToolbarButton.NUMBERED_LIST,
                  ToolbarButton.PHONE_NUMBER,
                ]}
                wrapperClassNames='flex-grow'
                onUpdate={(editor) =>
                  handleListChange(item.sortable_id, editor.getJSON(), 'tiptap')
                }
                initialContent={item.description_json_tiptap}
                richTextButtonsShowListTiptap={richTextButtonsShowList}
                suggestionsToExclude={suggestionsToExclude}
              />
            </Typography>
          </AccordionDetails>
        </Accordion>

        <SlidingPane
          isOpen={conditionModal}
          onRequestClose={() => setConditionModal((prevState) => !prevState)}
          from='right'
          // hideHeader
          className='sliding-pan-modal'
          title={
            <div>
              Condition Builder
              <HighlightOffIcon
                className='backIcon'
                onClick={() => setConditionModal((prevState) => !prevState)}
              ></HighlightOffIcon>
            </div>
          }
          width='587px'
          closeIcon={
            <div>
              <ArrowBackIcon className='closeIcon' fontSize='large'></ArrowBackIcon>
            </div>
          }
        >
          <ConditionBuilder
            calculatorId={calcId}
            type={'calculator'}
            toggleModal={() => setConditionModal((prevState) => !prevState)}
            setCondition={(condition, conditionLogic, jsonTree) =>
              setCategoryCondition(item.sortable_id, 'builder', condition, conditionLogic, jsonTree)
            }
            condition={item.condition}
            conditionLogic={item.condition_logic}
            builderJsonTree={item.builder_condition_json}
          />
        </SlidingPane>
      </React.Fragment>
    );
  }
);

interface SortableListProps {
  items: any[];
  distance: any;
  handleDeleteClick: (id: string) => void;
  handleListChange: (id: string, json: any, type: string, jsonTree?: any) => void;
  setCategoryCondition: () => void;
  clearCategoryCondition: () => void;
  calcId: string;
  moduleId?: string; // TODO: not used?
  type?: string;
  //onSortEnd:()=>void;
}

const SortableList = SortableContainer<SortableListProps>(
  ({
    items,
    handleDeleteClick,
    handleListChange,
    calcId,
    clearCategoryCondition,
    setCategoryCondition,
  }) => {
    return (
      <div>
        {items.map((val, index) => (
          <SortableItem
            key={val.sortable_id}
            index={index}
            indexNo={index}
            item={val}
            calcId={calcId}
            disabled={hasViewOnlyPermission('calculator')}
            setCategoryCondition={setCategoryCondition}
            clearCategoryCondition={clearCategoryCondition}
            handleDeleteClick={handleDeleteClick}
            handleListChange={handleListChange}
          />
        ))}
      </div>
    );
  }
);

function ListItem(props) {
  return (
    <SortableList
      distance={1} // this means we needs to slightly move the element to start sorting, we need this to made edit/delete icons clickable
      items={props.list}
      handleDeleteClick={props.handleDeleteClick}
      handleListChange={props.handleListChange}
      calcId={props.calcId}
      clearCategoryCondition={props.clearCategoryCondition}
      setCategoryCondition={props.setCategoryCondition}
      onSortEnd={({ oldIndex, newIndex }) =>
        props.updateListItems(onSortEnd(props.list, oldIndex, newIndex))
      }
    />
  );
}

export default ListItem;
