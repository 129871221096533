import clsx from 'clsx';
import { Icons } from 'components/utils/Icons';

interface LoadingSpinnerProps {
  size?: 'small' | 'medium' | 'large';
}

const LoadingSpinner = ({ size = 'medium' }: LoadingSpinnerProps) => {
  return (
    <div
      className={clsx(
        'aspect-square',
        { 'h-4': size === 'small' },
        { 'h-8': size === 'medium' },
        { 'h-16': size === 'large' }
      )}
    >
      <Icons.LoadingSpinnerGreen />
    </div>
  );
};

export default LoadingSpinner;
