import { useMutation, useQuery } from '@tanstack/react-query';
import { dataExtractorAPI } from 'api/data-extractor';
import { choiceCardAPI } from 'api/panels';
import clsx from 'clsx';
import LoadingSpinner from 'components/loader/LoadingSpinner';
import { ChoiceCardFormVariable } from 'components/module/cards/ChoiceCard';
import SituationBuilder from 'components/resources/triggers/SituationBuilder';
import { AvoSwitch } from 'components/utils/AvoSwitch';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/utils/AvoTooltip';
import Button from 'components/utils/Button';
import Checkbox from 'components/utils/Checkbox';
import { ComboBox } from 'components/utils/ComboBox';
import DropdownSingle from 'components/utils/common/DropdownSingle';
import { ExampleNTools } from 'components/utils/example-n-tools/ExampleNTools';
import { ExampleNToolButtons } from 'components/utils/example-n-tools/ToolButtons';
import { useToolOptions } from 'components/utils/example-n-tools/useToolOptions';
import { ExpandableList } from 'components/utils/form-input/ExpandableList';
import { Icons } from 'components/utils/Icons';
import Input from 'components/utils/Input';
import Label from 'components/utils/Label';
import { ModuleContext } from 'components/utils/module/ModuleContext';
import { AvoSlideCloseModal } from 'components/utils/panels/AvoSlideCloseModal';
import { SlidePanel } from 'components/utils/panels/AvoSlidePanel';
import RequiredMark from 'components/utils/requiredMark';
import { Skeleton } from 'components/utils/Skeleton';
import { Body2, Caption1, Caption2, H4 } from 'components/utils/typo';
import VariableAutoGenerator from 'components/utils/variable-generator';
import { useTriggers } from 'hooks/module/resources/useTriggers';
import { ArrowRightLeft, Info, X } from 'lucide-react';
import { useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { getVariableName } from 'utils/gptUtils';
import { isOnboardingModule } from 'utils/utilityFunctions';

interface ChoiceFormProps {
  choiceId: number;
  onSave: () => void;
  onCancel: () => void;
}

export const ChoiceForm = ({ choiceId, onSave, onCancel }: ChoiceFormProps) => {
  const { module } = useContext(ModuleContext);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    setError,
    clearErrors,
    formState: { isDirty, errors },
  } = useFormContext<ChoiceCardFormVariable>();
  const [triggerFormOpen, setTriggerFormOpen] = useState(false);
  const [closeModalOpen, setCloseModalOpen] = useState(false);
  const [triggerId, setTriggerId] = useState<number | undefined>();
  const { data: triggerState } = useTriggers();
  const triggers = [...triggerState.triggers, ...triggerState.candidate_triggers];
  const triggerOptions = triggers.map((trigger) => ({
    value: trigger.id,
    label: trigger.title,
  }));
  const triggerComponentProps = {
    toggleModal: () => setTriggerFormOpen(false),
    isFullHeight: true,
    selectTrigger: (value) => setValue('trigger', value, { shouldDirty: true }),
    createModal: true,
    triggerId: triggerId,
    // modal: triggerModalOpen,
    savePosition: () => {},
    // toogleModalPane: () => setTriggerModalOpen(false),
  };

  const { infoboxOptions } = useToolOptions();

  const { data: dynamicLists = [], isLoading: isLoadingDynamicLists } = useQuery({
    queryKey: [module!.id, 'dynamic-list'],
    queryFn: () => dataExtractorAPI.getDataExtractorGeneratedVariables(module!.id, ['list']),
    enabled: !!module?.id,
  });

  const { mutateAsync } = useMutation({
    mutationFn: !choiceId
      ? choiceCardAPI.postChoiceCard
      : (data: ChoiceCardFormVariable) => choiceCardAPI.putChoiceCard(data, choiceId),
  });

  const onSubmit = async (data: ChoiceCardFormVariable) => {
    if (isLoading) return;
    if (
      !data['is_choice_dynamic'] &&
      data['choices'].some((choice) => isNaN(choice.coefficient) || !choice.name)
    ) {
      setError('choices', { type: 'required', message: 'Please fill out all the Answers fields.' });
      return;
    } else if (data['is_choice_dynamic'] && !data['dynamic_choice_unique_code']) {
      setError('choices', { type: 'required', message: 'Please select dynamic choice variable.' });
      return;
    }

    setIsLoading(true);
    if (!data.variable) {
      data.variable = await getVariableName(data.title);
    }
    data['question_items'] = [...data['examples'], ...data['tools']];
    await mutateAsync(data);
    reset(data);
    onSave();
    setIsLoading(false);
  };

  return (
    <>
      <form className='flex h-full flex-col' onSubmit={handleSubmit(onSubmit)}>
        <div className='grow overflow-y-auto'>
          <div className='sticky top-0 z-10 bg-white pl-4 pr-3 pt-[20px]'>
            <div className='flex items-center justify-between'>
              <H4>{watch('is_multi_selectable') ? 'Checkboxes' : 'Multiple Choice'}</H4>
              <div
                className='cursor-pointer'
                onClick={() => (isDirty ? setCloseModalOpen(true) : onCancel())}
              >
                <X size={20} />
              </div>
            </div>
            {!choiceId && (
              <div className='flex py-2'>
                <div
                  className='flex cursor-pointer items-center gap-0.5 !rounded-full border border-gray-300 px-2.5 py-1 text-primary-500'
                  onClick={() =>
                    setValue('is_multi_selectable', !watch('is_multi_selectable'), {
                      shouldDirty: true,
                    })
                  }
                >
                  <ArrowRightLeft size={16} />
                  <div className='text-button-1'>
                    Convert to {watch('is_multi_selectable') ? 'Multiple Choice' : 'Checkboxes'}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='mt-4 px-4'>
            <div className='space-y-1'>
              <div className='flex gap-1'>
                <Body2 className='text-gray-900'>Trigger</Body2>
                <RequiredMark />
              </div>
              <ComboBox
                onChange={(value: any) => setValue('trigger', value, { shouldDirty: true })}
                options={triggerOptions}
                selectedValue={watch('trigger')}
                onCreate={() => {
                  setTriggerId(undefined);
                  setTriggerFormOpen(true);
                }}
                onEdit={(id) => {
                  setTriggerId(+id);
                  setTriggerFormOpen(true);
                }}
                createButtonLabel='Create New Trigger'
              />
            </div>
            <div className='mt-6 space-y-1'>
              <div className='flex gap-1'>
                <Body2 className='text-gray-900'>Question</Body2>
                <RequiredMark />
              </div>
              <Input
                {...register('title', {
                  required: { value: true, message: 'Please fill out this field.' },
                  maxLength: 200,
                })}
                maxLength={200}
                className={clsx(
                  errors.title &&
                    'border-red-500 ring-1 !ring-red-500' /* TODO: Need to remove ring after remove global style*/
                )}
              />
              {errors.title && <span className='text-error'>{errors.title.message}</span>}
            </div>
            <div className='mt-6 space-y-1'>
              <div className='flex gap-1'>
                <Body2 className='text-gray-900'>Subtitle</Body2>
              </div>
              <Input {...register('subtitle', { maxLength: 200 })} maxLength={200} />
            </div>
            <div className='mt-6 space-y-1'>
              <div className='flex gap-1'>
                <Body2 className='text-gray-900'>Examples</Body2>
              </div>
            </div>
            <div className='mt-2'>
              <ExampleNToolButtons
                section='E'
                list={watch('examples')}
                buttonsToShow={['text', 'infobox']}
                onChange={(value) => setValue('examples', value, { shouldDirty: true })}
              />
            </div>
            {watch('examples').length > 0 && (
              <div className='mt-2'>
                <ExampleNTools
                  list={watch('examples')}
                  onChange={(value) => setValue('examples', value, { shouldDirty: true })}
                />
              </div>
            )}
            <div
              className={clsx(
                'mt-6 space-y-1',
                errors.choices &&
                  'rounded border border-red-500 ring-1 !ring-red-500' /* TODO: Need to remove ring after remove global style*/
              )}
            >
              <div className='flex items-center gap-1'>
                <div className='flex gap-1'>
                  <Body2 className='text-gray-900'>Answers</Body2>
                  <RequiredMark />
                </div>
                <Tooltip placement='bottom-start'>
                  <TooltipTrigger>
                    <Info className='text-gray-300' size={16} />
                  </TooltipTrigger>
                  <TooltipContent>
                    <div className='w-[280px] rounded bg-gray-800 px-3 py-2 text-white'>
                      <ul className='mb-0 ml-3 list-outside'>
                        <li className='list-disc'>
                          <Caption1 className='inline'>Manual</Caption1>
                          <Caption2 className='inline'>
                            : Manually define each answer option.
                          </Caption2>
                        </li>
                        <li className='list-disc'>
                          <Caption1 className='inline'>Auto</Caption1>
                          <Caption2 className='inline'>
                            : Automatically generate answer options using a list from the AI data
                            extractor, like clinical recommendations.
                          </Caption2>
                        </li>
                      </ul>
                    </div>
                  </TooltipContent>
                </Tooltip>
              </div>
              <div className='mb-2 flex cursor-pointer overflow-hidden text-center'>
                <div
                  onClick={() => {
                    setValue('is_choice_dynamic', false, { shouldDirty: true });
                    clearErrors('choices');
                  }}
                  className={clsx(
                    'flex-1 rounded-bl rounded-tl border py-2',
                    !watch('is_choice_dynamic')
                      ? 'border-gray-300 bg-white text-button-1 text-primary-600'
                      : 'border-r-0 border-gray-200 bg-gray-100 text-button-2 text-gray-700'
                  )}
                >
                  Manual
                </div>
                <div
                  onClick={() => {
                    setValue('is_choice_dynamic', true, { shouldDirty: true });
                    clearErrors('choices');
                  }}
                  className={clsx(
                    'flex-1 rounded-br rounded-tr border py-2',
                    watch('is_choice_dynamic')
                      ? '!border-gray-300 bg-white text-button-1 text-primary-600'
                      : 'border-l-0 border-gray-200 bg-gray-100 text-button-2 text-gray-700'
                  )}
                >
                  Auto
                </div>
              </div>
              {watch('is_choice_dynamic') ? (
                isLoadingDynamicLists ? (
                  <Skeleton />
                ) : (
                  <DropdownSingle
                    value={watch('dynamic_choice_unique_code')}
                    options={dynamicLists.map((variable) => ({
                      id: variable.unique_code,
                      title: variable.name,
                    }))}
                    onChange={(value) => {
                      setValue('dynamic_choice_unique_code', value + '', { shouldDirty: true });
                      clearErrors('choices');
                    }}
                    placeholder='Select'
                    emptyDescription='Generate an array-type variable using the Data Extractor to display selectable lists.'
                  />
                )
              ) : (
                <ExpandableList
                  onAdd={() =>
                    setValue(
                      'choices',
                      watch('choices').concat([
                        {
                          name: 'Option ' + (watch('choices').length + 1),
                          coefficient: 1,
                          infobox: null,
                          position: watch('choices').length,
                        },
                      ]),
                      { shouldDirty: true }
                    )
                  }
                  setValue={(value) =>
                    setValue(
                      'choices',
                      value.map((item, i) => ({ ...item, position: i })),
                      { shouldDirty: true }
                    )
                  }
                  addItemLabel='Add Option'
                  items={watch('choices')}
                >
                  <ExpandableList.Items>
                    {watch('choices').map((item, index) => (
                      <ExpandableList.Item index={index}>
                        <ExpandableList.Button>
                          {(open) => (
                            <div className='flex w-full items-center space-x-[8px] px-[16px] py-[14px] focus:bg-transparent'>
                              <Icons.ChevronDown className={twMerge(open && 'rotate-180')} />
                              <input
                                required
                                className='grow border-b border-gray-300 text-body-1 focus:outline-0 disabled:bg-transparent'
                                value={watch('choices')[index].name}
                                onChange={(e) => {
                                  setValue(
                                    'choices',
                                    watch('choices').map((item, itemIndex) =>
                                      itemIndex === index ? { ...item, name: e.target.value } : item
                                    ),
                                    { shouldDirty: true }
                                  );
                                  clearErrors('choices');
                                }}
                                onClick={(e) => e.stopPropagation()}
                              />
                              <Icons.TrashBin
                                className='cursor-pointer fill-gray-600 hover:fill-primary-600'
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setValue(
                                    'choices',
                                    watch('choices').filter((_, itemIndex) => itemIndex !== index),
                                    { shouldDirty: true }
                                  );
                                }}
                              />
                            </div>
                          )}
                        </ExpandableList.Button>
                        <ExpandableList.Panel>
                          <div className='space-y-3'>
                            <Input
                              required
                              type='number'
                              placeholder='Assigned Value'
                              value={item.coefficient ?? index}
                              onChange={(e) => {
                                setValue(
                                  'choices',
                                  watch('choices').map((choice, i) =>
                                    i === index
                                      ? { ...choice, coefficient: e.target.value }
                                      : choice
                                  ),
                                  { shouldDirty: true }
                                );
                                clearErrors('choices');
                              }}
                            />
                            {watch('has_infobox_in_options') && (
                              <DropdownSingle
                                placeholder='Select Infobox'
                                value={watch('choices')[index].infobox || ''}
                                onChange={(infoboxId) => {
                                  setValue(
                                    'choices',
                                    watch('choices').map((choice, i) =>
                                      i === index ? { ...choice, infobox: infoboxId } : choice
                                    ),
                                    { shouldDirty: true }
                                  );
                                }}
                                options={infoboxOptions.map((option) => ({
                                  ...option,
                                  title: option.label,
                                }))}
                              />
                            )}
                            <Label className='flex items-center gap-1'>
                              <Checkbox
                                checked={watch('choices')[index].is_selected || false}
                                onChange={(e) =>
                                  setValue(
                                    'choices',
                                    watch('choices').map((choice, i) =>
                                      i === index
                                        ? { ...choice, is_selected: e.target.checked }
                                        : choice
                                    ),
                                    { shouldDirty: true }
                                  )
                                }
                              />
                              <Body2 className='text-gray-900'>Selected by Default</Body2>
                            </Label>
                          </div>
                        </ExpandableList.Panel>
                      </ExpandableList.Item>
                    ))}
                  </ExpandableList.Items>
                </ExpandableList>
              )}
            </div>
            {errors.choices && <span className='text-red-500'>{errors.choices.message}</span>}
            {!watch('is_choice_dynamic') && (
              <div className='mt-6 space-y-3'>
                <Label className='flex items-center justify-between'>
                  <Body2 className='text-gray-900'>Add infobox to answer options</Body2>
                  <AvoSwitch
                    checked={watch('has_infobox_in_options')}
                    onChange={(value) =>
                      setValue('has_infobox_in_options', value, { shouldDirty: true })
                    }
                  />
                </Label>
                {isOnboardingModule(module?.code) && (
                  <Label className='flex items-center justify-between'>
                    <Body2 className='text-gray-900'>Is Dropdown Menu</Body2>
                    <AvoSwitch
                      checked={watch('is_dropdown_item')}
                      onChange={(value) =>
                        setValue('is_dropdown_item', value, { shouldDirty: true })
                      }
                    />
                  </Label>
                )}
              </div>
            )}
            {watch('is_multi_selectable') && (
              <div className='mt-6'>
                <Label className='flex items-center gap-1'>
                  <Checkbox
                    checked={watch('is_submittable')}
                    onChange={(e) =>
                      setValue('is_submittable', e.currentTarget.checked, { shouldDirty: true })
                    }
                  />
                  <Body2 className='text-gray-900'>Show Submit Button</Body2>
                </Label>
              </div>
            )}
            <div className='mt-6 space-y-1'>
              <Body2 className='text-gray-900'>Tools</Body2>
              <div className='mt-2'>
                <ExampleNToolButtons
                  section='T'
                  list={watch('tools')}
                  buttonsToShow={['infobox', 'calculator']}
                  onChange={(value) => setValue('tools', value, { shouldDirty: true })}
                />
              </div>
              {watch('tools').length > 0 && (
                <div className='mt-2'>
                  <ExampleNTools
                    list={watch('tools')}
                    onChange={(value) => setValue('tools', value, { shouldDirty: true })}
                  />
                </div>
              )}
            </div>
            <div className='my-6'>
              <VariableAutoGenerator
                question={watch('title')}
                tagTitle={watch('is_multi_selectable') ? 'checkbox' : 'choice'}
                variable={watch('variable')}
                setVariableName={(variable) =>
                  setValue('variable', variable, { shouldDirty: true })
                }
              />
            </div>
          </div>
        </div>
        <div className='shrink-0'>
          <div className='flex justify-end gap-2 px-[12px] pb-[22px] pt-[12px]'>
            <div className='flex'>
              <Button.Reverse
                type='button'
                onClick={() => (isDirty ? setCloseModalOpen(true) : onCancel())}
                disabled={isLoading}
              >
                Cancel
              </Button.Reverse>
            </div>
            <div className='flex w-[80px]'>
              <Button disabled={isLoading || !isDirty}>
                {isLoading ? <LoadingSpinner /> : 'Save'}
              </Button>
            </div>
          </div>
        </div>
      </form>
      <AvoSlideCloseModal
        open={closeModalOpen}
        onContinue={() => setCloseModalOpen(false)}
        onDiscard={onCancel}
      />
      <SlidePanel open={triggerFormOpen} onClose={() => setTriggerFormOpen(false)}>
        <div className='mx-4 mt-3'>
          <SituationBuilder {...triggerComponentProps} />
        </div>
      </SlidePanel>
    </>
  );
};
