import { useMutation, useQuery } from '@tanstack/react-query';
import { Editor } from '@tiptap/react';
import { GET_KNOWLEDGE_BASE } from 'actions/actionsTypes';
import { getKnowledgeBase, setKnowledgeMirrorState } from 'actions/knowledge-base/getKnowledgeBase';
import { knowledgebaseAPI } from 'api/knowledgebase';
import { ModuleHeader } from 'components/bars/ModuleHeader';
import RequiredMark from 'components/utils/requiredMark';
import { Tiptap } from 'components/utils/tiptap/Tiptap';

import LoadingSpinner from 'components/loader/LoadingSpinner';
import { ModuleProvider } from 'components/utils/module/ModuleContext';
import {
  KnowledgeBaseSavePayload,
  RichTextMenuButtonTypes,
  TableActionTypes,
} from 'components/utils/tiptap/tiptapInterfaces';
import { CustomToast } from 'components/utils/toast-message';
import { Body1, Body2, H3 } from 'components/utils/typo';
import { useModules } from 'hooks/useModules';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { hasViewOnlyPermission } from 'utils/permissions';
import { ERR_MSG_MODULE_NOT_FOUND_REDIRECT_TO_MAIN, MODULE_TYPES } from '../../../constants';
import { formattedDate } from './AiKnowledgeBaseUtils';
import { ParsingModal } from './ParsingModal';
import { UploadPDF } from './UploadPDF';

const tableActionButtonsShowList: TableActionTypes[] = [
  'AddColumnBefore',
  'AddColumnAfter',
  'DeleteColumn',
  'AddRowBefore',
  'AddRowAfter',
  'DeleteRow',
  'DeleteTable',
  'SplitCell',
  'ToggleHeaderColumn',
  'ToggleHeaderRow',
  'ToggleHeaderCell',
];

const richTextButtonsShowList: RichTextMenuButtonTypes[] = [
  'textStyles',
  'bold',
  'italic',
  'highlight',
  'bulletList',
  'orderedList',
  'table',
];
interface RouteStateType {
  isMirror?: boolean;
  mirrorId?: string;
  mirrorName?: string;
}
const AiKnowledgeBaseUi = () => {
  const REFETCH_MILISECONDS = 10000;
  const { verifyModule } = useModules();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [content, setContent] = useState('');
  const [showEditor, setShowEditor] = useState(false);
  const [isPdfParsing, setIsPdfParsing] = useState(false);
  const { state } = useLocation();
  const locationState: RouteStateType = state || {};

  const { moduleId } = useParams<{ moduleId: string }>();
  const { isMirror = false, mirrorId = null } = locationState;
  const toggleReleaseModal = () => setReleaseModal((prev) => !prev);
  const { data: knowledgeBase, refetch } = useQuery({
    queryKey: ['knowledgeBase', moduleId, 'content'],
    queryFn: knowledgebaseAPI.getContent,
    refetchInterval: (query) => {
      const data = query.state.data;
      return !!data?.parsing_started || !!data?.parsing_queued ? REFETCH_MILISECONDS : false;
    },
  });
  const [releaseModal, setReleaseModal] = useState(false);
  const [lastUpdated, setLastUpdated] = useState('');
  const [lastSynced, setLastSynced] = useState('');
  const isViewOnly = hasViewOnlyPermission(MODULE_TYPES.KNOWLEDGE_BASE);

  useEffect(() => {
    if (!knowledgeBase) return;
    setLastUpdated(knowledgeBase.last_updated);
    setLastSynced(knowledgeBase.last_synced);
    setShowEditor(!!knowledgeBase.markdown);
    /**
     * <p></p> means empty content.
     * if empty, check markdown first because pdf parsing updates markdown only.
     * if not empty, apply text_json first, that will not crash the formatting.
     */
    if (knowledgeBase.text_json === '<p></p>') {
      setContent(knowledgeBase.markdown || knowledgeBase.text_json);
    } else {
      setContent(knowledgeBase.text_json || knowledgeBase.markdown || '');
    }
  }, [knowledgeBase]);

  // TODO: Need refactoring with react context
  useEffect(() => {
    const asyncFunction = async () => {
      dispatch(setKnowledgeMirrorState(locationState));

      try {
        dispatch(getKnowledgeBase(moduleId as any, mirrorId));
      } catch (e) {
        console.log(e);
        toast.error(CustomToast, { data: ERR_MSG_MODULE_NOT_FOUND_REDIRECT_TO_MAIN });
        navigate('/', { replace: true });
        return;
      }
    };

    asyncFunction();

    return () => {
      dispatch({
        type: GET_KNOWLEDGE_BASE,
        payload: {},
      });
      dispatch(setKnowledgeMirrorState({}));
    };
  }, []);

  const { mutate: save } = useMutation({
    mutationFn: (data: KnowledgeBaseSavePayload) =>
      knowledgebaseAPI.saveContent(parseInt(moduleId as any), data),
  });

  const { mutate: cancelJob, isPending: isCanceling } = useMutation({
    mutationFn: knowledgebaseAPI.cancelPdfParsingJob,
    onSuccess: refetch,
  });

  const onEditorUpdate = useCallback(
    debounce((editor: Editor) => {
      const html = editor.getHTML();
      const markdown = editor.storage.markdown.getMarkdown();
      save(
        { text_json: html, markdown },
        {
          onSuccess: (data) => {
            setLastSynced(data.last_synced);
            setLastUpdated(data.last_updated);
            if (!editor.isFocused || editor.isEmpty) {
              setContent(data.text_json);
            }
          },
        }
      );
    }, 500),
    [moduleId]
  );

  const onBlur = ({ editor, event }: { editor: Editor; event: FocusEvent }) => {
    setShowEditor(event.relatedTarget instanceof HTMLButtonElement || !editor.isEmpty);
  };

  return (
    <div className='flex h-0 min-h-screen flex-col'>
      <ModuleHeader />
      {!knowledgeBase ? (
        <div className='flex h-full items-center justify-center'>
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {(isPdfParsing || !!knowledgeBase.parsing_started || !!knowledgeBase.parsing_queued) && (
            <ParsingModal
              expectedEnd={
                knowledgeBase.parsing_pdf_file_size
                  ? (knowledgeBase.parsing_pdf_file_size / 200000) * 60
                  : 0
              }
              started={knowledgeBase.parsing_started}
              onCancel={() => !isCanceling && cancelJob(parseInt(moduleId as any))}
            />
          )}
          <div className='mt-20 flex flex-grow flex-col px-12'>
            <Body1 className='mb-1 flex gap-1 text-gray-900'>
              Content
              <RequiredMark />
            </Body1>
            {!showEditor && false ? (
              <div className='flex flex-grow flex-col gap-2.5 rounded border border-solid border-gray-300 px-3 py-2.5'>
                <div
                  onClick={() => setShowEditor(true)}
                  className='flex flex-grow cursor-pointer flex-col items-start rounded-lg'
                >
                  <Body2 className='text-gray-300'> Write here</Body2>
                  <H3 className='m-auto text-center text-gray-700'>Start Typing</H3>
                </div>
                <UploadPDF
                  onUploading={() => setIsPdfParsing(true)}
                  onUploaded={async () => {
                    await refetch();
                    setIsPdfParsing(false);
                  }}
                />
              </div>
            ) : (
              <div className='flex flex-grow flex-col'>
                <Tiptap
                  autofocus
                  minimalEditor
                  onBlur={onBlur}
                  disabled={isMirror || isViewOnly}
                  onUpdate={onEditorUpdate}
                  initialContent={content}
                  wrapperClassNames='flex-grow h-56'
                  richTextButtonsShowList={richTextButtonsShowList}
                  tableActionButtonsShowList={tableActionButtonsShowList}
                  placeholder='Type or paste your source of knowledge.'
                />
              </div>
            )}
            <div className='mb-3 mt-3'>
              <Body2 className='text-gray-700'>
                Last Saved:{' '}
                <span className='text-button-1'>{lastUpdated && formattedDate(lastUpdated)}</span>
              </Body2>
              <Body2 className='text-gray-700'>
                Last Published:{' '}
                <span className='text-button-1'>{lastSynced && formattedDate(lastSynced)}</span>
              </Body2>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
const AiKnowledgeBase = () => {
  return (
    <ModuleProvider>
      <AiKnowledgeBaseUi />
    </ModuleProvider>
  );
};

export default AiKnowledgeBase;
