//import { Modal, ModalFooter, modalBody= } from 'reactstrap';
import { Backdrop, Modal, Paper, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { ThemeProvider, createTheme, withStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import AvoMdLogo from 'assets/images/logo.png';
import successImage from 'assets/images/success.png';
import axios from 'axios';
import { CustomToast } from 'components/utils/toast-message';
import { SEND_PUSH_NOTIFICATIONS_URL } from 'constants';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import styled from 'styled-components';
import { withTeam } from '../../../hooks/useTeam';
import '../modals/modal.css';

const theme = createTheme({
  overrides: {
    MuiStepIcon: {
      root: {
        color: '#f5f5f5',
        border: '2px solid #D0D0D0',
        borderRadius: '100%',
        '&$completed': {
          color: '#D2D2D2',
          borderRadius: '100%',
          border: 'none !important',
        },
        '&$active': {
          color: '#f5f5f5',
          border: '2px solid #707070 !important',
          borderRadius: '100%',
        },
      },
      text: {
        display: 'none',
      },
    },
    MuiStep: {
      horizontal: {
        paddingLeft: '20px',
        paddingRight: '20px',
        '@media (max-width:1024px)': {
          paddingLeft: '0px',
          paddingRight: '0px',
        },
      },
    },
  },
});

const SuccessBox = styled(Box)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const SyncSuccessText = withStyles((theme) => ({
  root: {
    fontStyle: 'normal',
    fontWeight: '500',
    marginTop: '30px',
    textAlign: 'center',
    color: '#000000',

    [theme.breakpoints.only('xl')]: {
      fontSize: '32px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '27px',
    },
  },
}))(Typography);
const NextButton = withStyles((theme) => ({
  root: {
    fontSize: '26px !important',
    boxShadow: '0px 20px 30px rgba(0, 0, 0, 0.15) !important',
    WebkitBoxShadow: '0px 20px 30px rgba(0, 0, 0, 0.15) !important',
    MozBoxShadow: '0px 20px 30px rgba(0, 0, 0, 0.15) !important',
    textTransform: 'none',
    backgroundColor: '#08A88E !important',
    color: 'white !important',
    height: '80px',
    width: '232px',
    position: 'absolute',
    right: '0',
    marginRight: '30px',
    borderRadius: 100,
  },
  disabled: {
    opacity: '0.3 !important',
    color: 'white !important',
  },
}))(Button);

const SyncCaption = withStyles((theme) => ({
  root: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '25px',
    marginTop: '4px',
    textAlign: 'center',
    color: '#00000',
    [theme.breakpoints.only('xl')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
    },
  },
}))(Typography);

const NotificationWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TitleInput = styled(Input)`
  background: #ffffff !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 5px !important;
  height: 3rem !important;
  width: 52vw !important;
  font-size: 14px;
  text-indent: 10px;
  ::placeholder {
    text-align: left;
    color: #969696;
  }
`;
const NotificationInput = styled(Input)`
  background: #ffffff !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 5px !important;
  height: 16vh !important;
  width: 52vw !important;
  font-size: 14px;
  text-indent: 10px;
  padding-bottom: 105px;
  ::placeholder {
    text-align: left;
    color: #969696;
  }
  @media (max-height: 826px) {
    height: 13vh !important;
  }
`;
const MessageDisplay = styled(Box)`
  background: #ffffff !important;
  height: 20vh !important;
  width: 50vw !important;
`;
const NotificationLabel = styled(Label)`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  /* identical to box height */

  color: #000000;
`;
const MessageConfirmation = styled(Label)`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  margin: 0px;
  /* identical to box height */

  color: #000000;
`;

const CloseIcon = styled(Box)`
  position: absolute;
  right: -8px;
  top: 0;
  cursor: pointer;
  color: #c7c7c7;
  padding-top: 18px;
`;
const BackIcon = styled(Box)`
  position: absolute;
  left: 2;
  top: 22px;
  cursor: pointer;
  color: #08a88e;
`;

const StepperContainer = styled.div`
  width: 60%;
  padding-left: 20px;
  @media (max-width: 890px) {
    width: 52%;
    padding-left: 0px;
  }
`;

const StyledStepper = withStyles({
  root: {
    backgroundColor: 'transparent',
  },
})(Stepper);
const StyledStep = withStyles({
  completed: {
    //ansparent",
  },
})(Step);
const StyledStepConnector = withStyles({
  lineHorizontal: {
    borderTopStyle: 'dotted',
    borderTopWidth: '2px',
    borderLeft: '5px',
  },
})(StepConnector);
const StyledStepLabel = withStyles({
  root: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '25px',
    color: '#9E9E9E',
    paddingTop: '4px',
  },
  completed: {
    color: '#9E9E9E !important',
  },
  active: {
    color: '#707070 !important',
    fontWeight: '700 !important',
  },
  label: {
    fontSize: '17px',
    '@media (max-width:1130px)': {
      fontSize: '11px !important',
    },
    '@media (max-height:830px)': {
      fontSize: '13px',
    },
  },
})(StepLabel);

class PushNotificationModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    message: '',
    title: '',
    noErrors: false,
    startSync: false,
    activeStep: 1,
    channelSelect: [],
    isLoading: false,

    disableNavigation: false,
    steps: ['Select Channels', 'Edit Message', 'Confirm & Send'],

    /* state props */
  });

  onChange = (e) => this.setState({ message: e.target.value });
  onChangeTitle = (e) => this.setState({ title: e.target.value });

  handleChange = (event) => {
    this.setState({ activeStep: event.target.value });
  };
  handleChannelSelect = (channels) => {
    let channelsArray = channels;
    this.setState({ channelSelect: channelsArray });
  };
  stopSync = () => {
    this.setState({ startSync: false });
  };

  triggerSuccess = () => {
    this.setState({ noErrors: 'true' });
    this.setState({ activeStep: 2 });
  };
  handleForwardModalNavigation = () => {
    if (this.state.activeStep < 3 && this.state.activeStep >= 1) {
      this.setState({ activeStep: this.state.activeStep + 1 });
    }
  };
  sendPushNotification = () => {
    this.setState({ isLoading: true });
    axios
      .post(SEND_PUSH_NOTIFICATIONS_URL, {
        message: this.state.message,
        channels: this.state.channelSelect,
        title: this.state.title,
        team: this.props.team.id,
      })
      .then((res) => {
        this.setState({ activeStep: 4, isLoading: false });
      })
      .catch(() => {
        toast.error(CustomToast, { data: 'Unsuccessful!' });
        this.props.toggleModal();
      });
  };
  handleBackwardModalNavigation = () => {
    if (this.state.activeStep <= 3 && this.state.activeStep > 1) {
      this.setState({ activeStep: this.state.activeStep - 1 });
    }
  };

  closeModal = async () => {
    this.props.toggleModal();
    setTimeout(() => {
      this.setState(this.getInitialState());
    }, 1000);
  };

  render() {
    return (
      <Fragment>
        <Modal
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
            className: 'light-overlay',
          }}
          closeAfterTransition
          open={this.props.modal}
          // onClosed={() =>
          //   this.props.onClose
          //     ? this.props.onClose()
          //     : null
          //     ? this.props.onClose()
          //     : null
          // }
          // onExit={() => this.props.onClose}
          // toggle={this.props.toggleModal}
          // contentClassName='custom-modal-content-sync'
        >
          <Fade in={this.props.modal}>
            <Paper
              style={{
                padding: '0',
                borderRadius: '40px',
                margin: '0',
                //  display: 'flex',
                //  flexDirection: 'column',
                position: 'relative',
                width: '62%',
                height: '60%',
                paddingRight: '44px',
                paddingTop: '25px',
                //  paddingLeft: '40px'
                boxShadow: '0px 0px 60px rgba(0, 0, 0, 0.08)',
              }}
            >
              <div style={{ height: '80%' }}>
                <div
                  className='modal-body-sync'
                  style={{
                    zIndex: 1200,
                    backgroundColor: '#fff',
                    height: '100%',
                  }}
                >
                  {/* <modalBody= className='modal-body-sync'> */}
                  {(this.state.activeStep === 2 || this.state.activeStep === 3) && (
                    <BackIcon className='m-4' onClick={() => this.handleBackwardModalNavigation()}>
                      <KeyboardBackspaceIcon
                      //to handle state if there are no errors so we dont want to show sync warnings or allow to go there
                      />
                    </BackIcon>
                  )}
                  <CloseIcon
                    className='m-4 mr-5'
                    onClick={this.closeModal}
                    style={{ zIndex: '1200' }}
                  >
                    <HighlightOffIcon />
                  </CloseIcon>
                  {this.state.activeStep === 1 && this.state.noErrors === false && (
                    <Fade in={true}>
                      <Box className='ml-2 mt-2' style={{ height: '100%' }}></Box>
                    </Fade>
                  )}
                  {this.state.activeStep === 2 && (
                    <Fade in={true}>
                      <Box className='mr-3'>
                        <Box className='ml-3'>
                          <NotificationLabel
                            for='name'
                            style={{ margin: 'auto', marginTop: '1.1rem' }}
                            className='ml-5'
                          >
                            Notification Message
                          </NotificationLabel>
                        </Box>
                        <Box className='notification-body ml-3 mt-3'>
                          <NotificationWrapper>
                            <FormGroup>
                              <p
                                className='mt-3'
                                style={{
                                  fontSize: '18px',
                                  fontWeight: '400',
                                  color: '#4E4E4E',
                                }}
                              >
                                Notification Title
                              </p>
                              <TitleInput
                                className=' mb-2 mt-2'
                                autoSave='off'
                                autoComplete='off'
                                required
                                type='textArea'
                                name='title'
                                placeholder='Type title here'
                                onChange={this.onChangeTitle}
                              />
                              <p
                                className='mt-3'
                                style={{
                                  fontSize: '18px',
                                  fontWeight: '400',
                                  color: '#4E4E4E',
                                }}
                              >
                                Notification Message
                              </p>
                              <NotificationInput
                                className=' mb-2 mt-2'
                                autoSave='off'
                                autoComplete='off'
                                required
                                type='textArea'
                                name='message'
                                placeholder='Write your message'
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </NotificationWrapper>
                        </Box>
                      </Box>
                    </Fade>
                  )}

                  {this.state.activeStep === 3 && (
                    <Fade in={true}>
                      <Box>
                        <Box className='ml-3'>
                          <NotificationLabel
                            for='name'
                            style={{ margin: 'auto', marginTop: '1.1rem' }}
                            className='ml-5'
                          >
                            Notification Message
                          </NotificationLabel>
                        </Box>
                        <NotificationWrapper className='mr-4' style={{ marginTop: '64px' }}>
                          <MessageDisplay className='d-flex justify-content-left align-items-left '>
                            <img
                              src={AvoMdLogo}
                              alt='avomd-logo'
                              style={{ height: '28px', width: '28px' }}
                            />
                            <Box
                              className='mt-1'
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                paddingLeft: '9px',
                              }}
                            >
                              <MessageConfirmation style={{ fontWeight: 'bold' }}>
                                {this.state.title ? this.state.title : 'Title Was Not Entered'}
                              </MessageConfirmation>

                              <MessageConfirmation>
                                {this.state.message
                                  ? this.state.message
                                  : 'Message Was Not Entered'}
                              </MessageConfirmation>
                            </Box>
                          </MessageDisplay>
                        </NotificationWrapper>
                      </Box>
                    </Fade>
                    //   </Box>
                  )}

                  {this.state.activeStep === 4 && (
                    <Fade in={true}>
                      <SuccessBox>
                        <img
                          src={successImage}
                          alt='success'
                          style={{ width: '133px', height: '133px' }}
                        />
                        <SyncSuccessText>Awesome!</SyncSuccessText>
                        <SyncCaption>Notification sent successfully!</SyncCaption>
                      </SuccessBox>
                    </Fade>
                  )}
                </div>
                {/* </modalBody=> */}
                {/* <ModalFooter className='sync-footer'> */}
                {this.state.activeStep !== 4 && (
                  <div className='sync-footer'>
                    <ThemeProvider theme={theme}>
                      <StepperContainer>
                        <StyledStepper
                          activeStep={this.state.activeStep - 1}
                          connector={<StyledStepConnector />}
                        >
                          {this.state.steps.map((label) => (
                            <StyledStep key={label}>
                              <StyledStepLabel>{label}</StyledStepLabel>
                            </StyledStep>
                          ))}
                        </StyledStepper>
                      </StepperContainer>
                    </ThemeProvider>

                    <NextButton
                      disabled={
                        this.state.isLoading ||
                        (this.state.activeStep === 1 && this.state.channelSelect.length < 1) ||
                        (this.state.activeStep === 2 &&
                          (this.state.message === '' || this.state.title === ''))
                      }
                      variant='contained'
                      onClick={() => {
                        if (this.state.activeStep === 3) {
                          this.sendPushNotification();
                        } else {
                          this.handleForwardModalNavigation();
                        }
                      }}
                    >
                      {this.state.isLoading
                        ? 'Sending...'
                        : this.state.activeStep === 3
                          ? 'Send'
                          : 'Next'}
                    </NextButton>
                  </div>
                )}
                {/* </ModalFooter> */}
              </div>
            </Paper>
          </Fade>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({ ...state });

export default withTeam(connect(mapStateToProps)(PushNotificationModal));
