import { Fragment, ReactNode, useMemo, useState } from 'react';
import { TeamMate } from '../../api/team';
import { ReactComponent as Plus } from '../../assets/icons/v2/general/plus_m.svg';
import { useTeam } from '../../hooks/useTeam';
import { H2 } from '../utils/typo';
import MemberInvitationModal from './MemberInvitationModal';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { Skeleton } from '../utils/Skeleton';
import Avatar from '../utils/Avatar';
import { MemberActionColumn } from './MemberActionColumn';
import { twJoin, twMerge } from 'tailwind-merge';
import { Icons } from '../utils/Icons';

const MemberManagement = () => {
  const { team, isTeamLoading } = useTeam();

  const [open, setOpen] = useState<boolean>(false);

  const [sorting, setSorting] = useState<SortingState>([]);

  const columns = useMemo<ColumnDef<TeamMate>[]>(
    () => [
      {
        id: 'user',
        header: 'Members',
        accessorKey: 'user.name',
        cell: (cell) => (
          <div className='flex items-center gap-[16px] py-[12px]'>
            <Avatar name={cell.renderValue() as string} />
            <p className='text-body-1'>{cell.renderValue() as ReactNode}</p>
          </div>
        ),
      },
      {
        id: 'role',
        header: 'Role',
        accessorKey: 'role',
        cell: (cell) => {
          const member = cell.row.original;
          return <MemberActionColumn member={member} />;
        },
      },
    ],
    []
  );

  const tableColumns = useMemo(
    () =>
      isTeamLoading
        ? columns.map((column) => ({
            ...column,
            cell: () => <Skeleton className='h-[24px] bg-gray-200' />,
          }))
        : columns,
    [columns, isTeamLoading]
  );

  const tableData = useMemo(
    () => (isTeamLoading ? Array(1).fill({}) : team.teammates || []),
    [isTeamLoading, team]
  );

  const { getHeaderGroups, getRowModel, getSelectedRowModel } = useReactTable({
    columns: tableColumns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    enableSortingRemoval: false,
    sortDescFirst: true,
  });

  return (
    <>
      <div className='mb-[56px] px-[48px]'>
        <div className='flex items-center justify-between pb-[32px] pt-[40px]'>
          <H2>Members Management</H2>
          <div>
            <button
              className='flex h-[40px] items-center justify-center whitespace-nowrap rounded-[4px] bg-primary-500 px-[10px] py-[8px] text-button-1 text-white focus:bg-primary-500'
              onClick={() => setOpen(true)}
            >
              <Plus className='w-[23px] fill-white p-[2px]' />
              Invite Members
            </button>
          </div>
        </div>
        <div>
          <table className='w-full'>
            {getHeaderGroups().map((headerGroup, idx) => (
              <thead className='border-b-2 border-gray-300' key={idx}>
                {headerGroup.headers.map((header) =>
                  header.isPlaceholder ? null : (
                    <th
                      key={header.id}
                      className={twJoin(
                        'px-[8px] py-[16px] text-left',
                        header.column.getCanSort() && 'cursor-pointer select-none'
                      )}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <div className='flex items-center'>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        <Icons.ArrowDown
                          className={twMerge(
                            'duration-150',
                            header.column.getIsSorted() === 'desc' && 'rotate-180',
                            header.column.getIsSorted() ? 'text-primary-600' : 'text-gray-300'
                          )}
                        />
                      </div>
                    </th>
                  )
                )}
              </thead>
            ))}
            <tbody>
              {!isTeamLoading && getRowModel().rows.length === 0 ? (
                <div className='text-center'>No data</div>
              ) : (
                getRowModel().rows.map((row) => (
                  <Fragment key={row.id}>
                    <tr className='relative border-b border-gray-300 hover:bg-primary-100'>
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          className='whitespace-nowrap px-[12px] py-[16px] first:pl-2 last:pr-0'
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  </Fragment>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      <MemberInvitationModal open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default MemberManagement;
