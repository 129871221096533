import { useQuery } from '@tanstack/react-query';
import { moduleAPI } from 'api/module';
import LoadingSpinner from 'components/loader/LoadingSpinner';
import { ModuleContext } from 'components/utils/module/ModuleContext';
import { Body1, H3 } from 'components/utils/typo';
import { CircleAlert } from 'lucide-react';
import { useContext } from 'react';

interface WarningTypes {
  title: string;
  message: string;
}

export interface WarningData {
  success: WarningTypes[];
  warnings: WarningTypes[];
  errors: WarningTypes[];
}

export const Warnings = () => {
  const { module } = useContext(ModuleContext);
  const { data, isFetching } = useQuery<WarningData>({
    queryKey: ['publish', 'warnings', module?.type, module?.id],
    queryFn: moduleAPI.getPublishWarning,
    enabled: !!module && module?.type !== 'knowledge_base',
    refetchOnWindowFocus: false,
  });
  return isFetching || !data ? (
    <div className='flex h-full items-center justify-center'>
      <LoadingSpinner size='large' />
    </div>
  ) : (
    <>
      {data.errors.map((item, index) => (
        <WarningItem key={index} message={item.message} title={item.title} type='warning' />
      ))}
      {data.warnings.map((item, index) => (
        <WarningItem key={index} message={item.message} title={item.title} type='warning' />
      ))}
    </>
  );
};

interface WarningItemType extends WarningTypes {
  type: 'warning' | 'error';
}

const WarningItem = ({ title, message, type }: WarningItemType) => {
  const color = type === 'warning' ? 'text-orange-500' : 'text-error';
  return (
    <div className='flex flex-col gap-3 p-6'>
      <div className='flex items-center gap-1'>
        <CircleAlert size={16} className={color} strokeWidth={1.5} />
        <H3 className={color}>{title}</H3>
      </div>
      <Body1>{message}</Body1>
    </div>
  );
};
