import { JsonLogicResult } from '@react-awesome-query-builder/core';
import _ from 'lodash';

// traversal of nested object to find an empty rule
export const forEachNested = (O, f, cur?) => {
  O = [O]; // ensure that f is called with the top-level object
  while (O.length) {
    // keep on processing the top item on the stack
    if (
      !f((cur = O.pop())) && // do not spider down if `f` returns true
      cur instanceof Object && // ensure cur is an object, but not null
      [Object, Array].includes(cur.constructor) //limit search to [] and {}
    ) {
      // delete valueSrc property from object; (Not required to check for empty rule)
      // In case of "between" operator, it has inconsistant behavior then other operators,
      // it contains null value even if all values are filled
      cur.hasOwnProperty('valueSrc') && delete cur.valueSrc;
      // delete valueError property from object; (Not required to check for empty rule)
      cur.hasOwnProperty('valueError') && delete cur.valueError;
      O.push.apply(O, Object.values(cur));
    } //search all values deeper inside
  }
};

export const ifEmptyRuleFound = (queryValue) => {
  let isEmptyRuleFound = false;
  forEachNested(_.cloneDeep(queryValue), function (currentValue) {
    if (currentValue === null || currentValue === undefined) {
      isEmptyRuleFound = true;
      return;
    }
  });

  return isEmptyRuleFound;
};

export const convertCondition = (condition, index) => {
  let start = 0;
  let end = 0;
  for (let i = index; i >= 0; i--) {
    if (condition[i] === '[') {
      start = i;
      break;
    }
  }
  for (let i = index; i <= condition.length; i++) {
    if (condition[i] === ')') {
      end = i + 1;
      break;
    }
  }
  let multiselect = condition.slice(start, end);
  var regExp = /\(([^)]+)\)/;
  let match = regExp.exec(multiselect);
  let choices = match && match[1] ? match[1].split(',') : [];
  let variable = multiselect.match(/\[(.*?)\]/)[0];
  let final_condition = '';
  if (~multiselect.indexOf('NOT IN')) {
    for (let i = 0; i < choices.length; i++) {
      final_condition += variable + ' != ' + choices[i];
      if (i < choices.length - 1) {
        final_condition += ' || ';
      }
    }
  } else if (~multiselect.indexOf('IN')) {
    for (let i = 0; i < choices.length; i++) {
      final_condition += variable + ' == ' + choices[i];
      if (i < choices.length - 1) {
        final_condition += ' || ';
      }
    }
  }
  condition = [condition.slice(0, start), final_condition, condition.slice(end)].join('');
  return condition;
};

export const removePrefixesInCondition = (inputString) => {
  return inputString
    .replace(/numerics./g, '')
    .replace(/customNumerics./g, '')
    .replace(/candidate_triggers./g, '')
    .replace(/triggers./g, '')
    .replace(/variables./g, '')
    .replace(/formulae./g, '');
};

interface FhirLogic {
  // 'and' is not used when there is only one condition (FhirCondition)
  // 'and' is added when there are two or more conditions (FhirInnerLogic)
  some: [FhirVariable, FhirInnerLogic | FhirCondition];
}

interface FhirInnerLogic {
  and: FhirCondition[];
}

interface FhirCondition {
  '==': FhirTuple;
}

type FhirVariable = { var: string };
type FhirTuple = [FhirVariable, string | number];

function isAdvancedPhenotyping(conditionLogic: JsonLogicResult) {
  if (conditionLogic.data && 'fhir' in conditionLogic.data) {
    return true;
  }

  return false;
}

export function validateFhirConditions(conditionLogic: JsonLogicResult): string | undefined {
  if (!isAdvancedPhenotyping(conditionLogic)) return undefined;

  const { logic } = conditionLogic;
  // case 1: invalid entire condition logic
  if (!logic || !('and' in logic) || !Array.isArray(logic.and)) return 'Invalid condition';

  const fhirLogics = getFhirLogics(logic.and);
  if (!fhirLogics || fhirLogics.length === 0) return;
  // case 2: Duplicate FHIR conditions are used
  if (!allFieldsUnique(fhirLogics)) return 'Cannot use duplicate FHIR conditions';

  let errorMessage: string | undefined;

  fhirLogics.forEach((logic) => {
    // if already errorMessage was filled, skip next conditions
    if (errorMessage) return;
    const [fhirVariable, fhirTuple] = logic.some;

    errorMessage = checkFhirOperatorsErrorOrNone(fhirTuple as FhirInnerLogic | FhirCondition);
    if (errorMessage) {
      const fhirVarName = fhirVariable.var.split('.')[1];
      const capitalizedName =
        fhirVarName[0].toUpperCase() + fhirVarName.slice(1, fhirVarName.length);
      errorMessage = errorMessage + capitalizedName;
    }
  });

  return errorMessage;
}

function getFhirLogics(logics: Object[]): FhirLogic[] | undefined {
  const fhirFields = ['fhir.labs', 'fhir.vitals'];

  // check if logic
  return logics.filter(
    (obj) =>
      'some' in obj &&
      Array.isArray(obj.some) &&
      obj.some.length > 0 &&
      'var' in obj.some[0] &&
      fhirFields.includes(obj.some[0]['var'] || '')
  ) as FhirLogic[];
}

function allFieldsUnique(fhirLogics: FhirLogic[]): boolean {
  const keys = fhirLogics.map((obj) => obj.some[0].var);
  const uniqueKeys = new Set(keys);
  return uniqueKeys.size === keys.length;
}

function checkFhirOperatorsErrorOrNone(logic: FhirInnerLogic | FhirCondition): string | undefined {
  // case 3: Conditions are not combined with 'and' when there is only one condition
  if (!('and' in logic)) return 'Insufficient operators for the ';
  const conditions = logic.and;

  // case 4: Duplicate conditions
  const operators = logic.and.map((condition) => condition['=='][0].var);
  if (operators.length !== new Set(operators).size) return 'Duplicate operators exist in the ';

  // case 5: Missing required conditions
  if (!matchRequiredOperators(operators)) return 'All required operators must be present in the ';

  // case 6: Invalid number of period conditions (less than one or more)
  if (operators.filter((operator) => operator.includes('period')).length !== 1)
    return 'A Data Period operator must be present in the ';

  // case 7: A matchCount condition is required when matchScope is 'some'
  const requiresMatchCount = conditions.find(
    (condition) =>
      condition['=='][0].var === 'matchScope' &&
      (condition['=='][1] === 'some_exact' || condition['=='][1] === 'some_at_least')
  );
  if (requiresMatchCount && !operators.some((operator) => operator === 'matchCount'))
    return 'Match Count is required when Match Scope is set to "some" in the ';

  return undefined;
}

function matchRequiredOperators(operators: string[]): boolean {
  let requiredOperators = ['name', 'operator', 'operatorValue', 'matchScope'];

  operators.forEach((operator) => {
    if (requiredOperators.includes(operator)) {
      requiredOperators = requiredOperators.filter((o) => o !== operator);
    }
  });

  return requiredOperators.length === 0;
}
