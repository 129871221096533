import { Menu } from '@headlessui/react';
import { Icons } from 'components/utils/Icons';
import { debounce } from 'lodash';
import { HTMLAttributes, useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { twJoin, twMerge } from 'tailwind-merge';
import { AlgoMoreIcon } from '../../../../assets/icons/algoCardIcons';
import { hasViewOnlyPermission } from '../../../../utils/permissions';
import { PREVIEW_CARD_Z_INDEX } from '../../../../v2/utils/constant';
import { VariablesPreview } from '../../../app-previews/common/variable';
import { ConfirmModal } from '../../../utils/modals/ConfirmModal';
import { ModuleContext } from '../../../utils/module/ModuleContext';
import { Body2 } from '../../../utils/typo';
import { ModuleElementContext } from '../../ModuleBoard';
import { TriggerBadge } from './TriggerBadge';
import { GroupCardContext } from '../GroupCard';

interface CardContainerProps extends HTMLAttributes<HTMLDivElement> {
  elementId: number;
  onOpenPanel: () => void;
  isPreviewing: boolean;
  variables: { type: string; name: string }[];
  hideTrigger?: boolean;
}

export const CardContainer = ({
  children,
  className,
  elementId,
  isPreviewing,
  onOpenPanel,
  variables,
  hideTrigger,
  ...rest
}: CardContainerProps) => {
  const { module } = useContext(ModuleContext);
  const [deleteModal, setDeleteModal] = useState(false);

  const { deleteUIElement } = useContext(ModuleElementContext);
  const { watch } = useFormContext();
  const triggerId = watch('trigger');
  const hideOutPut = watch('is_hide');

  // check if inside group card
  const { isInsideGroupCard } = useContext(GroupCardContext);
  // check if group card
  const isGroupCard = !!watch('ui_elements');

  // due to slide panel transition delay
  const [debouncedPreview, setPreview] = useState(false);
  const setDebouncedPreview = debounce(setPreview, 300);

  useEffect(() => {
    if (isPreviewing) {
      setPreview(isPreviewing);
    } else {
      setDebouncedPreview(isPreviewing);
    }
  }, [isPreviewing]);

  return (
    <div
      className={twJoin('relative w-full max-w-[796px]', debouncedPreview && PREVIEW_CARD_Z_INDEX)}
    >
      <div
        className={twMerge(
          'relative box-border flex flex-col gap-[8px] bg-white',
          isGroupCard ? 'group' : 'group/item p-4',
          !isInsideGroupCard && 'rounded-md bg-white shadow-06',
          isPreviewing && 'outline outline-2 outline-primary-600',
          className
        )}
        onDoubleClick={(e) => {
          // Prevents the issue of opening the group card's panel when opening a card within the group
          e.stopPropagation();
          !hasViewOnlyPermission(module?.type) && onOpenPanel();
        }}
        {...rest}
      >
        <div className={twJoin('flex items-center gap-2', isGroupCard && 'px-4 pt-4')}>
          {!hideTrigger && <TriggerBadge isContainer={isInsideGroupCard} triggerId={triggerId} />}
          {hideOutPut && (
            <>
              <Icons.EyeSlashIcon className='fill-gray' />
              <Body2 className='text-gray-700'>Hidden</Body2>
            </>
          )}
        </div>
        <div
          className={twJoin(
            'absolute right-4 hidden',
            isGroupCard ? 'group-hover:block' : 'group-hover/item:block',
            isGroupCard && 'top-4'
          )}
        >
          <Menu>
            {!hasViewOnlyPermission(module?.type) && (
              <Menu.Button>
                <AlgoMoreIcon />
              </Menu.Button>
            )}
            <Menu.Items className='absolute z-10 m-0 w-[100px] rounded border border-gray-500 bg-white shadow-md'>
              <Menu.Item
                className='mb-0 h-[40px] w-[100px] px-3 py-[10px] hover:bg-primary-bg-strong hover:text-white'
                as='p'
                onClick={onOpenPanel}
              >
                <Body2>Edit</Body2>
              </Menu.Item>
              <Menu.Item
                as='p'
                className='mb-0 h-[40px] w-[100px] px-3 py-[10px] hover:bg-primary-bg-strong hover:text-white'
                onClick={() => setDeleteModal(true)}
              >
                <Body2>Delete</Body2>
              </Menu.Item>
            </Menu.Items>
          </Menu>
        </div>
        {children}
      </div>

      {!isInsideGroupCard && <VariablesPreview variables={variables} />}

      <ConfirmModal
        preset='delete'
        open={deleteModal}
        content='Are you sure you want to delete this card?'
        toggleModal={() => setDeleteModal((prev) => !prev)}
        performAction={() => {
          deleteUIElement(elementId);
          setDeleteModal(false);
        }}
      />
    </div>
  );
};
