import clsx from 'clsx';
import { Check, Circle } from 'lucide-react';
import { StepTypes } from './PublishModal';

interface StepStonesProps {
  step: StepTypes;
}

export const StepStones = ({ step }: StepStonesProps) => {
  return (
    <>
      <div className='flex items-center gap-1.5'>
        {step === 'warnings' ? (
          <Circle size={16} className='text-primary-500' strokeWidth={3} />
        ) : (
          <Check
            size={16}
            className='rounded-full bg-primary-500 p-[2px] font-bold text-white'
            strokeWidth={3}
          />
        )}
        <span
          className={clsx(
            step === 'warnings' ? 'text-button-1 text-primary-600' : 'text-body-2 text-gray-700'
          )}
        >
          Warnings
        </span>
      </div>
      <div className='h-0 w-10 border-t-[1.5px] border-gray-200' />
      <div className='flex items-center gap-1.5'>
        <Circle
          size={16}
          strokeWidth={3}
          className={clsx(step === 'select-channels' ? 'text-primary-500' : 'text-gray-300')}
        />
        <span
          className={clsx(
            step === 'select-channels'
              ? 'text-button-1 text-primary-600'
              : 'text-body-2 text-gray-700'
          )}
        >
          Select Channels
        </span>
      </div>
    </>
  );
};
