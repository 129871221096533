import { useMutation } from '@tanstack/react-query';
import { groupCardAPI } from 'api/panels';
import LoadingSpinner from 'components/loader/LoadingSpinner';
import SituationBuilder from 'components/resources/triggers/SituationBuilder';
import Button from 'components/utils/Button';
import { ComboBox } from 'components/utils/ComboBox';
import Input from 'components/utils/Input';
import { AvoSlideCloseModal } from 'components/utils/panels/AvoSlideCloseModal';
import { SlidePanel } from 'components/utils/panels/AvoSlidePanel';
import RequiredMark from 'components/utils/requiredMark';
import { Body1, Body2, Caption2, H4 } from 'components/utils/typo';
import VariableAutoGenerator from 'components/utils/variable-generator';
import { ChevronDown, GripVertical, X } from 'lucide-react';
import { useContext, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { DEFAULT_VARIABLE_NAME, getVariableName } from 'utils/gptUtils';
import { useTriggers } from '../../hooks/module/resources/useTriggers';
import {
  GroupFormVariable,
  labelToCardType,
  useGroupOrderingMutation,
  useGroupUiElements,
} from '../module/cards/GroupCard';
import { ExpandableList } from '../utils/form-input/ExpandableList';
import { Icons } from '../utils/Icons';
import { UIElement } from '../../api/moduleElement';
import { PlusIcon } from '@heroicons/react/20/solid';
import { Disclosure } from '@headlessui/react';
import { twJoin } from 'tailwind-merge';
import { ModuleElementContext } from '../module/ModuleBoard';
import { UiElementContext } from '../utils/module/UiElementContext';

interface MessageFormProps {
  onSave: () => void;
  onCancel: () => void;
  childElements: UIElement[];
  originalChildElements: UIElement[];
  setChildElements: (uiElements: UIElement[]) => void;
}

export const GroupForm = ({
  onSave,
  onCancel,
  originalChildElements,
  childElements,
  setChildElements,
}: MessageFormProps) => {
  const { data: triggerState } = useTriggers();
  const triggers = [...triggerState.triggers, ...triggerState.candidate_triggers];
  const triggerOptions = triggers.map((trigger) => ({
    value: trigger.id,
    label: trigger.title,
  }));
  const {
    reset,
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { isDirty, errors },
  } = useFormContext<GroupFormVariable>();

  const elementId = watch('id');

  const [triggerFormOpen, setTriggerFormOpen] = useState(false);
  const [closeModalOpen, setCloseModalOpen] = useState(false);
  const [triggerId, setTriggerId] = useState<number | undefined>();

  const triggerComponentProps = {
    toggleModal: () => setTriggerFormOpen(false),
    isFullHeight: true,
    selectTrigger: (value) => setValue('trigger', value),
    createModal: true,
    triggerId,
    savePosition: () => {},
  };

  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync, isPending: isMutating } = useMutation({
    mutationFn: groupCardAPI.upsertGroupCard,
  });

  const saveChildCardsPosition = useGroupOrderingMutation();

  const onItemsChange = (id) => {
    const addedElement = [...uiElements, ...originalChildElements].find(
      (element) => element.id === id
    );
    if (addedElement) {
      setChildElements([...childElements, addedElement]);
    }
  };

  const onItemDelete = (id: number) => {
    //handleDelete(id);
    setChildElements(childElements.filter((element) => element.id !== id));
  };

  const onSubmit = async (data: GroupFormVariable) => {
    if (isLoading) return;

    setIsLoading(true);
    if (!data.variable) {
      // If the group card title is empty, apply the default variable name
      data.variable = await getVariableName(data.name || DEFAULT_VARIABLE_NAME);
    }

    const { id } = await mutateAsync(data);
    // Save the order of child cards after saving the group card because the order is not guaranteed during group card saving
    await saveChildCardsPosition(childElements);
    setIsLoading(false);
    onSave();
    reset({ ...data, id });
  };

  // ComboBox options data for selecting child cards
  const { uiElements } = useContext(UiElementContext);
  const uiElementOptions = useMemo(() => {
    const removedChildElements = originalChildElements.filter(
      (element) => !childElements.some((childElement) => childElement.id === element.id)
    );
    return uiElements
      .filter((element) => !element.is_introduction && !element.ui_elements) // filter out introduction and group cards
      .filter((element) => !childElements.some((childElement) => childElement.id === element.id)) // filter out already added elements
      .concat(removedChildElements)
      .map((element) => ({
        value: element.id,
        label: element.title || element.name,
      }));
  }, [uiElements, childElements]);

  return (
    <>
      <form className='flex h-full flex-col' onSubmit={handleSubmit(onSubmit)}>
        <div className='grow overflow-y-auto'>
          <div className='sticky top-0 z-10 flex items-center justify-between bg-white pl-4 pr-3 pt-[20px]'>
            <H4>Group</H4>
            <div
              className='cursor-pointer'
              onClick={() => (isDirty ? setCloseModalOpen(true) : onCancel())}
            >
              <X size={20} />
            </div>
          </div>
          <div className='mt-4 flex flex-col gap-6 px-4'>
            <div className='space-y-1'>
              <div className='flex gap-1'>
                <Body2 className='text-gray-900'>Items</Body2>
                <RequiredMark />
              </div>
              <ComboBox
                onChange={(value: any) => setValue('trigger', value, { shouldDirty: true })}
                options={triggerOptions}
                selectedValue={watch('trigger')}
                onCreate={() => {
                  setTriggerId(undefined);
                  setTriggerFormOpen(true);
                }}
                onEdit={(id) => {
                  setTriggerId(+id);
                  setTriggerFormOpen(true);
                }}
                createButtonLabel='Create New Trigger'
              />
            </div>
            <div className='space-y-1'>
              <div className='flex gap-1'>
                <Body2 className='text-gray-900'>Title</Body2>
              </div>
              <Input
                {...register('name', {
                  maxLength: 200,
                })}
                maxLength={200}
              />
              <Caption2 className='flex justify-end text-gray-text-weak'>
                {watch('name')?.length}/200
              </Caption2>
            </div>
            <div className='space-y-1'>
              <div className='flex gap-1'>
                <Body2 className='text-gray-900'>Items</Body2>
                <RequiredMark />
              </div>
              {/* TODO: Fix the issue where the top selected card is added when clicking outside the component after opening the ComboBox */}
              <ComboBox onChange={onItemsChange} options={uiElementOptions}>
                {/* Disabled due to UX issues */}
                {/* <CardDisclosure elementId={elementId} nextChildPosition={childElements.length} /> */}
              </ComboBox>

              {childElements.length > 0 && (
                <ExpandableList setValue={setChildElements} items={childElements}>
                  <ExpandableList.Items>
                    {childElements.map((item, index) => (
                      <ExpandableList.Item index={index} key={item.id}>
                        <ExpandableList.Button className='group flex items-center py-[12px] pr-[16px]'>
                          <GripVertical
                            size={16}
                            className='stroke-transparent group-hover:stroke-gray-600'
                          />
                          <div className='grow'>
                            <Body1 className='line-clamp-1 h-[24px] text-gray-900'>
                              {item.title || item.name}
                            </Body1>
                          </div>
                          <Icons.TrashBin
                            className='cursor-pointer fill-gray-600 hover:fill-primary-600'
                            onClick={() => onItemDelete(item.id)}
                          />
                        </ExpandableList.Button>
                      </ExpandableList.Item>
                    ))}
                  </ExpandableList.Items>
                </ExpandableList>
              )}
            </div>
            <div>
              <VariableAutoGenerator
                question={watch('name')}
                tagTitle='Group'
                variable={watch('variable')}
                setVariableName={(variable) => setValue('variable', variable)}
              />
            </div>
          </div>
        </div>
        <div className='shrink-0'>
          <div className='flex justify-end gap-2 px-[12px] pb-[22px] pt-[12px]'>
            <div className='flex'>
              <Button.Reverse
                type='button'
                onClick={() => (isDirty ? setCloseModalOpen(true) : onCancel())}
                disabled={isLoading}
              >
                Cancel
              </Button.Reverse>
            </div>
            <div className='flex w-[80px]'>
              <Button disabled={isLoading || !isDirty}>
                {isLoading ? <LoadingSpinner /> : 'Save'}
              </Button>
            </div>
          </div>
        </div>
      </form>
      <AvoSlideCloseModal
        open={closeModalOpen}
        onContinue={() => setCloseModalOpen(false)}
        onDiscard={onCancel}
      />
      <SlidePanel open={triggerFormOpen} onClose={() => setTriggerFormOpen(false)}>
        <div className='mx-4 mt-3'>
          <SituationBuilder {...triggerComponentProps} />
        </div>
      </SlidePanel>
    </>
  );
};

function CardDisclosure({
  elementId,
  nextChildPosition,
}: {
  elementId: number;
  nextChildPosition: number;
}) {
  const { addModuleElement } = useContext(ModuleElementContext);

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={twJoin(
              'flex w-full items-center justify-between border-b !px-3 !py-2.5 hover:bg-primary-200',
              open ? 'border-gray-200 bg-gray-100' : 'border-gray-300',
              open ? 'focus:bg-gray-100' : 'focus:bg-transparent' //due to material
            )}
          >
            <div className='flex items-center gap-1'>
              <PlusIcon className='text-gary-800 h-4 w-4' />
              <p className='truncate text-button-1 text-gray-900'>Create New Item</p>
            </div>
            <ChevronDown
              size={15}
              className={twJoin('stroke-gray-700 transition', open && 'rotate-180')}
            />
          </Disclosure.Button>
          <Disclosure.Panel
            className={twJoin('border-b', open ? 'border-gray-200 bg-gray-100' : 'border-gray-300')}
          >
            {Object.keys(labelToCardType).map((label) => (
              <button
                key={label}
                type='button'
                className='flex w-full items-center gap-1 self-stretch !py-2.5 !pl-8 !pr-3 hover:bg-primary-200'
                onClick={() =>
                  addModuleElement({
                    parentId: elementId,
                    position: nextChildPosition,
                    resourcetype: labelToCardType[label],
                  })
                }
              >
                {label}
              </button>
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
