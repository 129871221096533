import { QueryFunctionContext } from '@tanstack/react-query';
import axios from 'axios';
import {
  AMB_LISTENING_API_URL,
  CONTAINER_CARD_API_URL,
  DATA_EXTRACTOR_API_URL,
  DESCRIPTION_PANEL_API_URL,
  CHOICE_PANEL_API_URL,
  MULTI_CHOICE_PANEL_API_URL,
} from '../constants';
import { GroupFormVariable } from '../components/module/cards/GroupCard';

export interface PostAmbListeningVariable {
  id?: number;
  title: string;
  subtitle?: string;
  type: string;
  variable: string;
  container?: string | number;
  trigger: string | number | null;
  module?: number;
  position: number;
}

export interface PostAmbListeningData {
  id: number;
}

export const ambListeningAPI = {
  getAmbListening: ({ queryKey }: QueryFunctionContext) =>
    axios.get(`${AMB_LISTENING_API_URL}${queryKey[1]}`).then((response) => response.data),
  postAmbListening: (data) =>
    axios.post(AMB_LISTENING_API_URL, data).then((response) => response.data),
  putAmbListening: (data, ambId) =>
    axios.patch(`${AMB_LISTENING_API_URL}${ambId}/`, data).then((response) => response.data),
};

export const msgCardAPI = {
  getMsgCard: ({ queryKey }: QueryFunctionContext) =>
    axios.get(`${DESCRIPTION_PANEL_API_URL}${queryKey[1]}/`).then((response) => response.data),
  postMsgCard: (data) =>
    axios.post(DESCRIPTION_PANEL_API_URL, data).then((response) => response.data),
  putMsgCard: (data, msgId) =>
    axios.patch(`${DESCRIPTION_PANEL_API_URL}${msgId}/`, data).then((response) => response.data),
};

export const groupCardAPI = {
  getGroupCard: ({ queryKey }: QueryFunctionContext) =>
    axios
      .get(`${CONTAINER_CARD_API_URL}${queryKey[1]}/container_card`)
      .then((response) => response.data),
  upsertGroupCard: (data: GroupFormVariable) =>
    !!data.id
      ? axios
          .put(`${CONTAINER_CARD_API_URL}${data.id}/`, { ...data, id: undefined })
          .then((res) => res.data)
      : axios.post(CONTAINER_CARD_API_URL, data).then((response) => response.data),
};

export const choiceCardAPI = {
  postChoiceCard: (data) =>
    axios.post(CHOICE_PANEL_API_URL, data).then((response) => response.data),
  putChoiceCard: (data, choiceId) =>
    axios.patch(`${CHOICE_PANEL_API_URL}${choiceId}/`, data).then((response) => response.data),
  tmpPostCheckbox: (data) =>
    axios.post(MULTI_CHOICE_PANEL_API_URL, data).then((response) => response.data),
  tmpPutCheckbox: (data, choiceId) =>
    axios
      .patch(`${MULTI_CHOICE_PANEL_API_URL}${choiceId}/`, data)
      .then((response) => response.data),
};
