import { UIElement } from 'api/moduleElement';
import { ChoiceForm } from 'components/panels/ChoiceForm';
import Checkbox from 'components/utils/Checkbox';
import { Icons } from 'components/utils/Icons';
import { ModuleContext } from 'components/utils/module/ModuleContext';
import { SlidePanel } from 'components/utils/panels/AvoSlidePanel';
import { RadioButton } from 'components/utils/RadioButton';
import { Body1, Body2, H4 } from 'components/utils/typo';
import { useTriggers } from 'hooks/module/resources/useTriggers';
import { Info } from 'lucide-react';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MODULE_TYPES } from '../../../constants';
import { DraggableContext, ModuleElementContext } from '../ModuleBoard';
import { GroupCardContext } from './GroupCard';
import { CardContainer } from './utils/CardContainer';

interface ChoiceCardProps {
  element: UIElement;
  multi?: boolean;
}

export interface ChoiceCardFormVariable {
  id?: string;
  is_submittable: boolean;
  is_dropdown_item: boolean;
  title: string;
  subtitle: string;
  variable: string;
  container: any;
  choices: any[];
  question_items: any[];
  trigger?: number;
  module?: number;
  calculator?: number;
  position?: number;
  is_choice_dynamic: boolean;
  dynamic_choice_unique_code?: string;
  examples: any[];
  tools: any[];
  is_multi_selectable: boolean;
  has_infobox_in_options: boolean;
}

export const ChoiceCard = ({ element, multi }: ChoiceCardProps) => {
  const { module } = useContext(ModuleContext);
  const { groupCardId } = useContext(GroupCardContext);
  const [formOpen, setFormOpen] = useState(!element.id);
  const { setIsDragDisabled } = useContext(DraggableContext);
  useEffect(() => setIsDragDisabled(formOpen), [formOpen]);

  const {
    data: { triggers, candidate_triggers },
  } = useTriggers();
  const triggerList = [...triggers, ...candidate_triggers];

  const alwayOnTrigger = triggerList.find((data) => data.title === 'Always On');

  const defaultValues: ChoiceCardFormVariable = {
    module: module && module.type === MODULE_TYPES.ALGO ? module.id : undefined,
    calculator: module && module.type === MODULE_TYPES.CALCULATOR ? module.id : undefined,
    trigger: element.trigger ? element.trigger.id : alwayOnTrigger?.id,
    title: element.title,
    subtitle: element.subtitle,
    choices: !element.choices
      ? [
          { name: 'Option 1', coefficient: 0, position: 0, is_selected: false },
          { name: 'Option 2', coefficient: 1, position: 1, is_selected: false },
          { name: 'Option 3', coefficient: 2, position: 2, is_selected: false },
        ]
      : element.choices
          .filter((choice) => choice.name !== 'submitted' && choice.name !== 'count')
          .map((choice, i) => ({
            ...choice,
            coefficient: choice.normalized_coefficient ?? i,
            infobox: choice.infobox ? choice.infobox.id : null,
          })),
    question_items: [],
    is_choice_dynamic: element.is_choice_dynamic,
    container: groupCardId,
    position: element.position,
    variable: element.variable_item?.name || '',
    is_submittable: element.is_submittable ?? true,
    is_dropdown_item: element.is_dropdown_item ?? false,
    dynamic_choice_unique_code: element.dynamic_choice_unique_code,
    examples: element.examples || [],
    tools: element.tools || [],
    is_multi_selectable: multi ?? element.is_multi_selectable ?? false,
    has_infobox_in_options: element.has_infobox_in_options ?? true,
  };
  const form = useForm<ChoiceCardFormVariable>({ defaultValues });
  const choices = form.watch('choices');

  const { refetchModuleElements, clearModuleElements } = useContext(ModuleElementContext);
  const onCancel = () => {
    clearModuleElements();
    form.reset(defaultValues);
    setFormOpen(false);
  };

  const onSave = () => {
    try {
      refetchModuleElements();
      setFormOpen(false);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <FormProvider {...form}>
      <CardContainer
        elementId={element.id}
        onOpenPanel={() => setFormOpen(true)}
        isPreviewing={formOpen}
        variables={[
          {
            type: form.watch('is_multi_selectable') ? 'checkbox' : 'choice',
            name: form.watch('variable'),
          },
        ]}
      >
        <div className='space-y-1'>
          <H4 className='text-gray-900'>{form.watch('title')}</H4>
          <Body2 className='text-gray-700'>{form.watch('subtitle')}</Body2>
          {!!form.watch('examples').length && (
            <>
              <div className='mb-1 mt-2 min-h-14 rounded border border-gray-200 bg-gray-100 px-3 py-2'>
                <div className='text-button-1'>Example</div>
                <div className='line-clamp-2 flex flex-wrap items-center'>
                  {form
                    .watch('examples')
                    .map(
                      (example, idx) =>
                        example.label ||
                        example.text?.statement ||
                        example.infobox?.shortened_title ||
                        example.image?.representation_phrase
                    )
                    .join(', ')}
                </div>
              </div>
            </>
          )}
          {!!form.watch('tools').length && (
            <div className='flex flex-wrap gap-1'>
              {form.watch('tools').map((tool) => (
                <div className='flex items-center gap-1'>
                  {!!tool.infobox ? <Icons.ToolInfobox /> : <Icons.ToolCalc />}
                  <span className='line-clamp-1 font-bold text-primary-600'>
                    {tool.label ||
                      tool.infobox?.shortened_title ||
                      tool.image?.representation_phrase ||
                      tool.legacy_calculator?.title ||
                      tool.calculator?.name}
                  </span>
                </div>
              ))}
            </div>
          )}
          {form.watch('is_choice_dynamic') ? (
            <>
              <div className='flex items-center gap-1 rounded bg-gray-100 px-2 py-2.5'>
                <Info size={24} className='text-gray-300' />
                <div className='text-gray-700'>
                  Will be generated automatically based on the selected list.
                </div>
              </div>
              {form.watch('dynamic_choice_unique_code') && (
                <div className='mt-[2px] flex items-center gap-1 rounded bg-gray-100 px-2 py-2.5'>
                  <div className='p-[3.5px]'>
                    <div className='h-[17px] w-[17px] rounded-sm border border-gray-300 bg-white' />
                  </div>
                  <div className='text-gray-700'>[Auto-generated option]</div>
                </div>
              )}
            </>
          ) : (
            <div className='space-y-1 pt-1'>
              {!!choices.length &&
                choices.map((choice) => (
                  <div className='space-y-0.5'>
                    <ChoiceItem
                      name={element.id + ''}
                      choice={choice}
                      multi={form.watch('is_multi_selectable')}
                    />
                  </div>
                ))}
            </div>
          )}
        </div>
      </CardContainer>
      <SlidePanel open={formOpen}>
        <ChoiceForm choiceId={element.id} onSave={onSave} onCancel={onCancel} />
      </SlidePanel>
    </FormProvider>
  );
};

interface ChoiceItemProps {
  multi?: boolean;
  choice: any;
  name: string;
}
const ChoiceItem = ({ name, choice, multi }: ChoiceItemProps) => {
  return (
    <div className='flex items-center gap-2 rounded bg-gray-100 px-2 py-2.5'>
      {multi ? (
        <Checkbox checked={choice.is_selected} />
      ) : (
        <RadioButton name={name} checked={choice.is_selected} />
      )}
      <Body1 className='text-gray-900'>{choice.name}</Body1>
      {choice.infobox && <Icons.ToolInfobox />}
    </div>
  );
};
