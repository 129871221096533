import clsx from 'clsx';
import { ModuleHeader } from 'components/bars/ModuleHeader';
import { ModuleSidebar } from 'components/bars/ModuleSidebar';
import NotFoundPage from 'components/home/NotFoundPage';
import { globalStyles } from 'components/utils/bootsrap-and-materialize';
import { HTMLAttributes } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ConvertingButton } from '../utils/tiptap/converter/converting-button/ConvertingButton';
import { ContextWrapper } from './ContextWrapper';

interface ModuleLayoutProps extends HTMLAttributes<HTMLDivElement> {}

export const ModuleLayout = ({ children, className }: ModuleLayoutProps) => {
  return (
    <ErrorBoundary FallbackComponent={NotFoundPage}>
      <ContextWrapper>
        {/* need to remove after refactoring all module related components */}
        {globalStyles}
        {/*  */}
        <div className='flex h-screen flex-col'>
          <ModuleHeader className='!relative' />
          <div className='flex grow overflow-hidden'>
            <div className='min-w-[164px] overflow-y-auto shadow-md'>
              <div className='flex h-full flex-col justify-between'>
                <ModuleSidebar />
                <ConvertingButton />
              </div>
            </div>
            <div
              className={clsx('h-full grow overflow-y-auto', className)}
              id='module-layout-scroll-target'
            >
              {children}
            </div>
          </div>
        </div>
      </ContextWrapper>
    </ErrorBoundary>
  );
};
