import { useIsFetching, useMutation, useQueryClient } from '@tanstack/react-query';
import { moduleAPI } from 'api/module';
import LoadingSpinner from 'components/loader/LoadingSpinner';
import Button from 'components/utils/Button';
import { ModuleContext } from 'components/utils/module/ModuleContext';
import { Skeleton } from 'components/utils/Skeleton';
import { CustomToast } from 'components/utils/toast-message';
import { useTeam } from 'hooks/useTeam';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  CALC_SYNC_FOR_RELEASE_API,
  FIREBASE_RELEASE_SYNC_API_URL,
  KNOWLEDGE_BASE_SYNC_API_URL,
} from '../../constants';
import { Modal } from '../utils/modals/Modal';
import { SelectChannels } from './SelectChannels';
import { StepStones } from './StepStones';
import { WarningData, Warnings } from './Warnings';

interface PublishModalProps {
  open: boolean;
  onClose: () => void;
}

export interface PublishFormVariables {
  module?: number;
  calculator?: number;
  knowledge_base?: number;
  channels: number[];
  team: number;
  is_universal?: boolean;
}

export type StepTypes = 'warnings' | 'select-channels';

export const PublishModal = ({ open, onClose }: PublishModalProps) => {
  const { module } = useContext(ModuleContext);
  const { team } = useTeam();
  const queryClient = useQueryClient();
  const warningData = queryClient.getQueryData<WarningData>([
    'publish',
    'warnings',
    module?.type,
    module?.id,
  ]);
  const isFetching = useIsFetching({ queryKey: ['publish'] });
  const [step, setStep] = useState<StepTypes>('warnings');
  useEffect(() => {
    if (module?.type === 'knowledge_base') setStep('select-channels');

    if (!warningData || isFetching) return;
    if (!!warningData.success.length) {
      setStep('select-channels');
    }
  }, [module?.type, warningData, isFetching]);

  const onClickSave = () => {
    if (!!warningData?.errors.length) return;
    if (step === 'warnings') {
      setStep('select-channels');
    } else {
      onPublish(form.getValues());
    }
  };

  const getSyncURL = () => {
    if (!module) throw new Error('Module is required');
    let baseUrl = '';
    switch (module.type) {
      case 'algo':
        baseUrl = FIREBASE_RELEASE_SYNC_API_URL;
        break;
      case 'calculator':
        baseUrl = CALC_SYNC_FOR_RELEASE_API;
        break;
      case 'knowledge_base':
        baseUrl = KNOWLEDGE_BASE_SYNC_API_URL;
        break;
    }
    return baseUrl + module.id;
  };

  const { mutate, isPending } = useMutation({
    mutationFn: (data: PublishFormVariables) => {
      const url = getSyncURL();
      return moduleAPI.publishModule(url, data);
    },
    onSuccess: () => {
      onClickCancel();
      toast.success(CustomToast, { data: 'Your module has been published successfully.' });
    },
    onError: (error) => {
      toast.error(CustomToast, { data: error });
    },
  });

  const onPublish = (data: PublishFormVariables) => {
    if (!module || isPending) return;
    mutate(data);
  };

  const onClickCancel = () => {
    onClose();
    if (module?.type !== 'knowledge_base') setTimeout(() => setStep('warnings'), 500);
  };

  const defaultValues: PublishFormVariables = useMemo(
    () => ({
      module: module?.type === 'algo' ? module?.id : undefined,
      calculator: module?.type === 'calculator' ? module?.id : undefined,
      ai_knowledge_base: module?.type === 'knowledge_base' ? module?.id : undefined,
      channels: [],
      team: team.id,
      is_universal: module?.type === 'calculator' ? module?.is_universal || false : undefined,
    }),
    [module, team]
  );

  const form = useForm<PublishFormVariables>({ defaultValues });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  return (
    <Modal open={open} title='Publish' onClose={onClickCancel} size='large'>
      <Modal.Head onClose={onClickCancel}>Publish Modules</Modal.Head>
      <Modal.Body>
        <div className='-mx-6'>
          <div className='flex items-center gap-4 px-6 pb-4 shadow-md'>
            {isFetching && step === 'warnings' ? <Skeleton /> : <StepStones step={step} />}
          </div>
          <div className='h-[392px] overflow-y-auto px-6 py-2'>
            {step === 'warnings' ? (
              <Warnings />
            ) : (
              <FormProvider {...form}>
                <SelectChannels />
              </FormProvider>
            )}
          </div>
          <div className='flex justify-end gap-2 px-4 py-3'>
            <div className='flex'>
              <Button.Reverse onClick={onClickCancel}>Cancel</Button.Reverse>
            </div>
            <div className='w-[80px]'>
              <Button
                disabled={!!warningData?.errors.length || isPending || !!isFetching}
                onClick={onClickSave}
              >
                {isPending ? (
                  <LoadingSpinner size='medium' />
                ) : step === 'warnings' ? (
                  'Next'
                ) : (
                  'Publish'
                )}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
