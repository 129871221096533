import { Box, MenuList, withStyles } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getCustomNumerics } from 'actions/resources/getCustomNumerics';
import { getInfoBoxes } from 'actions/resources/infoBoxes';
import getUniqueCode from 'actions/unique-code/getUniqueCodeAction';
import AddIcon from 'assets/icons/addIcon';
import SearchIcon from 'assets/icons/searchIcon';
import axios from 'axios';
import CustomNumericForm from 'components/resources/custom-numerics/CustomNumericForm';
import AppearingSituation from 'components/utils/AppearingSituation';
import Checkbox from 'components/utils/Checkbox';
import Label from 'components/utils/Label';
import MenuDropdown from 'components/utils/MenuDropdown';
import { StaffOnlyBadgeType, StaffOnlyWrapper } from 'components/utils/StaffOnlyWrapper';
import NumberListItem from 'components/utils/example-n-tools/NumberListItem';
import InputField from 'components/utils/form-input/field';
import FieldLabel from 'components/utils/form-input/fieldLabel';
import { getHelpInfoData } from 'components/utils/general/helpInfo';
import { ConfirmModal } from 'components/utils/modals/ConfirmModal';
import 'components/utils/modals/modal.css';
import { Header } from 'components/utils/panels/Header';
import {
  AddButton,
  AddLabel,
  Examples,
  GreenCheckbox,
  NumericsMenu,
  NumericsMenuItem,
} from 'components/utils/styled-components/FormStyle';
import { CustomToast } from 'components/utils/toast-message';
import { CANCEL_BUTTON, CARD_NUMBERS, SAVE_BUTTON } from 'constants/variables';
import React, { Component, FormEvent } from 'react';
import { connect } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import { toast } from 'react-toastify';
import { Form } from 'reactstrap';
import {
  MODULE_TYPES,
  NUMERICS_API_URL,
  RETRIEVE_VALUE_PANEL_API_URL,
  VALUE_PANEL_API_URL,
} from '../../constants';
import { CancelButton, CreateButton, FixedRow, UnderlyingElement } from './ChoicePanelForm';
import { ValuePanelFormDispatchProps } from './types';
import { withTriggers } from '../../hooks/module/resources/useTriggers';

const StyledFormLabel = withStyles((theme) => ({
  label: {
    marginTop: 4,
  },
}))(FormControlLabel);

interface ValuePanelFormState {
  numericDropDownOptions: any;
  numerics: any;

  pk: string;
  nextButtonModal: boolean;
  name: string;
  renderValuePreview: boolean;
  short_name: string;
  trigger: string;
  module: any;
  position: any;
  is_submitable: boolean;
  customNumericsDropDownEl: any;
  numericsDropDownEl: any;
  searchNumeric: string;
  searchCustomNumeric: string;
  createModal: boolean;
  editModal: boolean;
  customNumericId: any;
  isNumeric: boolean;
  addToPanel: boolean;
  confirmationState: boolean;
  is_hide: boolean;
}

interface ValuePanelFormProps {
  choiceCoefficient?: boolean;
  triggerState?: any;
  formState?: any;
  moduleCode?: string;
  infoboxState?: any;
  mediaState?: any;
  calculatorState?: any;
  type?: any;
  resetAnswerCards?: any;
  teamCalculatorState?: any;
  valuePanelId: string;
  calculatorId: string;
  moduleId: string;
  position: number;
  container: boolean;
  customNumericState?: any;
  handleSelectedItem: () => void;
  handleLivePreview: (
    choices: any,
    question: any,
    examples: any,
    rationale: any,
    trigger: any,
    tools: any,
    renderPreview: any
  ) => void;
  handleAddItems: (item: any) => void;
  handleAddTitle: (name: string) => void;
  handleShowNext: (is_submitable: boolean) => void;
  handleAddExample: (example: any) => void;
  handleAddTools: (tools: any) => void;
  handleAddTrigger: (trigger: any) => void;
  toggleModal: () => void;
  resetState: () => void;
  resetAnswerPages: () => void;
  startPreview: (component: string) => void;
  startBackdrop: () => void;
  stopBackdrop: () => void;
  modal?: boolean;
  closeUiElementPane?: (type?: any) => void;
}

type Props = ValuePanelFormDispatchProps & ValuePanelFormProps;

class ValuePanelForm extends Component<Props, ValuePanelFormState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      numericDropDownOptions: [],
      numerics: [],

      pk: '',
      nextButtonModal: false,
      name: '',
      renderValuePreview: false,
      short_name: '',
      trigger: '',
      module: '',
      position: null,
      is_submitable: true,
      customNumericsDropDownEl: null,
      numericsDropDownEl: null,
      searchNumeric: '',
      searchCustomNumeric: '',
      createModal: false,
      editModal: false,
      customNumericId: '',
      isNumeric: false,
      addToPanel: false,
      confirmationState: false,
      is_hide: false,
    };
  }

  toggleNextButtonModal = () => {
    this.setState((previous) => ({
      nextButtonModal: !previous.nextButtonModal,
    }));
  };

  // toggle confirmation modal
  toggleConfirmationModal = () => {
    this.setState((previous) => ({
      confirmationState: !previous.confirmationState,
    }));
  };

  componentDidUpdate(prevProps: any) {
    const prevCustomNumerics = prevProps?.customNumericState?.customNumerics || [];
    const currentCustomNumerics = this.props?.customNumericState?.customNumerics || [];

    // new custom numeric added
    if (prevCustomNumerics.length < currentCustomNumerics.length) {
      const newNumeric = currentCustomNumerics[currentCustomNumerics.length - 1];
      this.addNumeric(newNumeric, 'custom');
    } else if (prevCustomNumerics !== currentCustomNumerics) {
      const updatedNumerics = this.state.numerics.map((numeric: any) => {
        if (numeric.numeric_type === 'predefined') {
          return numeric;
        }

        const customNumericObj = currentCustomNumerics.find(
          (customNumeric: any) => numeric.id === customNumeric.id
        );

        if (
          customNumericObj &&
          (customNumericObj.name !== numeric.name ||
            customNumericObj.display_value !== numeric.display_value)
        ) {
          return {
            ...numeric,
            name: customNumericObj.name,
            display_value: customNumericObj.display_value,
          };
        }

        return numeric;
      });

      this.setState({ numerics: updatedNumerics });
    }
  }

  helpInfoData = getHelpInfoData('ValuePanel');

  componentDidMount() {
    // TODO: remove when redux data is depracated
    const { calculatorId, moduleId, getInfoBoxes, getCustomNumerics } = this.props;
    const moduleType = calculatorId ? MODULE_TYPES.CALCULATOR : MODULE_TYPES.ALGO;
    const id = calculatorId || moduleId;

    getInfoBoxes(moduleType, null, id);
    getCustomNumerics(moduleType, null, id);

    if (!this.props.valuePanelId) {
      let alwayOnTrigger = this.props.triggerState.triggers.find(
        (data) => data.title === 'Always On'
      );
      this.setState({
        trigger: alwayOnTrigger ? alwayOnTrigger.id : '',
        position: this.props.position,
      });
      this.props.handleSelectedItem();
      this.props.handleAddTrigger(alwayOnTrigger ? alwayOnTrigger.id : '');
      this.props.startPreview('renderValuePreview');
    }

    if (this.props.container) this.setState({ is_submitable: false });

    // getting universal numerics
    axios.get(NUMERICS_API_URL).then((res) => this.setState({ numericDropDownOptions: res.data }));

    // if update request encountered
    if (this.props.valuePanelId) {
      axios.get(RETRIEVE_VALUE_PANEL_API_URL + this.props.valuePanelId + '/').then((res) => {
        this.setState({
          pk: res.data.id,
          name: res.data.name,
          short_name: res.data.short_name,
          numerics: res.data.panel_item,
          trigger: res.data.trigger ? res.data.trigger.id : '',
          module: res.data.module ? res.data.module.id : '',
          position: res.data.position,
          is_submitable: res.data.is_submitable,
          is_hide: res.data.is_hide,
        });
        this.props.handleAddItems(res.data.panel_item);
        this.props.handleAddTitle(res.data.name);
        this.props.handleShowNext(res.data.is_submitable);
        this.props.handleAddTrigger(res.data.trigger ? res.data.trigger.id : '');
      });
    }
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'name') {
      this.setState({ name: e.target.value });
      this.props.handleAddTitle(e.target.value);
    } else {
      this.setState({ [e.target.name]: e.target.value } as Pick<ValuePanelFormState, any>);
    }
  };

  handleClick = (e: any) => this.setState({ customNumericsDropDownEl: e.target });

  closeMenu = () => this.setState({ customNumericsDropDownEl: null });

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ [event.target.name]: event.target.checked } as Pick<ValuePanelFormState, any>);
    this.props.handleShowNext(event.target.checked);
  };

  toggleNextButton = () => {
    this.setState(
      (previous) => ({
        is_submitable: !previous.is_submitable,
      }),
      () => {
        this.props.handleShowNext(this.state.is_submitable);
        if (!this.state.is_submitable) {
          this.toggleNextButtonModal();

          let numerics = [...this.state.numerics];
          for (let i = 0; i < numerics.length; i++) {
            numerics[i].default_value = null;
          }

          this.setState({ numerics });
          this.props.handleAddItems(numerics);
        }
      }
    );
  };

  toggleCreateModal = () => {
    this.setState((previous) => ({
      createModal: !previous.createModal,
      customNumericId: '',
      customNumericsDropDownEl: null,
    }));
  };

  toggleEditModal = (id: string) => {
    this.setState((previous) => ({
      editModal: !previous.editModal,
      customNumericId: id,
      customNumericsDropDownEl: null,
    }));
  };

  addNumeric = (numeric: any, type: any) => {
    this.setState({
      numericsDropDownEl: null,
      customNumericsDropDownEl: null,
      customNumericId: null,
    });

    if (!numeric) {
      toast.warning(CustomToast, { data: 'Numeric is not selected' });
      return;
    }

    let isFound = this.state.numerics.find((num) => num.id === numeric.id);
    if (!isFound) {
      let obj = {
        id: numeric.id,
        name: numeric.title || numeric.name,
        code: numeric.code || numeric.unique_code,
        display_value: numeric.display_value,
        default_value: null,
        is_optional: false,
        position: this.state.numerics.length,
        numeric_type: type,
        infobox: null,
      };

      let concatedArray = this.state.numerics.concat(obj);
      this.setState({ numerics: concatedArray });
      this.props.handleAddItems(concatedArray);
    } else {
      toast.warning(CustomToast, { data: 'Numeric is already added' });
    }
  };

  updateNumerics = (newList: any) => {
    for (let i = 0; i < newList.length; i++) {
      newList[i].position = i;
    }
    this.setState({ numerics: newList });
    this.props.handleAddItems(newList);
  };

  updateNumeric = (id: number, value: string, inputName: string, numeric_type: any) => {
    const updatedNumerics = this.state.numerics.map((numeric: any) => {
      let updatedItem = numeric;

      if (numeric.id === id && numeric.numeric_type === numeric_type) {
        if (inputName === 'default_value') {
          updatedItem = {
            ...numeric,
            default_value: !isNaN(parseFloat(value)) ? value : null,
          };
        } else if (inputName === 'infobox') {
          updatedItem = {
            ...numeric,
            infobox: value === '-----' ? null : value,
          };
        } else if (inputName === 'is_optional') {
          updatedItem = {
            ...numeric,
            is_optional: value,
          };
        }
        return updatedItem;
      }

      return updatedItem;
    });

    this.setState({ numerics: updatedNumerics });
    this.props.handleAddItems(updatedNumerics);
  };

  deleteNumeric = (numericId: string) => {
    let prevNumerics = this.state.numerics.filter((el) => el.id !== numericId);
    for (let i = 0; i < prevNumerics.length; i++) {
      prevNumerics[i].position = i;
    }

    this.setState({ numerics: prevNumerics });
    this.props.handleAddItems(prevNumerics);
  };

  getPayload = () => {
    return {
      name: this.state.name,
      short_name: this.state.short_name,
      panel_item: this.state.numerics,
      trigger: this.state.trigger === 'notAssigned' ? null : this.state.trigger,
      module: this.props.moduleId,
      calculator: this.props.calculatorId,
      position: this.state.position,
      container: this.props.container,
      is_submitable: this.state.is_submitable,
      is_hide: this.state.is_hide,
    };
  };

  backdropAwait = async () => {
    if (this.props.calculatorId) {
      await this.props.getUniqueCode(this.props.calculatorId, 'calculator');
    } else {
      await this.props.getUniqueCode(this.props.moduleId);
    }
    this.props.resetState();
  };

  createValuePanel = () => {
    if (!this.state.editModal) {
      if (this.state.numerics.length < 1) {
        toast.error(CustomToast, { data: 'At least one panel item is required' });
        return;
      }

      this.props.startBackdrop();
      axios.post(VALUE_PANEL_API_URL, this.getPayload()).then(async ({ data }) => {
        // fetch triggers to update used in field of triggers
        this.backdropAwait().then(() => this.props.stopBackdrop());
      });
    }
  };

  editValuePanel = () => {
    if (!this.state.editModal) {
      if (this.state.numerics.length < 1) {
        toast.error(CustomToast, { data: 'At least one panel item is required' });
        return;
      }

      this.props.toggleModal();
      axios.put(VALUE_PANEL_API_URL + this.state.pk + '/', this.getPayload()).then(async () => {
        // fetch triggers to update used in field of triggers

        // reset unique codes mapping to get latest result
        if (this.props.calculatorId) {
          await this.props.getUniqueCode(this.props.calculatorId, 'calculator');
        } else {
          await this.props.getUniqueCode(this.props.moduleId);
        }
        this.props.resetState();
      });
    }
  };

  getSelectedTrigger = (data: any) => {
    this.setState({ trigger: data });
    this.props.handleAddTrigger(data);
  };

  onKeyPress = (e: any) => {
    if (e.which === 13 && e.target.nodeName !== 'TEXTAREA') {
      e.preventDefault();
    }
  };

  handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const { name } = this.state;
    const { valuePanelId, modal, closeUiElementPane } = this.props;
    if (name === '') {
      toast.error(CustomToast, { data: 'Please fill out the Number panle title field.' });
    } else if (valuePanelId) {
      this.editValuePanel();
    } else {
      this.createValuePanel();
    }

    modal && closeUiElementPane?.();
  };

  render() {
    const helpInfoData = this.helpInfoData;
    const triggers = [
      ...this.props.triggerState.triggers,
      ...this.props.triggerState.candidate_triggers,
    ];

    const filteredNumerics = this.state.numericDropDownOptions.filter((numeric) =>
      numeric.title.toLowerCase().includes(this.state.searchNumeric.toLowerCase())
    );

    const customNumericFormProps = {
      type: this.props.calculatorId && 'calculator',
      toggleCreateModal: this.toggleCreateModal,
      toggleEditModal: this.toggleEditModal,
      savePosition: () => [], // TODO: better to delete if it does nothing?
      isFullHeight: true,
      createModal: true,
      addNumeric: this.addNumeric,
      addToPane: true,
      customNumericId: this.state.customNumericId,
    };

    const greenCheckboxProps = {
      checked: this.state.is_submitable,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        this.state.is_submitable ? this.toggleNextButtonModal() : this.toggleNextButton();
      },
      name: 'is_submitable',
    };

    return (
      <div className='row mt-14'>
        <div className='side-panel-form'>
          <Header title={CARD_NUMBERS} toggleModal={this.props.toggleModal} />
          <Form
            className='flex h-full flex-col px-3 pb-0'
            onKeyPress={this.onKeyPress}
            autoComplete='off'
            onSubmit={this.handleSubmit}
          >
            <Box className='mt-3' style={{ marginBottom: 20 }}>
              <AppearingSituation
                onInputChange={this.getSelectedTrigger}
                triggerOptions={triggers}
                defaultValue={this.state.trigger}
                moduleId={this.props.moduleId}
                calculatorId={this.props.calculatorId}
                type={this.props.calculatorId && 'calculator'}
              />
            </Box>

            <Box className='ml-1 mt-3'>
              <InputField
                name='name'
                required={true}
                value={this.state.name}
                onChange={this.onChange}
                label={helpInfoData?.name?.label}
                detail={helpInfoData?.name?.detail}
                placeholder={helpInfoData?.name?.placeholder}
                maxLength={helpInfoData?.name?.character_limit}
              />
            </Box>

            <Box className='ml-1 mt-3'>
              <FieldLabel
                required
                label={helpInfoData?.panel_item?.label}
                detail={helpInfoData?.panel_item?.detail}
              />
              <Examples
                container
                direction='column'
                justifyContent='flex-start'
                alignItems='flex-start'
              >
                <div className='example-n-tools-textBox m-4 mt-2'>
                  <NumberListItem
                    nextButton={this.props.container || this.state.is_submitable}
                    list={this.state.numerics}
                    updateListItems={this.updateNumerics}
                    handleListChange={this.updateNumeric}
                    handleDeleteClick={this.deleteNumeric}
                    moduleId={this.props.moduleId}
                    calculatorId={this.props.calculatorId}
                    type={this.props.calculatorId && 'calculator'}
                    infoboxes={this.props.infoboxState.infoBoxes}
                  />
                </div>
                <Box
                  className='mb-3 ml-2 mt-2'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <AddLabel className='ml-4 mr-3 mt-2'>Add Item</AddLabel>
                  <AddButton
                    style={{
                      maxWidth: '142px',
                      paddingLeft: '14px',
                      paddingRight: '16px',
                      display: 'inline-flex',
                    }}
                    startIcon={<AddIcon />}
                    endIcon={<ExpandMoreIcon />}
                    className='waves-effect waves-light mr-2'
                    onClick={(e) => this.handleClick(e)}
                  >
                    <p style={{ marginTop: '12px' }}> Custom </p>
                  </AddButton>
                  <AddButton
                    style={{
                      maxWidth: '162px',
                      paddingLeft: '14px',
                      paddingRight: '16px',
                      display: 'inline-flex',
                    }}
                    startIcon={<AddIcon />}
                    endIcon={<ExpandMoreIcon />}
                    className='waves-effect waves-light mr-2'
                    onClick={(e) => this.setState({ numericsDropDownEl: e.target })}
                  >
                    <p style={{ marginTop: '12px' }}> Predefined </p>
                  </AddButton>
                </Box>
              </Examples>
            </Box>

            {/* Menu for Numerics */}
            <NumericsMenu
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              getContentAnchorEl={null}
              anchorEl={this.state.numericsDropDownEl}
              open={Boolean(this.state.numericsDropDownEl)}
              onClose={() => this.setState({ numericsDropDownEl: null })}
            >
              <div style={{ padding: '16px' }}>
                <div className='search-ip-box'>
                  <input
                    autoFocus
                    placeholder='Search...'
                    id='search-ip'
                    name='searchNumeric'
                    value={this.state.searchNumeric}
                    onChange={this.onChange}
                    type='text'
                  />
                  <SearchIcon />
                </div>
              </div>

              <MenuList
                autoFocus
                style={{
                  maxHeight: 400,
                  overflowY: 'scroll',
                  paddingBottom: 15,
                }}
              >
                {filteredNumerics.map((item) => (
                  <NumericsMenuItem
                    key={item.id}
                    onClick={() => this.addNumeric(item, 'predefined')}
                  >
                    {item.display_value}
                  </NumericsMenuItem>
                ))}
              </MenuList>
            </NumericsMenu>

            {/* Menu for Custom Numerics */}
            <MenuDropdown
              dropDownEl={this.state.customNumericsDropDownEl}
              selEl={this.closeMenu}
              searchInput={this.state.searchCustomNumeric}
              setSearchInput={(searchCustomNumeric) => this.setState({ searchCustomNumeric })}
              selectedItem={this.state.customNumericId}
              setSelectedItem={(customNumericId) => this.setState({ customNumericId })}
              toggleEditModal={this.toggleEditModal}
              toggleCreateModal={this.toggleCreateModal}
              addItem={() => this.addNumeric(this.state.customNumericId, 'custom')}
              isNumeric={true}
            />

            {!this.props.container && (
              <StyledFormLabel
                style={{ display: 'flex', alignItems: 'center' }}
                control={<GreenCheckbox {...greenCheckboxProps} />}
                label='Next Button'
              />
            )}
            <StaffOnlyWrapper type={StaffOnlyBadgeType.STAFF}>
              <Label className='flex items-center gap-1'>
                <Checkbox
                  checked={this.state.is_hide}
                  onChange={(e) => this.setState({ is_hide: e.target.checked })}
                />
                <span>Hide card</span>
              </Label>
            </StaffOnlyWrapper>
            <UnderlyingElement />
            <FixedRow>
              <div
                style={{
                  marginLeft: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 30,
                  marginBottom: 10,
                }}
              >
                <CancelButton
                  style={{ color: '#08A88E', marginRight: '10px' }}
                  onClick={this.props.toggleModal}
                >
                  {CANCEL_BUTTON}
                </CancelButton>
                <CreateButton type='submit'>{SAVE_BUTTON}</CreateButton>
              </div>
            </FixedRow>
            <ConfirmModal
              open={this.state.nextButtonModal}
              content={
                'You are disabling the submit feature of the data entry. As a result, you cannot add default values to each numeric/text field.'
              }
              toggleModal={this.toggleNextButtonModal}
              performAction={this.toggleNextButton}
            />

            {/* Sliding Pane for Creating Custom Numerics*/}
            <SlidingPane
              isOpen={this.state.createModal}
              onRequestClose={this.toggleConfirmationModal}
              from='right'
              className='no-padding sliding-panel-shadow mt-5'
              overlayClassName='!z-30'
              width='622px'
              hideHeader
            >
              <CustomNumericForm {...customNumericFormProps} />
            </SlidingPane>

            {/* Sliding Pane for Editing Custom Numerics  */}
            <SlidingPane
              isOpen={this.state.editModal}
              onRequestClose={this.toggleConfirmationModal}
              from='right'
              className='no-padding sliding-panel-shadow mt-5'
              overlayClassName='!z-30'
              width='622px'
              hideHeader
            >
              <CustomNumericForm {...customNumericFormProps} />
            </SlidingPane>
          </Form>
        </div>
        <ConfirmModal
          preset='unsaved'
          open={this.props.modal!}
          toggleModal={this.props.closeUiElementPane!}
          toggleModalPanel={this.props.toggleModal!}
          handleSubmit={this.handleSubmit}
          panelForm
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch, ValuePanelFormProps) => ({
  getUniqueCode: (moduleId, moduleType) => dispatch(getUniqueCode(moduleId, moduleType)),
  getInfoBoxes: (moduleType, mirrorId, moduleId) =>
    dispatch(getInfoBoxes(moduleType, mirrorId, moduleId)),
  getCustomNumerics: (moduleType, mirrorId, moduleId) =>
    dispatch(getCustomNumerics(moduleType, mirrorId, moduleId)),
});

export default withTriggers(connect(mapStateToProps, mapDispatchToProps)(ValuePanelForm));
