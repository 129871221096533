import ConditionalText from 'assets/icons/tiptap/conditionaltext';
import Infobox from 'assets/icons/tiptap/infobox';
import KnowledgeBase from 'assets/icons/tiptap/knowledgebase';
import Link from 'assets/icons/tiptap/link';
import Media from 'assets/icons/tiptap/media';
import Phone from 'assets/icons/tiptap/phone';
import Variable from 'assets/icons/tiptap/variable';
import { Icons } from 'components/utils/Icons';
import { SuggestionTypes } from 'components/utils/tiptap/tiptapInterfaces';
import { HTMLAttributes, useContext, useMemo, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { MentionContext } from '../../module/MentionContext';
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useFloatingRootContext,
  useInteractions,
  useMergeRefs,
} from '@floating-ui/react';
import { StaffOnlyBadgeType, StaffOnlyWrapper } from '../../StaffOnlyWrapper';
import {
  DEMOGRAPHICS_TYPE,
  LAB_DATA_TYPE,
  MEDICATION_TYPE,
  NOTE_TYPE,
  PROBLEM_LIST_TYPE,
  PROVIDER_INFO_TYPE,
  RADIOLOGY_REPORT_TYPE,
  VITAL_SIGN_TYPE,
} from '../../../../hooks/useEHRVariables';
import { MentionPopover } from './MentionPopover';

export const TiptapExpandButton = () => {
  const mention = useContext(MentionContext);

  const FILTERED_MENU_ITEMS = useMemo(
    () => MENU_ITEMS.filter((item) => filterMenuBySuggestions(item.type, mention)),
    [mention]
  );
  const [suggestionType, setSuggestionType] = useState<SuggestionTypes | undefined>();

  // First menu popover settings
  const [menuOpen, setMenuOpen] = useState(false);
  const { refs, context, floatingStyles } = useFloating({
    open: menuOpen,
    onOpenChange: setMenuOpen,
    middleware: [offset(5), flip()],
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const { getFloatingProps, getReferenceProps } = useInteractions([click, dismiss]);

  // Second mention popover settings
  const [reference, setReference] = useState<Element | null>(null);
  const [floating, setFloating] = useState<HTMLElement | null>(null);
  const subMenuFloatingRefs = useMergeRefs([setFloating, refs.setFloating]);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const subMenuContext = useFloatingRootContext({
    open: subMenuOpen,
    onOpenChange: setSubMenuOpen,
    elements: {
      reference,
      floating,
    },
  });

  const subMenuClick = useClick(subMenuContext);
  const sueMenuDismiss = useDismiss(subMenuContext);
  const { getFloatingProps: getSubMenuFloatingProps, getReferenceProps: getSubMenuReferenceProps } =
    useInteractions([subMenuClick, sueMenuDismiss]);

  return (
    <>
      <button
        type='button'
        className='flex h-[24px] items-center gap-[2px] rounded pl-[4px] pr-[6px] focus:!bg-gray-200'
        ref={useMergeRefs([refs.setReference, (ref: Element) => setReference(ref)])}
        {...getReferenceProps({
          onClick: () => {
            if (menuOpen && subMenuOpen) {
              setSubMenuOpen(false);
            }
          },
        })}
      >
        <Icons.Plus />
        <Icons.MenuUp className={twJoin(!menuOpen && 'rotate-180')} />
      </button>
      {menuOpen && !subMenuOpen && (
        <FloatingPortal preserveTabOrder={false}>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className={twJoin(
              'z-40 flex max-h-96 w-[340px] flex-col items-start gap-2 overflow-y-scroll rounded-[4px] bg-white !py-2 shadow-06',
              subMenuOpen && 'invisible'
            )}
            {...getFloatingProps()}
          >
            {FILTERED_MENU_ITEMS.map((item, index) =>
              item.type === 'ehr_order' ? (
                <StaffOnlyWrapper type={StaffOnlyBadgeType.BETA}>
                  <TiptapExpandButtonMenu
                    item={item}
                    {...getSubMenuReferenceProps({
                      onClick: () => {
                        setSuggestionType(item.type);
                      },
                    })}
                  />
                </StaffOnlyWrapper>
              ) : (
                <TiptapExpandButtonMenu
                  item={item}
                  {...getSubMenuReferenceProps({
                    onClick: () => {
                      setSuggestionType(item.type);
                    },
                  })}
                />
              )
            )}
          </div>
        </FloatingPortal>
      )}
      {subMenuOpen && suggestionType && (
        <MentionPopover
          suggestionType={suggestionType}
          rootContext={subMenuContext}
          ref={subMenuFloatingRefs}
          onClose={() => {
            setMenuOpen(false);
            setSubMenuOpen(false);
          }}
          {...getSubMenuFloatingProps()}
        />
      )}
    </>
  );
};

interface TiptapExpandButtonMenuProps extends HTMLAttributes<HTMLDivElement> {
  item: ExpandMenuItemType;
}

const TiptapExpandButtonMenu = ({ item, ...props }: TiptapExpandButtonMenuProps) => {
  return (
    <>
      <div className='flex w-full cursor-pointer items-start gap-4 !py-2 !pl-2 !pr-4' {...props}>
        <item.Icon className='flex-shrink-0' />
        <div className='flex flex-col items-start gap-1'>
          <p
            className={twJoin(
              'text-button-1',
              '!mb-0' // due to materialize css and bootstrap
            )}
          >
            {item.title}
          </p>
          <p className='text-left text-caption-2 text-gray-700'>{item.subtitle}</p>
        </div>
      </div>
    </>
  );
};

interface ExpandMenuItemType {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  type: SuggestionTypes;
  title: string;
  subtitle: string;
}

const MENU_ITEMS: ExpandMenuItemType[] = [
  {
    Icon: KnowledgeBase,
    type: 'knowledge_base',
    title: 'Knowledge Base',
    subtitle: 'Use Knowledge Base to guide GPT Box with your own Documents or Protocols.',
  },
  {
    Icon: Variable,
    type: 'variable',
    title: 'Variable',
    subtitle: 'Use Variable to select and include variables from previous cards in your ALGO.',
  },
  {
    Icon: ConditionalText,
    type: 'conditional_text',
    title: 'Conditional Text',
    subtitle: 'Use Conditional Text to display message variation based on met conditions.',
  },
  {
    Icon: Infobox,
    type: 'infobox',
    title: 'Infobox',
    subtitle: 'Use Infobox to include optional information in your ALGO.',
  },
  {
    Icon: Media,
    type: 'media',
    title: 'Media',
    subtitle: 'Add images to your ALGO.',
  },
  {
    Icon: Link,
    type: 'link',
    title: 'Link',
    subtitle: 'Create hyperlinks in your AvoMD ALGO to link to another ALGO or an external link.',
  },
  {
    Icon: Phone,
    type: 'contact_number',
    title: 'Contact Number',
    subtitle: 'Add contact details of the person or department you need to reach.',
  },
  {
    Icon: Icons.TiptapReference,
    type: 'reference',
    title: 'Reference',
    subtitle: 'Add a reference to your ALGO.',
  },
  {
    Icon: Icons.EHRData,
    type: 'ehr_data',
    title: 'EHR Data',
    subtitle: 'Add EHR data to your ALGO.',
  },
  {
    Icon: Icons.EHRData,
    type: 'ehr_order',
    title: 'EHR Order',
    subtitle: 'Add EHR order to your ALGO.',
  },
];

const filterMenuBySuggestions = (menuType: SuggestionTypes, mention: MentionContext) => {
  const { excludedSuggestionTypes, suggestions } = mention;
  const unfilteredTypes: SuggestionTypes[] = [
    'media',
    'link',
    'contact_number',
    'conditional_text',
    'infobox',
  ];
  const ehrDataTypes: SuggestionTypes[] = [
    NOTE_TYPE,
    VITAL_SIGN_TYPE,
    LAB_DATA_TYPE,
    MEDICATION_TYPE,
    PROBLEM_LIST_TYPE,
    DEMOGRAPHICS_TYPE,
    RADIOLOGY_REPORT_TYPE,
    PROVIDER_INFO_TYPE,
  ];

  switch (true) {
    // When defined not to be used externally
    case !excludedSuggestionTypes.includes(menuType):
    // In case there are no suggestions, indicating a menu without suggestions.
    case unfilteredTypes.includes(menuType):
    // In case there are variable type suggestions (e.g. gptbox_variable, ambient_variable...)
    case menuType === 'variable' && suggestions.some((item) => item.type.includes(menuType)):
    // In case there are ehr data type suggestions (e.g. note, vital_sign, lab_data ...)
    case menuType === 'ehr_data' && suggestions.some((item) => ehrDataTypes.includes(item.type)):
    case suggestions.some((item) => item.type === menuType):
      return true;
  }
};
